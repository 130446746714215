l
<template>
    <div>
        <ToolBar :menu="'Báo cáo'" :content="'Chốt dữ liệu báo cáo'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="1">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool()"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool()"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable
            :tableData="tableData"
            :hide-btn-table="true"
            :column-form="1"
        >
            <template v-slot:header>
                <tr class="text-center">
                    <th>STT</th>

                    <th>Tên trường</th>
                    <th>Mã trường</th>
                    <th>Trạng thái</th>
                    <th>Thao tác</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>

                    <td class="text-left">
                        {{ item.tenTruong }}
                    </td>
                    <td>
                        {{ item.maTruong }}
                    </td>
                    <td>
                        <TextStatus
                            :content="item.status === 1 ? 'Đã chốt' : 'Chưa chốt'"
                            :status="item.status"
                        />
                    </td>
                    <td>
                        <span v-if="item.status === 1">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Mở chốt dữ liệu"
                                placement="top"
                            >
                                <img
                                    :src="iconUnLock"
                                    class="icon"
                                    @click="handleUnLock(item)"
                                />
                            </el-tooltip>
                        </span>
                        <span v-else>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="Chốt dữ liệu"
                                placement="top"
                            >
                                <img
                                    :src="iconLock"
                                    class="icon"
                                    @click="handleLock(item)"
                                />
                            </el-tooltip>
                        </span>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>

        <CustomModal
            :centered="true"
            :is-modal-visible.sync="statusVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalStatus"
        >
            <template v-slot:content>
                <div>Xác nhận {{ idstatus === 0 ? 'Khóa' : 'Mở khóa' }} ?</div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleStatus"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomTable from '@/components/CustomTable.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import iconLock from '@/assets/img/icon-table/lock.svg'
import iconUnLock from '@/assets/img/icon-table/un-lock.svg'
import TextStatus from '@/components/TextStatus.vue'
import { convertListData } from '@/utils'
import { isRoleEducationOfficeOrSchool, isRoleSchool } from '@/constants/Roles'
export default {
    name: 'LatchSchool',

    components: {
        ToolBar,
        CustomTable,
        CustomButton,
        CustomModal,
        FormSearch,
        ESelect,
        CustomPagination,
        TextStatus
    },
    data() {
        return {
            iconLock,
            iconUnLock,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school: [],
            school: [],

            tableData: [],
            statusVisible: false,
            idstatus: null,
            dataStatus: {}
        }
    },
    methods: {
        isRoleEducationOfficeOrSchool,
        isRoleSchool,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        openModalSatus() {
            this.statusVisible = true
        },
        async handleToggleLock(item, trangThai) {
            try {
                const request_data = {
                    maPhong: item.maPhong,
                    maTruongs: [item.maTruong],
                    namHoc: item.namHoc,
                    trangThai: trangThai
                }

                const response = await sendRequest(
                    Api.bao_cao.chot_du_lieu_bao_cao.chot_theo_truong.trang_thai,
                    request_data,
                    this.request_headers
                )

                if (response.rc == 0) {
                    this.handleSearch()
                    showNotication(this, 'success', response.rd)
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        },

        async handleUnLock(item) {
            await this.handleToggleLock(item, 0)
        },

        async handleLock(item) {
            await this.handleToggleLock(item, 1)
        },
        closeModalStatus() {
            this.statusVisible = false
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_school() {
            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },

        async handleSearch() {
            try {
                const request_data = {
                    start: this.start,
                    limit: this.limit,
                    maSo: this.auth_user.ma_so,

                    maPhongs: this.unit_education,
                    maTruongs: this.school,
                    capHocs: this.selectedGradeLevelFromStore,
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.bao_cao.chot_du_lieu_bao_cao.chot_theo_truong.danh_sach,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    this.tableData = response.rows
                    this.total_rows = response.total
                } else {
                    this.tableData = []
                    this.total_rows = 0
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore(newVal) {
            if (newVal) {
                this.school = []
                this.get_list_school()
            }
        },
        auth_user: {
            handler(user) {
                if (isRoleEducationOfficeOrSchool()) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (isRoleSchool()) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
