<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="pushDataVisible"
        :modal-title="'Thông báo'"
        :close-function="closeModalPushData"
        :size="'sm'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            Xác nhận đẩy thông tin <b> {{ dataPushData.length }}</b> bản ghi đã
            chọn?
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handlePushData"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalPushData',
    data() {
        return {
            errModal: false
        }
    },
    props: {
        pushDataVisible: {
            type: Boolean,
            required: true
        },
        dataPushData: {
            type: Array,
            require: true
        },
        closeModalPushData: Function,
        handleSearch: Function
    },

    components: { CustomButton, CustomModal },
    methods: {
        async handlePushData() {
            try {
                const listNopRieng = this.dataPushData
                    .map((item) => `${item.maGiaoVien}`)
                    .toString()

                const maTruongHoc = this.dataPushData?.every(
                    (item) =>
                        item.maTruongHoc === this.dataPushData[0]?.maTruongHoc
                )
                    ? this.dataPushData[0]?.maTruongHoc
                    : []

                const request_data = {
                    hocKy: 1,
                    namHoc: this.selectedYearFromStore,
                    truongCanDayRequests: [
                        {
                            maTruong: maTruongHoc,
                            buocDays: [2],
                            listNopRieng: listNopRieng
                        }
                    ]
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_can_bo_giao_vien.day_du_lieu,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    this.closeModalPushData()
                    this.$emit('handleSearch')
                } else if (response.rc == 999) {
                    showNotication(this, 'error', response.rd)

                    this.tableDataError = response.rows
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
