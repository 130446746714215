<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="deleteVisible"
        :modal-title="'Thông báo'"
        :close-function="closeModalDelete"
        :size="'sm'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            Xóa <b> {{ dataDelete.length }}</b> bản ghi đã chọn?
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleDelete"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { extractValuesFromArray } from '@/utils'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalDelete',
    data() {
        return {
            errModal: false
        }
    },
    props: {
        deleteVisible: {
            type: Boolean,
            required: true
        },
        dataDelete: {
            type: Array,
            require: true
        },
        closeModalDelete: Function,
        handleSearch: Function
    },

    components: { CustomButton, CustomModal },
    methods: {
        async handleDelete() {
            try {
                const listMa = extractValuesFromArray(
                    this.dataDelete,
                    'tongKetHsId'
                )

                const request_data = {
                    listMa: listMa,

                    namHoc: this.selectedYearFromStore
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_kqht.xoa,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    this.closeModalDelete()
                    this.$emit('handleSearch')
                } else if (response.rc == 999) {
                    showNotication(this, 'error', response.rd)
                    this.tableDataError = response.rows
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
