<template>
    <div>
        <CustomModal
            :is-modal-visible="schoolTransferVisible"
            :modal-title="`Chuyển công tác - ${dataSchoolTransfer.tenCanBo} [${dataSchoolTransfer.maGiaoVien}]`"
            :close-function="handleCloseModalSchoolTransfer"
            :size="'lg'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                <div class="row">
                    <div class="col-md-12">
                        <label class="control-label">Tỉnh thành:</label>

                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_province"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="province"
                            @change="onChangeProvince"
                        />
                    </div>
                    <div class="col-md-12 mt-3">
                        <label class="control-label">Đơn vị quản lý</label>

                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_unit_education"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="true"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            v-model="unit_education"
                            @change="onChangeUnitEducation"
                        />
                    </div>
                    <div class="col-md-12 mt-3">
                        <label class="control-label">
                            Trường học <span class="error-label">*</span>
                        </label>

                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_school"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="school"
                            @input="onChangeHandler('school', $event)"
                            :class="{
                                'error-select': err_school.length > 0
                            }"
                        />
                        <span
                            v-if="err_school.length > 0"
                            class="text-error-select"
                        >
                            {{ err_school }}
                        </span>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label class="control-label">
                            Ngày chuyển <span class="error-label">*</span>
                        </label>
                        <DatePicker
                            v-model="the_transfer_date"
                            @input="onChangeHandler('the_transfer_date', $event)"
                            :showError="err_the_transfer_date.length > 0"
                        />
                        <span
                            v-if="err_the_transfer_date.length > 0"
                            class="text-error-select"
                        >
                            {{ err_the_transfer_date }}
                        </span>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label class="control-label">Lý do chuyển trường</label>
                        <CustomInput
                            v-model="in_order_to_switch_schools"
                            placeholder=""
                            :disabled="false"
                            :type="'textarea'"
                        >
                        </CustomInput>
                    </div>
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleSchoolTransfer"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { formatDateToDDMMYYYY, convertListData } from '@/utils'
import ESelect from '@/components/ESelect.vue'
import CustomInput from '@/components/CustomInput.vue'
import DatePicker from '@/components/DatePicker.vue'
import { validateFields, handlerError } from '@/utils/validator'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalSchoolTransfer',
    data() {
        return {
            errModal: false,
            province: '',
            list_unit_education: [],
            unit_education: [],
            list_school: [],
            school: '',
            err_school: '',
            in_order_to_switch_schools: '',
            the_transfer_date: '',
            err_the_transfer_date: ''
        }
    },
    components: {
        CustomButton,
        CustomModal,
        ESelect,
        CustomInput,
        DatePicker
    },
    props: {
        schoolTransferVisible: {
            type: Boolean,
            required: true
        },
        dataSchoolTransfer: {
            type: Object,
            require: true
        },
        closeModalSchoolTransfer: Function,
        handleSearch: Function
    },

    methods: {
        onChangeProvince() {
            this.unit_education = []
            this.get_list_unit_education()
            this.school = []
            this.list_school = []
        },
        async get_list_unit_education() {
            try {
                const request_params = {
                    start: 0,
                    limit: 9999,
                    maTinhThanh: this.province,
                    check: false
                }
                const response = await sendRequest(
                    Api.internal_api.dm_DonVi,
                    null,
                    this.request_headers,
                    request_params
                )
                this.list_unit_education = response.rows
            } catch (error) {
                console.log(error)
            }
        },

        async get_list_school() {
            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                school: 'err_school',
                the_transfer_date: 'err_the_transfer_date'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        handleCloseModalSchoolTransfer() {
            this.closeModalSchoolTransfer()
            this.resetData()
        },
        resetData() {
            this.errModal = false
            this.province = ''
            this.unit_education = []
            this.school = ''
            this.err_school = ''
            this.in_order_to_switch_schools = ''
            this.the_transfer_date = ''
            this.err_the_transfer_date = ''
        },
        async handleSchoolTransfer() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.school,
                        errorField: 'err_school'
                    },
                    {
                        value: this.the_transfer_date,
                        errorField: 'err_the_transfer_date'
                    }
                ]
                if (validateFields(this, fieldsToValidate)) {
                    const request_data = {
                        maGiaoVien: this.dataSchoolTransfer.maGiaoVien,
                        maTruongHoc: this.school,
                        namHoc: this.selectedYearFromStore,
                        ngayChuyen: formatDateToDDMMYYYY(this.the_transfer_date),
                        lyDo: this.in_order_to_switch_schools
                    }

                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_can_bo_giao_vien.chuyen_truong,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)
                        this.handleCloseModalSchoolTransfer()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    watch: {
        schoolTransferVisible(newValue) {
            if (newValue === true) {
                this.get_list_school()
                this.err_school = ''
                this.err_the_transfer_date = ''
            }
        }
    },
    computed: {
        list_province() {
            return getFromSessionStorage('list_province')
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.control-label {
    font-weight: 600;
}
.error {
    color: red;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
