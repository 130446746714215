var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-input',_vm._b({class:{ 'is-error': _vm.textError.length > 0 },attrs:{"placeholder":_vm.disabled ? '' : _vm.placeholder,"value":_vm.value,"disabled":_vm.disabled,"clearable":_vm.clearable,"size":_vm.size,"autosize":_vm.autosize,"type":_vm.type,"show-password":_vm.showPassword},on:{"input":_vm.updateValue,"blur":_vm.trimValue}},'el-input',_vm.$attrs,false),[(
                _vm.showCloseIcon === true &&
                _vm.disabled === false &&
                _vm.value &&
                _vm.type !== 'textarea' &&
                !_vm.clearable
            )?_c('i',{staticClass:"el-input__icon el-icon-circle-close icon-close",attrs:{"slot":"suffix"},on:{"click":_vm.clearInput},slot:"suffix"}):_vm._e()]),(_vm.textError.length > 0)?_c('span',{staticClass:"text-error-input"},[_vm._v(" "+_vm._s(_vm.textError)+" ")]):_vm._e(),(
            _vm.showCloseIcon === true &&
            _vm.disabled === false &&
            _vm.value &&
            _vm.type === 'textarea'
        )?_c('i',{staticClass:"el-input__icon el-icon-circle-close icon-close-textarea",on:{"click":_vm.clearInput}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }