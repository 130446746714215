<template>
    <LayoutCard>
        <div class="row">
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title> Trạng thái </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="handlerefresh_column_chart_status_student"
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                customColorStackedColumnChart(
                                    column_chart_status_student
                                )
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_status_student
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title> Giới tính </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="handlerefresh_column_chart_gender_student"
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                customColorStackedColumnChart(
                                    column_chart_gender_student
                                )
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_gender_student
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title>Loại hình đào tạo</template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="handlerefresh_column_chart_type_of_training"
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                customColorStackedColumnChart(
                                    column_chart_type_of_training
                                )
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_type_of_training
                            "
                        />
                    </template>
                </Card>
            </div>

            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title>Khu vực</template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="handlerefresh_column_chart_area"
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                customColorStackedColumnChart(column_chart_area)
                            "
                            :xaxis_categories="xaxisCategories.column_chart_area"
                        />
                    </template>
                </Card>
            </div>
        </div>
    </LayoutCard>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import 'element-ui/lib/theme-chalk/index.css'

import { customColorStackedColumnChart } from '@/utils'
import LayoutCard from '@/components/CardDashboard/LayoutCard'
import Card from '@/components/CardDashboard/Card'
import StackedColumnChart from '@/components/StackedColumnChart.vue'
export default {
    name: 'ChartStudent',
    components: {
        CustomButton,
        StackedColumnChart,
        LayoutCard,
        Card
    },
    data() {
        return {
            xaxisCategories: {
                column_chart_status_student: ['Đang học', 'Chuyển trường'],
                column_chart_gender_student: ['Nam', 'Nữ'],
                column_chart_type_of_training: ['Công lập', 'Tư thục', 'Dân lập'],
                column_chart_area: [
                    'Biên giới - Hải đảo',
                    'Đô thị',
                    'Đồng bằng',
                    'Miền núi - Vùng sâu'
                ]
            }
        }
    },
    props: {
        column_chart_status_student: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_status_student: Function,
        column_chart_gender_student: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_gender_student: Function,

        column_chart_type_of_training: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_type_of_training: Function,
        column_chart_area: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_area: Function
    },
    methods: {
        customColorStackedColumnChart,
        handlerefresh_column_chart_status_student() {
            this.$emit('refresh_column_chart_status_student')
        },
        handlerefresh_column_chart_gender_student() {
            this.$emit('refresh_column_chart_gender_student')
        },
        handlerefresh_column_chart_type_of_training() {
            this.$emit('refresh_column_chart_type_of_training')
        },
        handlerefresh_column_chart_area() {
            this.$emit('refresh_column_chart_area')
        }
    }
}
</script>

<style scoped></style>
