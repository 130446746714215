l
<template>
    <div>
        <ToolBar :menu="'Báo cáo'" :content="'Báo cáo Eqms'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
                <CustomButton label="Xuất Excel" :icon="'el-icon-download'" />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="get_list_school"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Cấp học:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="true"
                        :data="list_grade_level"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="grade_level"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Chi tiết báo cáo:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_detailed_report"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="detailed_report"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"> Thông tư:</label>
                    </div>
                </div>

                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_circulars"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="circulars"
                    />
                </div>
            </div>
        </FormSearch>

        <QualityOfEducation
            :start="start"
            :limit="limit"
            :tableData="tableData"
            v-if="detailed_report == 1"
        />
        <PeriodicCheckPoint
            :start="start"
            :limit="limit"
            :tableData="tableData"
            v-if="detailed_report == 2"
        />

        <CommunityExpertise
            :start="start"
            :limit="limit"
            :tableData="tableData"
            v-if="detailed_report == 3"
        />

        <div class="pagi">
            <CustomPagination
                v-show="total_rows > 0"
                :tongbanghi="total_rows"
                :pageSize="pageSize"
                :pageStart="pageStart"
                @pageChange="pageChange($event)"
                @pageSizeChange="pageSizeChange($event)"
            >
            </CustomPagination>
        </div>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import CustomPagination from '@/components/CustomPagination.vue'
import ESelect from '@/components/ESelect.vue'
import { list_circulars, list_detailed_report } from '@/mock_data/bao-cao-eqmis'
import QualityOfEducation from './QualityOfEducation.vue'
import CommunityExpertise from './CommunityExpertise.vue'
import PeriodicCheckPoint from './PeriodicCheckPoint.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ReportEqms',

    components: {
        ToolBar,
        CustomButton,
        FormSearch,
        CustomPagination,
        ESelect,

        QualityOfEducation,
        CommunityExpertise,
        PeriodicCheckPoint
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            tableData: [],

            unit_education: [],

            grade_level: ['1'],

            list_school: [],
            school: [],

            list_detailed_report: list_detailed_report,
            detailed_report: 1,

            list_circulars: list_circulars,
            circulars: ''
        }
    },
    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        async get_list_school() {
            this.school = []

            const request_data = {
                capHoc: this.grade_level,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        async handleSearch() {
            try {
                const request_data = {
                    idSo: this.auth_user.ma_so,
                    capHoc: '1',
                    maDonVis: this.unit_education,
                    maTruongHocs: this.school,
                    type: 2,
                    namHoc: this.selectedYearFromStore,
                    start: this.start,
                    limit: this.limit
                }

                const response = await sendRequest(
                    Api.bao_cao.bao_cao_emis.bao_cao_dau_nam.danh_sach,
                    request_data,
                    this.request_headers
                )
                if (response) {
                    const type = response.rc === 0 ? 'success' : 'error'
                    showNotication(this, type, response.rd)
                    this.tableData = response.data
                    this.total_rows = response.total
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_list_school()
        // this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore: function (newVal) {
            if (newVal) {
                this.get_list_school()
            }
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_grade_level() {
            return getFromSessionStorage('list_grade_level')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },

        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
</style>
