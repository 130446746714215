<template>
    <div>
        <ToolBar
            :menu="'Tra cứu trường học'"
            :content="getContentToolBar()"
            :hideToggle="true"
            :hideZoom="true"
        >
            <template v-slot:form>
                <CustomButton
                    :plain="true"
                    label="Quay lại"
                    @click="openModalBack"
                />
                <CustomButton
                    v-if="!viewVisible"
                    label="Lưu"
                    @click="handleUpdate"
                />
            </template>
        </ToolBar>
        <FormLockUp>
            <template v-slot:lock-up-container>
                <CustomTab
                    :initialTab="active_tab_info"
                    :tabs="list_tab_info"
                    :hideHr="false"
                    @tabChanged="handleTabInfoChanged"
                />
                <div class="form-update mt-3" v-if="active_tab_info === 1">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.province }}
                                        </label>
                                        <div class="n-index error">[1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="true"
                                        :data="list_province"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="province"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.district }}
                                        </label>
                                        <div class="n-index error">[2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_district"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="district"
                                        :show-error="err_district.length > 0"
                                        @input="
                                            onChangeHandler('district', $event)
                                        "
                                        @change="onChangeDistrict"
                                        :class="{
                                            'error-select':
                                                err_district.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_district.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_district }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.wards }}
                                        </label>
                                        <div class="n-index">[3]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_wards"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="wards"
                                        @change="onChangeWards"
                                        @input="onChangeHandler('wards', $event)"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.village }}
                                        </label>
                                        <div class="n-index">[4]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_village"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="village"
                                        @input="
                                            onChangeHandler('village', $event)
                                        "
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.unit_education }}
                                        </label>
                                        <div class="n-index error">[5]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_unit_education"
                                        :multiple="false"
                                        :fields="['tenDonVi', 'maDonVi']"
                                        v-model="unit_education"
                                        @input="
                                            onChangeHandler(
                                                'unit_education',
                                                $event
                                            )
                                        "
                                        :class="{
                                            'error-select':
                                                err_unit_education.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_unit_education.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_unit_education }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                titleGeneralInfo.school_code
                                            }}</label
                                        >
                                        <div class="n-index error">[6]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="school_code"
                                        @input="
                                            onChangeHandler('school_code', $event)
                                        "
                                        :textError="err_school_code"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                titleGeneralInfo.school_name
                                            }}</label
                                        >
                                        <div class="n-index error">[7]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="school_name"
                                        @input="
                                            onChangeHandler('school_name', $event)
                                        "
                                        :textError="err_school_name"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.grade_level }}
                                        </label>
                                        <div class="n-index error">[8]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_grade_level"
                                        :multiple="true"
                                        :fields="['label', 'value']"
                                        v-model="grade_level"
                                        @input="
                                            onChangeHandler('grade_level', $event)
                                        "
                                        :class="{
                                            'error-select':
                                                err_grade_level.length > 0
                                        }"
                                    />

                                    <span
                                        v-if="err_grade_level.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_grade_level }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                titleGeneralInfo.grade_group
                                            }}</label
                                        >
                                        <div class="n-index error">[9]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_grade_group"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="grade_group"
                                        @input="
                                            onChangeHandler('grade_group', $event)
                                        "
                                        :class="{
                                            'error-select':
                                                err_grade_group.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_grade_group.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_grade_group }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                titleGeneralInfo.type_of_education
                                            }}
                                        </label>
                                        <div class="n-index error">[10]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_type_of_education"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="type_of_education"
                                        @input="
                                            onChangeHandler(
                                                'type_of_education',
                                                $event
                                            )
                                        "
                                        :class="{
                                            'error-select':
                                                err_type_of_education.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_type_of_education.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_type_of_education }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                titleGeneralInfo.school_type
                                            }}</label
                                        >
                                        <div class="n-index error">[11]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_school_type"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="school_type"
                                        @input="
                                            onChangeHandler('school_type', $event)
                                        "
                                        :class="{
                                            'error-select':
                                                err_school_type.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_school_type.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_school_type }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.date }}
                                        </label>
                                        <div class="n-index">[12]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        :disabled="viewVisible"
                                        v-model="date"
                                        @change="onChangeHandler('date', $event)"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                titleGeneralInfo.national_standard
                                            }}
                                        </label>
                                        <div class="n-index">[13]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_national_standard"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="national_standard"
                                        @input="
                                            onChangeHandler(
                                                'national_standard',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.website }}
                                        </label>
                                        <div class="n-index">[14]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="website"
                                        @input="
                                            onChangeHandler('website', $event)
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.principals_name }}
                                        </label>
                                        <div class="n-index">[15]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="principals_name"
                                        @input="
                                            onChangeHandler(
                                                'principals_name',
                                                $event
                                            )
                                        "
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.email }}
                                        </label>
                                        <div class="n-index">[16]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="email"
                                        @input="onChangeHandler('email', $event)"
                                        :trimAll="true"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.fax }}
                                        </label>
                                        <div class="n-index">[17]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="fax"
                                        @input="onChangeHandler('fax', $event)"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label"
                                            >{{ titleGeneralInfo.address }}
                                        </label>
                                        <div class="n-index">[18]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        :disabled="viewVisible"
                                        v-model="address"
                                        @input="
                                            onChangeHandler('address', $event)
                                        "
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ titleGeneralInfo.status }}
                                        </label>
                                        <div class="n-index">[19]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_status"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="status"
                                        @input="onChangeHandler('status', $event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-update mt-3" v-if="active_tab_info === 2">
                    <div class="row">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.school_telephone }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="school_telephone"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    :trimAll="true"
                                    @input="
                                        onChangeHandler(
                                            'school_telephone',
                                            $event
                                        )
                                    "
                                >
                                </CustomInput>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.principal_telephone }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="principal_telephone"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    :trimAll="true"
                                    @input="
                                        onChangeHandler(
                                            'principal_telephone',
                                            $event
                                        )
                                    "
                                >
                                </CustomInput>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.area }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_area"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="area"
                                    @input="onChangeHandler('area', $event)"
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.special_difficult_area }}
                            </label>

                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_yes_no"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="special_difficult_area"
                                    @input="
                                        onChangeHandler(
                                            'special_difficult_area',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.drinking_water_available }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="drinking_water_available"
                                    @input="
                                        onChangeHandler(
                                            'drinking_water_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.internet_access_for_teaching }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="internet_access_for_teaching"
                                    @input="
                                        onChangeHandler(
                                            'internet_access_for_teaching',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.separate_sanitation_facilities_for_each_gender
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="
                                        separate_sanitation_facilities_for_each_gender
                                    "
                                    @input="
                                        onChangeHandler(
                                            'separate_sanitation_facilities_for_each_gender',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.school_counseling_service }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="school_counseling_service"
                                    @input="
                                        onChangeHandler(
                                            'school_counseling_service',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.electricity_available }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="electricity_available"
                                    @input="
                                        onChangeHandler(
                                            'electricity_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.convenient_handwashing_facilities
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="convenient_handwashing_facilities"
                                    @input="
                                        onChangeHandler(
                                            'convenient_handwashing_facilities',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.school_uses_clean_water_source
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="school_uses_clean_water_source"
                                    @input="
                                        onChangeHandler(
                                            'school_uses_clean_water_source',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.school_uses_computers_for_teaching
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="school_uses_computers_for_teaching"
                                    @input="
                                        onChangeHandler(
                                            'school_uses_computers_for_teaching',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.school_points }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="school_points"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    @input="
                                        onChangeHandler('school_points', $event)
                                    "
                                >
                                </CustomInput>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.center_code }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_center_code"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="center_code"
                                    @input="
                                        onChangeHandler('center_code', $event)
                                    "
                                />
                            </div>
                        </div>

                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.longitude }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="longitude"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    @input="onChangeHandler('longitude', $event)"
                                >
                                </CustomInput>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.latitude }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="latitude"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    @input="onChangeHandler('latitude', $event)"
                                >
                                </CustomInput>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.map_location }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="map_location"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    @input="
                                        onChangeHandler('map_location', $event)
                                    "
                                >
                                </CustomInput>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.basic_education_program }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="basic_education_program"
                                    @input="
                                        onChangeHandler(
                                            'basic_education_program',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.non_specialized_classes_available
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="non_specialized_classes_available"
                                    @input="
                                        onChangeHandler(
                                            'non_specialized_classes_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.sanitation_facilities_available
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="sanitation_facilities_available"
                                    @input="
                                        onChangeHandler(
                                            'sanitation_facilities_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.handwashing_education_program }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="handwashing_education_program"
                                    @input="
                                        onChangeHandler(
                                            'handwashing_education_program',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.meets_minimum_quality_standards
                                }}
                            </label>

                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="meets_minimum_quality_standards"
                                    @input="
                                        onChangeHandler(
                                            'meets_minimum_quality_standards',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.vocational_education }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="vocational_education"
                                    @input="
                                        onChangeHandler(
                                            'vocational_education',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.acreage }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="acreage"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    @input="onChangeHandler('acreage', $event)"
                                >
                                </CustomInput>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.area_two_sessions_per_day }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="area_two_sessions_per_day"
                                    @input="
                                        onChangeHandler(
                                            'area_two_sessions_per_day',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.boarding_students_available }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="boarding_students_available"
                                    @input="
                                        onChangeHandler(
                                            'boarding_students_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.day_students_available }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="day_students_available"
                                    @input="
                                        onChangeHandler(
                                            'day_students_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.students_with_disabilities_available
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="students_with_disabilities_available"
                                    @input="
                                        onChangeHandler(
                                            'students_with_disabilities_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.social_life_skills_education }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="social_life_skills_education"
                                    @input="
                                        onChangeHandler(
                                            'social_life_skills_education',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.area_code_of_difficult_circumstances
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="
                                        list_area_code_of_difficult_circumstances
                                    "
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="area_code_of_difficult_circumstances"
                                    @input="
                                        onChangeHandler(
                                            'area_code_of_difficult_circumstances',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.type_of_center_code }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_type_of_center_code"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="type_of_center_code"
                                    @input="
                                        onChangeHandler(
                                            'type_of_center_code',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.disaster_and_epidemic_prevention_plan_available
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="
                                        disaster_and_epidemic_prevention_plan_available
                                    "
                                    @input="
                                        onChangeHandler(
                                            'disaster_and_epidemic_prevention_plan_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{
                                    titleOtherInfo.bilingual_education_program_available
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="
                                        bilingual_education_program_available
                                    "
                                    @input="
                                        onChangeHandler(
                                            'bilingual_education_program_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.total_number_of_staff }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="total_number_of_staff"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    @input="
                                        onChangeHandler(
                                            'total_number_of_staff',
                                            $event
                                        )
                                    "
                                >
                                </CustomInput>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.total_number_of_classes }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="total_number_of_classes"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    @input="
                                        onChangeHandler(
                                            'total_number_of_classes',
                                            $event
                                        )
                                    "
                                >
                                </CustomInput>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.total_number_of_students }}
                            </label>
                            <div>
                                <CustomInput
                                    v-model="total_number_of_students"
                                    :disabled="viewVisible"
                                    :type="'Number'"
                                    @input="
                                        onChangeHandler(
                                            'total_number_of_students',
                                            $event
                                        )
                                    "
                                >
                                </CustomInput>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.international_school }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="international_school"
                                    @input="
                                        onChangeHandler(
                                            'international_school',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.career_guidance_counseling }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="career_guidance_counseling"
                                    @input="
                                        onChangeHandler(
                                            'career_guidance_counseling',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label class="fw">
                                {{
                                    titleOtherInfo.infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="
                                        infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available
                                    "
                                    @input="
                                        onChangeHandler(
                                            'infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="fw">
                                {{
                                    titleOtherInfo.disaster_prevention_climate_change_adaptation_education_plan_available
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="
                                        disaster_prevention_climate_change_adaptation_education_plan_available
                                    "
                                    @input="
                                        onChangeHandler(
                                            'disaster_prevention_climate_change_adaptation_education_plan_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label class="fw">
                                {{
                                    titleOtherInfo.disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills
                                }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_yes_no"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="
                                        disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills
                                    "
                                    @input="
                                        onChangeHandler(
                                            'disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label class="fw">
                                {{ titleOtherInfo.party_branch_available }}
                            </label>
                            <div>
                                <ESelect
                                    style="width: 100%"
                                    :disabled="viewVisible"
                                    :data="list_true_false"
                                    :multiple="false"
                                    :fields="['label', 'value']"
                                    v-model="party_branch_available"
                                    @input="
                                        onChangeHandler(
                                            'party_branch_available',
                                            $event
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <CustomTab
                        :initialTab="active_tab_school"
                        :tabs="list_tab_school"
                        :hideHr="true"
                    />

                    <CustomTable
                        :column-form="1"
                        :hide-pagi="true"
                        :tableModal="true"
                        :hideBtnTable="viewVisible"
                    >
                        <template v-slot:btn-table>
                            <CustomButton
                                v-if="!viewVisible"
                                label="Thêm mới điểm trường"
                                :icon="'el-icon-circle-plus-outline'"
                                @click="openModalAddNew"
                            />
                        </template>
                        <template v-slot:header>
                            <th>STT</th>
                            <th v-if="!viewVisible">Thao tác</th>
                            <!-- <th>Mã điểm trường</th> -->
                            <th>Tên điểm trường</th>
                            <th>Địa chỉ</th>
                            <th>Quận huyện</th>
                            <th>Tỉnh thành</th>
                            <th>Khoảng cách tới trường chính (km)</th>
                            <th>Số điện thoại</th>
                            <th>Phương tiện đi lại</th>
                            <th>Diện tích</th>
                            <th>Mô tả</th>
                        </template>
                        <template v-slot:body>
                            <template v-if="tableData.length === 0">
                                <tr>
                                    <td colspan="10">Không có bản ghi nào.</td>
                                </tr>
                            </template>
                            <tr v-for="(item, index) in tableData" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td v-if="!viewVisible">
                                    <img
                                        :src="iconEdit"
                                        class="icon"
                                        @click="openModalUpdate(item)"
                                    />
                                    <img
                                        :src="iconDelete"
                                        class="icon"
                                        @click="openModalDelete(item)"
                                    />
                                </td>
                                <!-- <td class="text-left">
                                                {{ item.maDiemTruong }}
                                            </td> -->
                                <td class="text-left">
                                    {{ item.tenDiemTruong }}
                                </td>
                                <td class="text-left">
                                    {{ item.diaChi }}
                                </td>
                                <td class="text-left">
                                    {{
                                        getLabelByArray(
                                            list_district,
                                            item.maQuanHuyen
                                        )
                                    }}
                                </td>

                                <td class="text-left">
                                    {{
                                        getLabelByArray(
                                            list_province,
                                            item.maTinhThanh
                                        )
                                    }}
                                </td>
                                <td>
                                    {{ item.khoangCach }}
                                    <span v-if="item.khoangCach"> km </span>
                                </td>
                                <td>{{ item.dienThoai }}</td>
                                <td>{{ item.phuongTien }}</td>
                                <td>
                                    {{ item.dienTich }}
                                    <span v-if="item.dienTich"> m2 </span>
                                </td>
                                <td>{{ item.moTa }}</td>
                            </tr>
                        </template>
                    </CustomTable>
                </div>
            </template>
        </FormLockUp>

        <CustomModal
            :is-modal-visible="backVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalBack"
        >
            <template v-slot:content>
                <strong>
                    Các thông tin vừa nhập sẽ bị mất khi đóng trang.
                    <br />
                    Thầy cô có muốn tiếp tục chỉnh sửa, kiểm tra và lưu lại thông
                    tin đã nhập?
                </strong>
            </template>
            <template v-slot:btn-handle>
                <CustomButton
                    label="Rời khỏi trang"
                    :btnSaveModal="true"
                    @click="navigateLockUpSchool"
                />
            </template>
        </CustomModal>

        <ModalAddNew
            :addNewVisible="addNewVisible"
            :closeModalAddNew="closeModalAddNew"
            @get_data_school_site="get_data_school_site"
        />
        <ModalUpdate
            :updateVisible="updateVisible"
            :closeModalUpdate="closeModalUpdate"
            :dataUpdate="dataUpdate"
            @get_data_school_site="get_data_school_site"
        />
        <ModalDelete
            :deleteVisible="deleteVisible"
            :closeModalDelete="closeModalDelete"
            :dataDelete="dataDelete"
            @get_data_school_site="get_data_school_site"
        />
    </div>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import FormLockUp from '@/components/FormLockUp.vue'
import CustomTab from '@/components/CustomTab.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import {
    validateFields,
    handlerError,
    updateChangedTitles
} from '@/utils/validator'
import {
    list_grade_group,
    list_type_of_education
} from '@/mock_data/tra-cuu-truong-hoc'
import { list_status } from '@/mock_data/tra-cuu-truong-hoc'
import ESelect from '@/components/ESelect.vue'
import DatePicker from '@/components/DatePicker.vue'
import { getFromSessionStorage } from '@/utils/session'
import {
    formatDateToYYYYMMDD,
    convertNumberToString,
    convertStringToNumber,
    formatDateToDDMMYYYY,
    getLabelByArray
} from '@/utils'
import ToolBar from '@/components/ToolBar.vue'
import CustomTable from '@/components/CustomTable.vue'
import CustomModal from '@/components/CustomModal.vue'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import ModalAddNew from './modal_add.vue'
import ModalUpdate from './modal_update.vue'
import ModalDelete from './modal_delete.vue'
import { path } from '@/router/path'
import { list_yes_no, list_true_false } from '@/mock_data/tra-cuu-truong-hoc'
import { showNotication } from '@/utils/notification'
export default {
    name: 'UpdateLookUpSchool',
    components: {
        CustomButton,
        CustomInput,
        ESelect,
        DatePicker,
        CustomTab,
        ToolBar,
        CustomTable,
        ModalAddNew,
        ModalUpdate,
        ModalDelete,
        FormLockUp,
        CustomModal
    },
    data() {
        return {
            iconEdit,
            iconDelete,

            titleGeneralInfo: {
                province: 'Tỉnh thành',
                district: 'Quận huyện',
                wards: 'Phường xã',
                village: 'Tổ thôn',
                unit_education: 'Đơn vị quản lý',
                school_code: 'Mã trường học',
                school_name: 'Tên trường học',
                grade_level: 'Cấp học',
                grade_group: 'Nhóm cấp học',
                type_of_education: 'Loại hình đào tạo',
                school_type: 'Loại hình trường',
                date: 'Ngày thành lập',
                national_standard: 'Chuẩn quốc gia',
                website: 'Website',
                principals_name: 'Tên hiệu trưởng',
                email: 'Email',
                fax: 'Fax',
                address: 'Địa chỉ',
                status: 'Trạng thái'
            },
            changedTitleGeneralInfo: [],

            titleOtherInfo: {
                school_telephone: 'Điện thoại trường học',
                principal_telephone: 'Điện thoại hiệu trưởng',
                area: 'Khu vực',
                special_difficult_area: 'Vùng đặc biệt khó khăn',
                drinking_water_available: 'Có nước uống',
                internet_access_for_teaching: 'Có khai thác internet cho dạy học',
                separate_sanitation_facilities_for_each_gender:
                    'Có công trình vệ sinh riêng rẽ cho từng giới tính',
                school_counseling_service: 'Có bộ phận công tác tư vấn học đường',
                electricity_available: 'Có điện (điện lưới)',
                convenient_handwashing_facilities:
                    'Có chỗ rửa tay thuận tiện (nước + xà phòng)',
                school_uses_clean_water_source:
                    'Trường có sử dụng nguồn nước sạch',
                school_uses_computers_for_teaching:
                    'Trường có sử dụng máy tính dạy học',
                school_points: 'Số điểm trường',
                center_code: 'Mã trung tâm',
                longitude: 'Kinh tuyến',
                latitude: 'Vĩ tuyến',
                map_location: 'Vị trí bản đồ',
                basic_education_program: 'Chương trình giáo dục cơ bản',
                non_specialized_classes_available: 'Có lớp không chuyên',
                sanitation_facilities_available: 'Có công trình vệ sinh',
                handwashing_education_program:
                    'Chương trình giáo dục vệ sinh đôi tay',
                meets_minimum_quality_standards: 'Đạt chất lượng tối thiểu',
                vocational_education: 'Dạy nghề phổ thông',
                acreage: 'Diện tích',
                area_two_sessions_per_day: 'Học 2 buổi trên ngày',
                boarding_students_available: 'Có học sinh bán trú',
                day_students_available: 'Có học sinh nội trú',
                students_with_disabilities_available: 'Có học sinh khuyết tật',
                social_life_skills_education: 'Kỹ năng sống giáo dục xã hội',
                area_code_of_difficult_circumstances: 'Mã vùng khó khăn',
                type_of_center_code: 'Mã loại trung tâm',
                disaster_and_epidemic_prevention_plan_available:
                    'Có kế hoạch phòng chống thiên tai dịch bệnh',
                bilingual_education_program_available:
                    'Có chương trình học song ngữ',
                total_number_of_staff: 'Tổng số cán bộ',
                total_number_of_classes: 'Tổng số lớp học',
                total_number_of_students: 'Tổng số học sinh',
                international_school: 'Trường quốc tế',
                career_guidance_counseling: 'Tư vấn giáo dục hướng nghiệp',
                infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available:
                    'Có cơ sở hạ tầng, tài liệu học tập, môi trường học tập phù hợp và tiếp cận với học sinh khuyết tật',
                disaster_prevention_climate_change_adaptation_education_plan_available:
                    'Có KH G.Dục phòng, chống thiên tai và Ứng phó biến đổi khí hậu',
                disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills:
                    'Phổ biến cho CMHS về các CTGD phòng, chống HIV trên nền tảng kỹ năng sống và giáo dục sức khỏe sinh sản',
                party_branch_available: 'Có chi bộ Đảng'
            },
            changedTitleOtherInfo: [],

            province: '',

            list_district: [],
            district: '',
            err_district: '',

            list_wards: [],
            wards: '',

            list_village: [],
            village: '',

            unit_education: '',
            err_unit_education: '',

            school_code: '',
            err_school_code: '',

            school_name: '',
            err_school_name: '',

            grade_level: [],
            err_grade_level: '',

            list_grade_group: list_grade_group,
            grade_group: '',
            err_grade_group: '',

            list_type_of_education: list_type_of_education,
            type_of_education: '',
            err_type_of_education: '',

            school_type: '',
            err_school_type: '',

            date: '',

            national_standard: '',

            website: '',

            principals_name: '',

            email: '',

            fax: '',

            address: '',

            list_status: list_status,
            status: '',

            list_yes_no: list_yes_no,
            list_true_false: list_true_false,

            school_telephone: '', // Điện thoại trường học
            principal_telephone: '', // Điện thoại hiệu trưởng
            area: '', // Khu vực
            special_difficult_area: '', // Vùng đặc biệt khó khăn
            drinking_water_available: '', // Có nước uống
            internet_access_for_teaching: '', // Có khai thác internet cho dạy học
            separate_sanitation_facilities_for_each_gender: '', // Có công trình vệ sinh riêng rẽ cho từng giới tính
            school_counseling_service: '', // Có bộ phận công tác tư vấn học đường
            electricity_available: '', // Có điện (điện lưới)
            convenient_handwashing_facilities: '', // Có chỗ rửa tay thuận tiện (nước + xà phòng)
            school_uses_clean_water_source: '', // Trường có sử dụng nguồn nước sạch
            school_uses_computers_for_teaching: '', // Trường có sử dụng máy tính dạy học
            school_points: '', // Số điểm trường
            center_code: '', // Mã trung tâm
            longitude: '', // Kinh tuyến
            latitude: '', // Vĩ tuyến
            map_location: '', // Vị trí bản đồ
            basic_education_program: '', // Chương trình giáo dục cơ bản
            non_specialized_classes_available: '', // Có lớp không chuyên
            sanitation_facilities_available: '', // Có công trình vệ sinh
            handwashing_education_program: '', // Chương trình giáo dục vệ sinh đôi tay
            meets_minimum_quality_standards: '', // Đạt chất lượng tối thiểu
            vocational_education: '', // Dạy nghề phổ thông
            acreage: '', // Diện tích
            area_two_sessions_per_day: '', // Học 2 buổi trên ngày
            boarding_students_available: '', // Có học sinh bán trú
            day_students_available: '', // Có học sinh nội trú
            students_with_disabilities_available: '', // Có học sinh khuyết tật
            social_life_skills_education: '', // Kỹ năng sống giáo dục xã hội
            area_code_of_difficult_circumstances: '', // Mã vùng khó khăn
            type_of_center_code: '', // Mã loại trung tâm
            disaster_and_epidemic_prevention_plan_available: '', // Có kế hoạch phòng chống thiên tai dịch bệnh
            bilingual_education_program_available: '', // Có chương trình học song ngữ
            total_number_of_staff: '', // Tổng số cán bộ
            total_number_of_classes: '', // Tổng số lớp học
            total_number_of_students: '', // Tổng số học sinh
            international_school: '', // Trường quốc tế
            career_guidance_counseling: '', // Tư vấn giáo dục hướng nghiệp
            infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available:
                '', // Có CS hạ tầng, TL học tập, MT học tập phù hợp và tiếp cận với HS khuyết tật
            disaster_prevention_climate_change_adaptation_education_plan_available:
                '', // Có KH G.Dục phòng, chống thiên tai và Ứng phó biến đổi khí hậu
            disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills:
                '', // Phổ biến cho CMHS về các CTGD phòng, chống HIV trên nền tảng KNS&SKSS
            party_branch_available: '', // Có chi bộ Đảng

            viewVisible: false,

            errModal: false,
            backVisible: false,
            tableData: [],

            addNewVisible: false,

            updateVisible: false,
            dataUpdate: {},

            deleteVisible: false,
            dataDelete: {},

            active_tab_info: 1,
            list_tab_info: [
                { number: 1, label: 'Thông tin chung' },
                { number: 2, label: 'Thông tin khác' }
            ],

            active_tab_school: 1,
            list_tab_school: [{ number: 1, label: 'Thông tin điểm trường' }]
        }
    },

    mounted() {
        this.handleClearError()
        this.get_data_school_site()
        this.get_info_school()
        this.bindDataInfoSchoolFromSession()
    },
    methods: {
        handleTabInfoChanged(tabNumber) {
            this.active_tab_info = tabNumber
        },
        getLabelByArray,
        getContentToolBar() {
            return this.viewVisible
                ? 'Thông tin trường học'
                : 'Chỉnh sửa thông tin trường học'
        },
        navigateLockUpSchool() {
            this.$router.push({ path: path.lock_up.school.home })
            this.changedTitleGeneralInfo = []
            this.changedTitleOtherInfo = []
        },

        openModalBack() {
            this.changedTitleGeneralInfo.length === 0 &&
            this.changedTitleOtherInfo.length === 0
                ? this.navigateLockUpSchool()
                : (this.backVisible = true)
        },
        closeModalBack() {
            this.backVisible = false
        },

        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalUpdate(item) {
            this.dataUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
        },
        openModalDelete(item) {
            this.dataDelete = item
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        convertStringToArray(str) {
            return str && str.length > 0 ? str.split('') : []
        },
        convertArrayToString(arr) {
            return Array.isArray(arr) ? arr.join('') : ''
        },
        bindDataInfoSchoolFromSession() {
            this.province = this.auth_user.ma_so
            if (this.province) {
                this.get_list_district()
            }
            this.district = this.data_lock_up_school.maQhuyen
            if (this.district) {
                this.get_list_wards()
            }
            this.wards = this.data_lock_up_school.maPxa
            if (this.wards) {
                this.get_list_village()
            }
            this.village = this.data_lock_up_school.maTthon

            this.unit_education = this.data_lock_up_school.maDonVi
            this.school_code = this.data_lock_up_school.maTruongHoc
            this.school_name = this.data_lock_up_school.tenTruongHoc
            this.grade_level = this.convertStringToArray(
                this.data_lock_up_school.capHoc
            )
            this.grade_group = this.data_lock_up_school.maNhomCapHoc
            this.type_of_education = this.data_lock_up_school.loaiHinhDaoTao
            this.school_type = convertNumberToString(
                this.data_lock_up_school.loaiHinhTruong
            )
            this.date = formatDateToYYYYMMDD(
                this.data_lock_up_school?.ngayThanhLap
            )

            this.national_standard = convertNumberToString(
                this.data_lock_up_school.chuanQuocGia
            )

            this.website = this.data_lock_up_school.website
            this.principals_name = this.data_lock_up_school.tenHieuTruong
            this.email = this.data_lock_up_school.email
            this.fax = this.data_lock_up_school.fax
            this.address = this.data_lock_up_school.diaChi
            this.status = convertNumberToString(
                this.data_lock_up_school.trangThai
            )

            this.viewVisible = this.data_lock_up_school.viewVisible
        },

        handleClearError() {
            this.errModal = false
            this.err_district = ''
            this.err_wards = ''
            this.err_unit_education = ''
            this.err_school_code = ''
            this.err_school_name = ''
            this.err_type_of_education = ''
            this.err_school_type = ''
            this.err_national_standard = ''
        },
        onChangeHandler(source, newValue) {
            this.changedTitleGeneralInfo = updateChangedTitles(
                this.titleGeneralInfo,
                this.changedTitleGeneralInfo,
                source,
                newValue
            )

            this.changedTitleOtherInfo = updateChangedTitles(
                this.titleOtherInfo,
                this.changedTitleOtherInfo,
                source,
                newValue
            )

            const errorFields = {
                district: 'err_district',
                unit_education: 'err_unit_education',
                school_code: 'err_school_code',
                school_name: 'err_school_name',
                grade_level: 'err_grade_level',
                type_of_education: 'err_type_of_education',
                school_type: 'err_school_type',
                grade_group: 'err_grade_group'
            }

            handlerError(this, source, newValue, errorFields)
        },
        async get_info_school() {
            try {
                const request_params = {
                    maTruongHoc: this.$route.params.id,
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.chi_tiet_truong_hoc,
                    null,
                    this.request_headers,
                    request_params
                )
                if (response.code == 200) {
                    // showNotication(this, 'success', response.message)
                } else {
                    showNotication(this, 'error', response.message)
                }
                const dataInfoSchool = response.truongHocTheoNamDto

                this.school_telephone = dataInfoSchool?.dienThoai
                this.principal_telephone = dataInfoSchool?.dienThoaiHieuTruong
                this.area = dataInfoSchool?.maKhuVuc
                this.special_difficult_area = dataInfoSchool?.maVungKhoKhan
                this.drinking_water_available = dataInfoSchool?.isCoNuocUong
                this.internet_access_for_teaching =
                    dataInfoSchool?.khaiThacInternetDayHoc
                this.separate_sanitation_facilities_for_each_gender =
                    dataInfoSchool?.isCoCtVeSinhTheoGioiTinh
                this.school_counseling_service =
                    dataInfoSchool?.congTacTuVanHocDuong
                this.electricity_available = dataInfoSchool?.dienLuoi
                this.convenient_handwashing_facilities =
                    dataInfoSchool?.isCoChoRuaTayThuanTien
                this.school_uses_clean_water_source =
                    dataInfoSchool?.nguonNuocSach
                this.school_uses_computers_for_teaching =
                    dataInfoSchool?.suDungMayTinhDayHoc
                this.school_points = dataInfoSchool?.soDiemTruong
                this.center_code = dataInfoSchool?.maTrungTam

                //     this.longitude= dataInfoSchool
                //     this.latitude= dataInfoSchool
                this.map_location = dataInfoSchool?.viTriBanDo
                this.basic_education_program =
                    dataInfoSchool?.chuongTrinhGiaoDucCoBan
                this.non_specialized_classes_available =
                    dataInfoSchool?.coLopKhongChuyen
                this.sanitation_facilities_available =
                    dataInfoSchool?.congTrinhVeSinh
                this.handwashing_education_program = dataInfoSchool?.ctGdvsDoiTay
                this.meets_minimum_quality_standards =
                    dataInfoSchool?.datChatLuongToiThieu
                this.vocational_education = dataInfoSchool?.dayNghePhoThong
                this.acreage = dataInfoSchool?.dienTich
                this.area_two_sessions_per_day = dataInfoSchool?.hoc2BuoiNgay
                this.boarding_students_available = dataInfoSchool?.hocSinhBanTru
                this.day_students_available = dataInfoSchool?.hocSinhNoiTru
                this.students_with_disabilities_available =
                    dataInfoSchool?.hocSinhKhuyetTat
                this.social_life_skills_education = dataInfoSchool?.kyNangSongGdxh
                this.area_code_of_difficult_circumstances =
                    dataInfoSchool?.maVungKhoKhan
                this.type_of_center_code = dataInfoSchool?.maLoaiTrungTam
                this.disaster_and_epidemic_prevention_plan_available =
                    dataInfoSchool?.khPcThienTaiDichBenh
                this.bilingual_education_program_available =
                    dataInfoSchool?.isCoChuongTrinhSongNgu
                this.total_number_of_staff = dataInfoSchool?.tongSoCanBo
                this.total_number_of_classes = dataInfoSchool?.tongSoLopHoc
                this.total_number_of_students = dataInfoSchool?.tongSoHocSinh
                this.international_school = dataInfoSchool?.truongQuocTe
                this.career_guidance_counseling =
                    dataInfoSchool?.ttGdtxHuongNghiep
                this.infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available =
                    dataInfoSchool?.coHaTangTlhtPhuHopHskt
                this.disaster_prevention_climate_change_adaptation_education_plan_available =
                    dataInfoSchool?.khPcThienTaiBdKhiHau

                this.disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills =
                    convertNumberToString(dataInfoSchool?.pbienKnsSkss)
                this.party_branch_available = dataInfoSchool?.coChiBoDang
            } catch (error) {
                console.log(error)
            }
        },
        async handleUpdate() {
            const fieldsToValidate = [
                {
                    value: this.district,
                    errorField: 'err_district'
                },

                {
                    value: this.unit_education,
                    errorField: 'err_unit_education'
                },

                {
                    value: this.school_code,
                    errorField: 'err_school_code'
                },

                {
                    value: this.school_name,
                    errorField: 'err_school_name'
                },

                {
                    value: this.grade_level,
                    errorField: 'err_grade_level'
                },

                {
                    value: this.type_of_education,
                    errorField: 'err_type_of_education'
                },

                {
                    value: this.school_type,
                    errorField: 'err_school_type'
                },
                {
                    value: this.grade_group,
                    errorField: 'err_grade_group'
                }
            ]

            if (validateFields(this, fieldsToValidate)) {
                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    capHoc: this.convertArrayToString(this.grade_level),
                    chiBoDang: this.data_lock_up_school.chiBoDang,
                    chuanQuocGia: this.national_standard,
                    diaChi: this.address,
                    email: this.email,
                    fax: this.fax,
                    idPhong: this.data_lock_up_school.idPhong,
                    idSo: this.auth_user.province,
                    loaiHinhDaoTao: this.type_of_education,
                    loaiHinhTruong: this.school_type,
                    ma: this.school_code,
                    maDoiTac: this.data_lock_up_school.maDoiTac,
                    maDonVi: this.unit_education,
                    maNhomCapHoc: this.grade_group,
                    maPhuongXa: this.wards,
                    maQhuyen: this.district,
                    maQuanHuyen: this.district,
                    maTinhThanh: this.province,
                    maTruongHoc: this.school_code,
                    maTthanh: this.province,
                    maTthon: this.village,
                    ngayThanhLap: formatDateToDDMMYYYY(this.date),

                    sdt: this.data_lock_up_school.sdt,
                    sdtHieuTruong: this.data_lock_up_school.sdtHieuTruong,
                    ten: this.school_name,
                    tenDonVi: this.data_lock_up_school.tenDonVi,
                    tenHieuTruong: this.principals_name,

                    tenTruongHoc: this.school_name,
                    trangThai: this.status,
                    website: this.website,
                    tenPhuongXa: this.data_lock_up_school.tenPhuongXa,
                    tenQuanHuyen: this.data_lock_up_school.tenQuanHuyen,
                    tenTinhThanh: this.data_lock_up_school.tenTinhThanh,
                    tenToThon: this.data_lock_up_school.tenToThon,

                    vungBienGioi: this.data_lock_up_school.vungBienGioi,
                    vungKhoKhan: this.data_lock_up_school.vungKhoKhan,

                    dienThoai: this.school_telephone,
                    dienThoaiHieuTruong: this.principal_telephone,
                    maKhuVuc: this.area,
                    // maVungKhoKhan: this.special_difficult_area,
                    isCoNuocUong: this.drinking_water_available,
                    khaiThacInternetDayHoc: this.internet_access_for_teaching,
                    isCoCtVeSinhTheoGioiTinh:
                        this.separate_sanitation_facilities_for_each_gender,
                    congTacTuVanHocDuong: this.school_counseling_service,
                    dienLuoi: this.electricity_available,
                    isCoChoRuaTayThuanTien:
                        this.convenient_handwashing_facilities,
                    nguonNuocSach: this.school_uses_clean_water_source,
                    suDungMayTinhDayHoc: this.school_uses_computers_for_teaching,
                    soDiemTruong: this.school_points,
                    maTrungTam: this.center_code,
                    //     this.longitude=                //     this.latitude=                this.map_location = viTriBanDo
                    chuongTrinhGiaoDucCoBan: this.basic_education_program,
                    coLopKhongChuyen: this.non_specialized_classes_available,

                    congTrinhVeSinh: this.sanitation_facilities_available,
                    ctGdvsDoiTay: this.handwashing_education_program,
                    datChatLuongToiThieu: this.meets_minimum_quality_standards,
                    dayNghePhoThong: this.vocational_education,
                    dienTich: this.acreage,
                    hoc2BuoiNgay: this.area_two_sessions_per_day,
                    hocSinhBanTru: this.boarding_students_available,
                    hocSinhNoiTru: this.day_students_available,
                    hocSinhKhuyetTat: this.students_with_disabilities_available,
                    kyNangSongGdxh: this.social_life_skills_education,
                    maVungKhoKhan: this.area_code_of_difficult_circumstances,
                    maLoaiTrungTam: this.type_of_center_code,
                    khPcThienTaiDichBenh:
                        this.disaster_and_epidemic_prevention_plan_available,
                    isCoChuongTrinhSongNgu:
                        this.bilingual_education_program_available,
                    tongSoCanBo: this.total_number_of_staff,
                    tongSoLopHoc: this.total_number_of_classes,
                    tongSoHocSinh: this.total_number_of_students,
                    truongQuocTe: this.international_school,
                    ttGdtxHuongNghiep: this.career_guidance_counseling,

                    coHaTangTlhtPhuHopHskt:
                        this
                            .infrastructure_learning_support_facilities_and_accessibility_for_students_with_disabilities_available,
                    khPcThienTaiBdKhiHau:
                        this
                            .disaster_prevention_climate_change_adaptation_education_plan_available,
                    pbienKnsSkss: convertStringToNumber(
                        this
                            .disseminate_information_to_students_about_hiv_aids_prevention_based_on_knowledge_attitudes_and_life_skills
                    ),

                    coChiBoDang: this.party_branch_available
                }

                try {
                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_truong_hoc.chinh_sua_truong_hoc,
                        request_data,
                        this.request_headers
                    )
                    if (response.code == 200) {
                        this.navigateLockUpSchool()
                        showNotication(this, 'success', response.message)
                    } else {
                        showNotication(this, 'error', response.message)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async get_data_school_site() {
            try {
                const request_data = {
                    maSo: this.auth_user.ma_so,
                    maTruongs: [this.$route.params.id],
                    namHoc: this.selectedYearFromStore
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.danh_sach_diem_truong,
                    request_data,
                    this.request_headers
                )

                this.tableData = response.rows
            } catch (error) {
                console.log(error)
            }
        },

        async get_list_district() {
            try {
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.auth_user.ma_so
                }
                const response = await sendRequest(
                    Api.app.quan_huyen,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_district = convertListData(
                    response.rows,
                    'tenQuanHuyen',
                    'maQuanHuyen'
                )
            } catch (error) {
                console.log(error)
            }
        },

        onChangeDistrict() {
            this.wards = ''
            this.list_wards = []
            this.get_list_wards()

            this.village = ''
            this.list_village = []
        },

        async get_list_wards() {
            try {
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.auth_user.ma_so,
                    maQuanHuyen: this.district
                }
                const response = await sendRequest(
                    Api.app.phuong_xa,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_wards = convertListData(
                    response.rows,
                    'tenPhuongXa',
                    'maPhuongXa'
                )
            } catch (error) {
                console.log(error)
            }
        },
        onChangeWards() {
            this.village = ''
            this.get_list_village()
        },

        async get_list_village() {
            try {
                const request_params = {
                    limit: 999,
                    maPhuongXa: this.wards,
                    maQuanHuyen: this.district,
                    maTinhThanh: this.auth_user.ma_so,
                    start: 0
                }

                const response = await sendRequest(
                    Api.app.to_thon,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_village = convertListData(
                    response.rows,
                    'tenToThon',
                    'maToThon'
                )
            } catch (error) {
                console.log(error)
            }
        }
    },

    computed: {
        list_province() {
            return getFromSessionStorage('list_province')
        },
        list_grade_level() {
            return getFromSessionStorage('list_grade_level')
        },
        data_lock_up_school() {
            return getFromSessionStorage('data_lock_up_school')
        },
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_school_type() {
            return getFromSessionStorage('DM_LOAI_HINH_TRUONG')
        },
        list_national_standard() {
            return getFromSessionStorage('DM_MUC_DAT_CHUAN_QG_CLGD')
        },
        list_area() {
            return getFromSessionStorage('DM_KHU_VUC')
        },
        list_center_code() {
            return getFromSessionStorage('DM_TRUNG_TAM')
        },
        list_area_code_of_difficult_circumstances() {
            return getFromSessionStorage('DM_VUNG_KHO_KHAN')
        },
        list_type_of_center_code() {
            return getFromSessionStorage('DM_LOAI_TRUNG_TAM')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.mb-2::before,
.mt-2::before {
    content: '• ';
    font-weight: bold;
}
.color-red {
    color: red;
}
.fw {
    font-weight: 600;
}
</style>
