<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="disciplineVisible"
        :modal-title="'Kỷ luật'"
        :close-function="closeModalDiscipline"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <CustomTable
                :column-form="2"
                :hide-btn-table="true"
                :hidePagi="true"
                :tableModal="true"
            >
                <template v-slot:header>
                    <tr>
                        <th>STT</th>
                        <th>Kỷ luật <span class="error-label">*</span></th>
                        <th>Cấp ký quyết định</th>
                        <th>Số quyết định</th>
                        <th>Ngày kỷ luật</th>
                    </tr>
                </template>
                <template v-slot:body>
                    <template v-if="dataDiscipline.length === 0">
                        <tr>
                            <td colspan="10">Không có bản ghi nào.</td>
                        </tr>
                    </template>
                    <tr v-for="(item, index) in dataDiscipline" :key="index">
                        <td>
                            {{ index + 1 }}
                        </td>

                        <td>
                            <ESelect
                                style="width: 100%"
                                :clearable="true"
                                :disabled="true"
                                :data="list_discipline"
                                :placeholder="'Chọn '"
                                :filterable="true"
                                :multiple="false"
                                :collapse-tags="true"
                                :fields="['label', 'value']"
                                v-model="item.maKyLuatGv"
                            />
                        </td>

                        <td>
                            <CustomInput
                                v-model="item.capKyLuat"
                                placeholder=""
                                :disabled="true"
                            >
                            </CustomInput>
                        </td>
                        <td>
                            <CustomInput
                                v-model="item.soQuyetDinhKyLuat"
                                placeholder=""
                                :disabled="true"
                            >
                            </CustomInput>
                        </td>

                        <td>
                            {{ item.ngayKyLuat }}
                        </td>
                    </tr>
                </template>
            </CustomTable>
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import CustomModal from '@/components/CustomModal.vue'
import CustomTable from '@/components/CustomTable.vue'
import { getFromSessionStorage } from '@/utils/session'
export default {
    name: 'ModalDiscipline',
    data() {
        return { errModal: false }
    },
    components: {
        CustomModal,
        CustomTable,
        ESelect,
        CustomInput
    },
    props: {
        disciplineVisible: {
            type: Boolean,
            required: true
        },
        closeModalDiscipline: Function,
        dataDiscipline: {
            type: Array,
            required: true
        }
    },

    methods: {},
    mounted() {},
    computed: {
        list_discipline() {
            return getFromSessionStorage('DM_KY_LUAT_GV')
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
}
::v-deep .el-input.is-disabled .el-input__inner {
    color: black;
}
</style>
