<template>
    <CustomModal
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới cấu hình lịch đồng bộ cấp Trường'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-4">
                    <label>Đơn vị</label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                        @input="onChangeHandler('unit_education', $event)"
                    />
                </div>
                <div class="col-sm-3">
                    <label>Cấp học</label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_grade_level"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="grade_level"
                        @change="onChangeGradeLevel"
                        @input="onChangeHandler('grade_level', $event)"
                    />
                </div>
                <div class="col-sm-5">
                    <label>Trường học <span class="error-label">*</span></label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                        @input="onChangeHandler('school', $event)"
                        :show-error="err_school.length > 0"
                        :class="{
                            'error-select': err_school.length > 0
                        }"
                    />
                    <span v-if="err_school.length > 0" class="text-error-select">
                        {{ err_school }}
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-12">
                    <label>Thời gian nộp</label>
                    <span class="error-label">*</span>
                </div>
                <div class="col-md-3">
                    <DatePicker
                        v-model="start_date"
                        @input="onChangeHandler('start_date', $event)"
                        :showError="err_start_date.length > 0"
                    />
                    <span
                        v-if="err_start_date.length > 0"
                        class="text-error-select"
                    >
                        {{ err_start_date }}
                    </span>
                </div>

                <div class="col-md-3">
                    <DatePicker
                        v-model="end_date"
                        @input="onChangeHandler('end_date', $event)"
                        :showError="err_end_date.length > 0"
                    />
                    <span
                        v-if="err_end_date.length > 0"
                        class="text-error-select"
                    >
                        {{ err_end_date }}
                    </span>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleAddNew"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import DatePicker from '@/components/DatePicker.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
import { formatDateToDDMMYYYY, convertListData } from '@/utils'
import ESelect from '@/components/ESelect.vue'
import { validateFields, handlerError } from '@/utils/validator'
import { ROLES, checkUserRole } from '@/constants/Roles'
export default {
    name: 'ModalAdd',
    data() {
        return {
            unit_education: [],
            grade_level: [],
            list_school: [],
            school: [],
            start_date: '',
            end_date: '',

            err_school: '',
            err_end_date: '',
            err_start_date: '',
            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function
    },
    components: { CustomButton, CustomModal, DatePicker, ESelect },
    methods: {
        async get_list_school() {
            const request_data = {
                capHoc: this.grade_level,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        resetSchool() {
            this.school = []
            this.err_school = ''
            this.get_list_school()
        },
        onChangeUnitEducation() {
            this.resetSchool()
        },
        onChangeGradeLevel() {
            this.resetSchool()
        },

        onChangeHandler(source, newValue) {
            const noValidate = ['grade_level', 'unit_education']

            if (noValidate.includes(source) && newValue.length > 0) {
                this.errModal = true
            } else {
                this.errModal = false
            }

            const errorFields = {
                school: 'err_school',
                start_date: 'err_start_date',
                end_date: 'err_end_date'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        handleClearData() {
            this.unit_education = []
            this.grade_level = []
            this.school = []
            this.errModal = false
            this.err_school = ''
            this.start_date = ''
            this.err_start_date = ''
            this.end_date = ''
            this.err_end_date = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        async handleAddNew() {
            const fieldsToValidate = [
                {
                    value: this.school,
                    errorField: 'err_school'
                },
                {
                    value: this.start_date,
                    errorField: 'err_start_date'
                },
                {
                    value: this.end_date,
                    errorField: 'err_end_date'
                }
            ]
            if (validateFields(this, fieldsToValidate)) {
                const request_data = {
                    ngayBatDau: formatDateToDDMMYYYY(this.start_date),
                    ngayKetThuc: formatDateToDDMMYYYY(this.end_date),
                    dsMaTruong: this.school,
                    maSo: this.auth_user.ma_so,
                    namHoc: this.selectedYearFromStore
                }
                try {
                    const response = await sendRequest(
                        Api.tiep_nhan_du_lieu_QLNT.cau_hinh_lich_dong_bo
                            .them_moi_ngay_cap_truong,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)
                        this.handleCloseModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                } catch (error) {
                    if (error.response?.data.rd) {
                        showNotication(this, 'error', error.response?.data.rd)
                    }
                }
            }
        }
    },
    mounted() {
        this.get_list_school()
    },
    watch: {
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_phong ? [user.ma_phong] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_grade_level() {
            return getFromSessionStorage('list_grade_level')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
::v-deep .is-error .el-input__inner {
    border: 1px solid #f56c6c !important;
}
::v-deep .is-error .el-input__inner:focus {
    border: 1px solid #f56c6c !important;
    box-shadow: 0 0 3px #f56c6c;
}
</style>
