<template>
    <div>
        <ToolBar :menu="'Hệ thống'" :content="'Quản lý vai trò'">
            <template v-slot:form>
                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    @click="openModalAddNew"
                />
            </template>
        </ToolBar>

        <CustomTable :tableData="tableData" :hidePagi="true" :hideBtnTable="true">
            <template v-slot:header>
                <tr class="text-center">
                    <th>STT</th>

                    <th>Tên vai trò</th>
                    <th>Vai trò gốc</th>
                    <th>Mô tả</th>
                    <th>Thao tác</th>
                </tr>
            </template>
            <template v-slot:body>
                <tr
                    v-for="(item, index) in tableData"
                    :key="index"
                    class="text-center"
                >
                    <td>
                        {{ index + 1 }}
                    </td>

                    <td class="text-left">{{ item.roleName }}</td>
                    <td class="text-left">{{ getNameRole(item.parentRole) }}</td>
                    <td class="text-left">{{ item.description }}</td>
                    <td>
                        <span v-if="item.builtIn === true">
                            <CustomToolTip
                                content="Vai trò mặc định. Không thể chỉnh sửa"
                            >
                                <img :src="iconEdit" class="icon-dislabel" />
                            </CustomToolTip>
                        </span>
                        <span v-else>
                            <CustomToolTip content="Chỉnh sửa">
                                <img
                                    :src="iconEdit"
                                    class="icon"
                                    @click="openModalUpdate(item)"
                                />
                            </CustomToolTip>
                        </span>

                        <span v-if="item.builtIn === true">
                            <CustomToolTip
                                content="Vai trò mặc định. Không thể xóa"
                            >
                                <img :src="iconDelete" class="icon-dislabel" />
                            </CustomToolTip>
                        </span>
                        <span v-else>
                            <CustomToolTip content="Xóa">
                                <img
                                    :src="iconDelete"
                                    class="icon"
                                    @click="openModalDelete(item.roleId)"
                                />
                            </CustomToolTip>
                        </span>
                    </td>
                </tr>
            </template>
        </CustomTable>
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="deleteVisible"
            :modal-title="'Xóa vai trò'"
            :close-function="closeModalDelete"
            :size="'sm'"
        >
            <template v-slot:content>
                <div>Xác nhận xóa thông tin vai trò ?</div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Xóa"
                    @click="handleDelete"
                    :type="'danger'"
                />
            </template>
        </CustomModal>
        <ModalAddNew
            :closeModalAddNew="closeModalAddNew"
            :addNewVisible="addNewVisible"
            @handleSearch="handleSearch"
        />
        <ModalUpdate
            :closeModalUpdate="closeModalUpdate"
            :updateVisible="updateVisible"
            @handleSearch="handleSearch"
            :dataUpdate="dataUpdate"
        />
    </div>
</template>

<script>
import ToolBar from '@/components/ToolBar.vue'
import CustomTable from '@/components/CustomTable.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import ModalAddNew from './modal_add.vue'
import ModalUpdate from './modal_update.vue'
import { list_role } from '@/mock_data/quan-ly-vai-tro'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import CustomToolTip from '@/components/CustomToolTip.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'DepartmentLevel',

    components: {
        ToolBar,
        CustomTable,
        CustomButton,
        ModalAddNew,
        CustomModal,
        CustomToolTip,
        ModalUpdate
    },
    data() {
        return {
            iconEdit,
            iconDelete,

            list_role: list_role,
            tableData: [],
            addNewVisible: false,

            updateVisible: false,
            dataUpdate: {},

            deleteVisible: false,
            roleId: null
        }
    },
    methods: {
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalUpdate(item) {
            this.dataUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
        },
        openModalDelete(roleId) {
            this.roleId = roleId
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        getNameRole(parentRole) {
            const role = this.list_role.find((role) => role.value === parentRole)
            return role ? role.label : ''
        },
        async handleDelete() {
            try {
                const request_data = {
                    roleId: this.roleId
                }

                const response = await sendRequest(
                    Api.he_thong.quan_ly_vai_tro.xoa,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    await this.handleSearch()
                    this.closeModalDelete()
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleSearch() {
            try {
                const response = await sendRequest(
                    Api.he_thong.quan_ly_vai_tro.danh_sach_vai_tro,
                    null,
                    this.request_headers
                )
                this.tableData = response
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.handleSearch()
    },
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
