<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="saveTemplateVisible"
        :modal-title="'Lưu mẫu'"
        :close-function="handleCloseModalSaveTemplate"
        :size="'lg'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row d-flex align-items-center justify-content-center">
                <div class="col-sm-12">
                    <label>Tên mẫu: <span class="error-label">*</span></label>
                    <div>
                        <CustomInput
                            v-model="name_template"
                            @input="onChangeHandler('name_template', $event)"
                            :textError="err_name_template"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleSaveTemplate"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { validateFields, handlerError } from '@/utils/validator'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalSaveTemplate',
    components: { CustomButton, CustomModal, CustomInput },
    data() {
        return {
            name_template: '',
            err_name_template: '',

            errModal: false
        }
    },
    props: {
        saveTemplateVisible: {
            type: Boolean,
            required: true
        },
        closeModalSaveTemplate: Function,

        prepareRequestData: Function
    },

    methods: {
        handleClearData() {
            this.errModal = false
        },
        handleCloseModalSaveTemplate() {
            this.closeModalSaveTemplate()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                name_template: 'err_name_template'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async handleSaveTemplate() {
            const prepareRequestData = this.prepareRequestData()

            const fieldsToValidate = [
                {
                    value: this.name_template,
                    errorField: 'err_name_template'
                }
            ]
            if (validateFields(this, fieldsToValidate)) {
                const request_data = {
                    ...prepareRequestData,
                    type: 1,
                    name: this.name_template,
                    hienThiBoLoc: {}
                }
                try {
                    const response = await sendRequest(
                        Api.bao_cao.bao_cao_dong.bao_cao_hoc_sinh.luu_mau,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)
                        this.closeModalSaveTemplate()
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                } catch (error) {
                    if (error.response?.data.rd) {
                        showNotication(this, 'error', error.response?.data.rd)
                    }
                }
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
