<template>
    <div>
        <ToolBar
            :menu="'Tra cứu trường học'"
            :content="'Thêm mới trường học'"
            :hideToggle="true"
            :hideZoom="true"
        >
            <template v-slot:form>
                <CustomButton
                    label="Quay lại"
                    @click="openModalBack"
                    :plain="true"
                />
                <CustomButton label="Lưu" @click="handleAddNew" />
            </template>
        </ToolBar>
        <FormLockUp>
            <template v-slot:lock-up-container>
                <CustomTab
                    :initialTab="active_tab_info"
                    :tabs="list_tab_info"
                    :hideHr="false"
                />
                <div class="form-update mt-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.province }}
                                        </label>
                                        <div class="n-index error">[1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="true"
                                        :data="list_province"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="province"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.district }}
                                        </label>
                                        <div class="n-index error">[2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="false"
                                        :data="list_district"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="district"
                                        :show-error="err_district.length > 0"
                                        @input="
                                            onChangeHandler('district', $event)
                                        "
                                        @change="onChangeDistrict"
                                        :class="{
                                            'error-select':
                                                err_district.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_district.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_district }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.wards }}
                                        </label>
                                        <div class="n-index">[3]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="false"
                                        :data="list_wards"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="wards"
                                        @change="onChangeWards"
                                        @input="onChangeHandler('wards', $event)"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.village }}
                                        </label>
                                        <div class="n-index">[4]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="false"
                                        :data="list_village"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="village"
                                        @input="
                                            onChangeHandler('village', $event)
                                        "
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.unit_education }}
                                        </label>
                                        <div class="n-index error">[5]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="isRoleEducationOfficeOrSchool"
                                        :data="list_unit_education"
                                        :multiple="false"
                                        :fields="['tenDonVi', 'maDonVi']"
                                        v-model="unit_education"
                                        @input="
                                            onChangeHandler(
                                                'unit_education',
                                                $event
                                            )
                                        "
                                        :class="{
                                            'error-select':
                                                err_unit_education.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_unit_education.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_unit_education }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.school_code }}</label
                                        >
                                        <div class="n-index error">[6]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="school_code"
                                        @input="
                                            onChangeHandler('school_code', $event)
                                        "
                                        :textError="err_school_code"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.school_name }}</label
                                        >
                                        <div class="n-index error">[7]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="school_name"
                                        @input="
                                            onChangeHandler('school_name', $event)
                                        "
                                        :textError="err_school_name"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.grade_level }}
                                        </label>
                                        <div class="n-index error">[8]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="false"
                                        :data="list_grade_level"
                                        :multiple="true"
                                        :fields="['label', 'value']"
                                        v-model="grade_level"
                                        @input="
                                            onChangeHandler('grade_level', $event)
                                        "
                                        :class="{
                                            'error-select':
                                                err_grade_level.length > 0
                                        }"
                                    />

                                    <span
                                        v-if="err_grade_level.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_grade_level }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.grade_group }}</label
                                        >
                                        <div class="n-index error">[9]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="false"
                                        :data="list_grade_group"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="grade_group"
                                        @input="
                                            onChangeHandler('grade_group', $event)
                                        "
                                        :class="{
                                            'error-select':
                                                err_grade_group.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_grade_group.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_grade_group }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.type_of_education }}
                                        </label>
                                        <div class="n-index error">[10]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="false"
                                        :data="list_type_of_education"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="type_of_education"
                                        @input="
                                            onChangeHandler(
                                                'type_of_education',
                                                $event
                                            )
                                        "
                                        :class="{
                                            'error-select':
                                                err_type_of_education.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_type_of_education.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_type_of_education }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.school_type }}</label
                                        >
                                        <div class="n-index error">[11]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="false"
                                        :data="list_school_type"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="school_type"
                                        @input="
                                            onChangeHandler('school_type', $event)
                                        "
                                        :class="{
                                            'error-select':
                                                err_school_type.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_school_type.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_school_type }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.date }}
                                        </label>
                                        <div class="n-index">[12]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="date"
                                        @input="onChangeHandler('date', $event)"
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.national_standard }}
                                        </label>
                                        <div class="n-index">[13]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="false"
                                        :data="list_national_standard"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="national_standard"
                                        @input="
                                            onChangeHandler(
                                                'national_standard',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.website }}
                                        </label>
                                        <div class="n-index">[14]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="website"
                                        @input="
                                            onChangeHandler('website', $event)
                                        "
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.principals_name }}
                                        </label>
                                        <div class="n-index">[15]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="principals_name"
                                        @input="
                                            onChangeHandler(
                                                'principals_name',
                                                $event
                                            )
                                        "
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.email }}
                                        </label>
                                        <div class="n-index">[16]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="email"
                                        :trimAll="true"
                                        @input="onChangeHandler('email', $event)"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.fax }}
                                        </label>
                                        <div class="n-index">[17]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="fax"
                                        @input="onChangeHandler('fax', $event)"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label"
                                            >{{ objectTitle.address }}
                                        </label>
                                        <div class="n-index">[18]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="address"
                                        @input="
                                            onChangeHandler('address', $event)
                                        "
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.status }}
                                        </label>
                                        <div class="n-index">[19]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="false"
                                        :data="list_status"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="status"
                                        @input="onChangeHandler('status', $event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </FormLockUp>

        <CustomModal
            :is-modal-visible="backVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalBack"
        >
            <template v-slot:content>
                <!-- <span>
                    Các thông tin
                    <span
                        class="bold"
                        v-for="(title, index) in changedTitles"
                        :key="title"
                    >
                        <strong> {{ title }}</strong>
                        <span v-if="index < changedTitles.length - 1">, </span>
                    </span>
                    vừa nhập sẽ bị mất khi đóng trang. Thầy cô có muốn tiếp tục
                    quay lại?
                </span> -->
                <strong>
                    Các thông tin vừa nhập sẽ bị mất khi đóng trang.
                    <br />
                    Thầy cô có muốn tiếp tục chỉnh sửa, kiểm tra và lưu lại thông
                    tin đã nhập?
                </strong>
            </template>
            <template v-slot:btn-handle>
                <CustomButton
                    label="Rời khỏi trang"
                    :btnSaveModal="true"
                    @click="navigateLockUpSchool"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import FormLockUp from '@/components/FormLockUp.vue'
import CustomTab from '@/components/CustomTab.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomModal from '@/components/CustomModal.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import {
    validateFields,
    handlerError,
    updateChangedTitles
} from '@/utils/validator'
import {
    list_grade_group,
    list_type_of_education
} from '@/mock_data/tra-cuu-truong-hoc'
import { list_status } from '@/mock_data/tra-cuu-truong-hoc'
import ESelect from '@/components/ESelect.vue'
import DatePicker from '@/components/DatePicker.vue'
import { getFromSessionStorage } from '@/utils/session'
import ToolBar from '@/components/ToolBar.vue'
import { path } from '@/router/path'
import { ROLES, checkUserRole } from '@/constants/Roles'
import { showNotication } from '@/utils/notification'
export default {
    name: 'AddLookUpSchool',
    components: {
        CustomButton,
        CustomInput,
        ESelect,
        DatePicker,
        CustomModal,
        ToolBar,
        FormLockUp,

        CustomTab
    },
    data() {
        return {
            active_tab_info: 1,
            list_tab_info: [{ number: 1, label: 'Thông tin chung' }],

            objectTitle: {
                province: 'Tỉnh thành',
                district: 'Quận huyện',
                wards: 'Phường xã',
                village: 'Tổ thôn',
                unit_education: 'Đơn vị quản lý',
                school_code: 'Mã trường học',
                school_name: 'Tên trường học',
                grade_level: 'Cấp học',
                grade_group: 'Nhóm cấp học',
                type_of_education: 'Loại hình đào tạo',
                school_type: 'Loại hình trường',
                date: 'Ngày thành lập',
                national_standard: 'Chuẩn quốc gia',
                website: 'Website',
                principals_name: 'Tên hiệu trưởng',
                email: 'Email',
                fax: 'Fax',
                address: 'Địa chỉ',
                status: 'Trạng thái'
            },
            changedTitles: [],

            province: '',

            list_district: [],
            district: '',
            err_district: '',

            list_wards: [],
            wards: '',

            list_village: [],
            village: '',

            unit_education: '',
            err_unit_education: '',

            school_code: '',
            err_school_code: '',

            school_name: '',
            err_school_name: '',

            grade_level: [],
            err_grade_level: '',

            list_grade_group: list_grade_group,
            grade_group: '',
            err_grade_group: '',

            list_type_of_education: list_type_of_education,
            type_of_education: '',
            err_type_of_education: '',

            school_type: '',
            err_school_type: '',

            date: '',

            national_standard: '',

            website: '',

            principals_name: '',

            email: null,

            fax: '',

            address: '',

            list_status: list_status,
            status: '',

            errModal: false,
            backVisible: false
        }
    },

    methods: {
        navigateLockUpSchool() {
            this.$router.push({ path: path.lock_up.school.home })
            this.changedTitles = []
        },

        openModalBack() {
            this.changedTitles.length === 0
                ? this.navigateLockUpSchool()
                : (this.backVisible = true)
        },
        closeModalBack() {
            this.backVisible = false
        },

        handleClearData() {
            this.errModal = false
            this.district = ''
            this.err_district = ''

            this.wards = ''

            this.village = ''

            this.unit_education = ''
            this.err_unit_education = ''

            this.school_code = ''
            this.err_school_code = ''

            this.school_name = ''
            this.err_school_name = ''
            this.grade_level = []
            this.err_grade_level = ''
            this.grade_group = ''
            this.err_grade_group = ''

            this.type_of_education = ''
            this.err_type_of_education = ''

            this.school_type = ''
            this.err_school_type = ''

            this.date = ''

            this.national_standard = ''

            this.website = ''

            this.principals_name = ''

            this.email = ''

            this.fax = ''

            this.address = ''

            this.status = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            this.changedTitles = updateChangedTitles(
                this.objectTitle,
                this.changedTitles,
                source,
                newValue
            )

            const errorFields = {
                district: 'err_district',
                unit_education: 'err_unit_education',
                school_code: 'err_school_code',
                school_name: 'err_school_name',
                grade_level: 'err_grade_level',
                grade_group: 'err_grade_group',
                type_of_education: 'err_type_of_education',
                school_type: 'err_school_type'
            }

            handlerError(this, source, newValue, errorFields)
        },
        async handleAddNew() {
            const fieldsToValidate = [
                {
                    value: this.district,
                    errorField: 'err_district'
                },

                {
                    value: this.unit_education,
                    errorField: 'err_unit_education'
                },

                {
                    value: this.school_code,
                    errorField: 'err_school_code'
                },

                {
                    value: this.school_name,
                    errorField: 'err_school_name'
                },

                {
                    value: this.grade_level,
                    errorField: 'err_grade_level'
                },
                {
                    value: this.grade_group,
                    errorField: 'err_grade_group'
                },

                {
                    value: this.type_of_education,
                    errorField: 'err_type_of_education'
                },

                {
                    value: this.school_type,
                    errorField: 'err_school_type'
                }
            ]

            if (validateFields(this, fieldsToValidate)) {
                const convertCapHoc = parseInt(
                    this.grade_level.sort((a, b) => a - b).join(''),
                    10
                )

                const request_data = {
                    capHoc: convertCapHoc,
                    capHocs: this.grade_level,
                    chiBoDang: null,
                    chuanQuocGia: this.national_standard,
                    diaChi: this.address,
                    email: this.email,
                    fax: this.fax,
                    idPhong: '',
                    idSo: this.auth_user.province,
                    loaiHinhDaoTao: this.type_of_education,
                    loaiHinhTruong: this.school_type,
                    ma: this.school_code,
                    maDoiTac: '',
                    maDonVi: this.unit_education,
                    maNhomCapHoc: this.grade_group,
                    maPhuongXa: this.wards,
                    maQhuyen: this.district,
                    maQuanHuyen: this.district,
                    maTinhThanh: this.province,
                    maTruongHoc: this.school_code,
                    maTthanh: this.province,
                    maTthon: this.village,
                    ngayThanhLap: this.date,
                    sdt: '',
                    sdtHieuTruong: '',
                    ten: this.school_name,
                    tenDonVi: '',
                    tenHieuTruong: this.principals_name,
                    tenPhuongXa: '',
                    tenQuanHuyen: '',
                    tenTinhThanh: '',
                    tenToThon: '',
                    tenTruongHoc: this.school_name,
                    trangThai: this.status,
                    vungBienGioi: '',
                    vungKhoKhan: '',
                    website: this.website
                }

                try {
                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_truong_hoc.them_truong_hoc,
                        request_data,
                        this.request_headers
                    )
                    if (response.code == 200) {
                        showNotication(this, 'success', response.message)
                        this.navigateLockUpSchool()
                    } else {
                        showNotication(this, 'error', response.message)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async get_list_district() {
            try {
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.auth_user.ma_so
                }
                const response = await sendRequest(
                    Api.app.quan_huyen,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_district = convertListData(
                    response.rows,
                    'tenQuanHuyen',
                    'maQuanHuyen'
                )
            } catch (error) {
                console.log(error)
            }
        },

        onChangeDistrict() {
            this.wards = ''
            this.list_wards = []
            this.get_list_wards()

            this.village = ''
            this.list_village = []
        },

        async get_list_wards() {
            try {
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: this.auth_user.ma_so,
                    maQuanHuyen: this.district
                }
                const response = await sendRequest(
                    Api.app.phuong_xa,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_wards = convertListData(
                    response.rows,
                    'tenPhuongXa',
                    'maPhuongXa'
                )
            } catch (error) {
                console.log(error)
            }
        },
        onChangeWards() {
            this.list_village = []
            this.village = ''
            this.get_list_village()
        },

        async get_list_village() {
            try {
                const request_params = {
                    limit: 999,
                    maPhuongXa: this.wards,
                    maQuanHuyen: this.district,
                    maTinhThanh: this.auth_user.ma_so,
                    start: 0
                }

                const response = await sendRequest(
                    Api.app.to_thon,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_village = convertListData(
                    response.rows,
                    'tenToThon',
                    'maToThon'
                )
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.province = this.auth_user.ma_so
        if (this.province) {
            this.get_list_district()
        }
    },
    watch: {
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_phong
                }
            },
            immediate: true
        }
    },
    computed: {
        list_province() {
            return getFromSessionStorage('list_province')
        },
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_grade_level() {
            return getFromSessionStorage('list_grade_level')
        },
        list_school_type() {
            return getFromSessionStorage('DM_LOAI_HINH_TRUONG')
        },
        list_national_standard() {
            return getFromSessionStorage('DM_MUC_DAT_CHUAN_QG_CLGD')
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
