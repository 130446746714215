<template>
    <div>
        <ToolBar
            :menu="'Tiếp nhận dữ liệu QLNT'"
            :content="'Báo cáo tình hình nộp dữ liệu'"
            :subContent="'Báo cáo tình hình nộp dữ liệu cấp Sở '"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="1">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="1" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>

                    <th>Đơn vị quản lý</th>
                    <th class="text-right">Tổng số trường học</th>
                    <th class="text-right">Tổng số cán bộ</th>
                    <th class="text-right">Tổng số lớp đơn</th>
                    <th class="text-right">Tổng số lớp ghép</th>
                    <th class="text-right">Tổng số học sinh</th>
                    <th class="text-right">Tổng số KQ HK I</th>
                    <th class="text-right">Tổng số KQ HK II</th>
                    <th class="text-right">Tổng số KQ cả năm</th>
                    <th class="text-right">Tổng số HS tốt nghiệp</th>
                </tr>
            </template>
            <template v-slot:body>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>

                    <td class="text-left">
                        {{ item.tenDonVi }}
                    </td>
                    <td class="text-right">
                        {{ item.totalTruongHoc }}
                    </td>
                    <td class="text-right">
                        {{ item.totalCanbo }}
                    </td>
                    <td class="text-right">
                        {{ item.totalLopDon }}
                    </td>

                    <td class="text-right">
                        {{ item.totalLopGhep }}
                    </td>

                    <td class="text-right">
                        {{ item.totalHocsinh }}
                    </td>
                    <td class="text-right">
                        {{ item.totalKQHocKi1 }}
                    </td>

                    <td class="text-right">
                        {{ item.totalKQHocki2 }}
                    </td>
                    <td class="text-right">
                        {{ item.totalKQCanam }}
                    </td>
                    <td class="text-right">
                        {{ item.totalHsTotNghep }}
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'

export default {
    name: 'DataTransferSchedule',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            tableData: []
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        async handleSearch() {
            try {
                const request_data = {
                    start: this.start,
                    limit: this.limit,
                    capHocList: this.selectedGradeLevelFromStore,
                    maDonViList: this.unit_education,
                    namHoc: this.selectedYearFromStore,
                    maSo: this.auth_user.ma_so
                }

                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.bao_cao_tinh_hinh_nop_du_lieu
                        .cap_so,
                    request_data,
                    this.request_headers,
                    null
                )

                this.total_rows = response.totalRows

                this.tableData = response.tableData
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
