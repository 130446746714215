l
<template>
    <div>
        <ToolBar :tab="true">
            <template v-slot:tab>
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                >
                    <el-menu-item
                        v-for="(item, index) in menuItems"
                        :key="index"
                        :index="index.toString()"
                        @click="handleMenuItemClick(index)"
                    >
                        {{ item.label }}
                    </el-menu-item>
                </el-menu>
            </template>
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
                <CustomButton label="Xuất Excel" :icon="'el-icon-download'" />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row justify-content-center">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="get_list_school"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Thống kê cấp học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_statistical"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="statistical"
                        @change="onChangeStatistical"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Chi tiết báo cáo:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_detailed_report"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="detailed_report"
                    />
                </div>
            </div>
        </FormSearch>

        <School
            :statistical="statistical"
            :start="start"
            :limit="limit"
            :tableData="tableData"
            :column_form="column_form"
            :detailed_report="detailed_report"
            v-if="activeIndex == '0'"
        />

        <ClassRoom
            :start="start"
            :limit="limit"
            :tableData="tableData"
            :statistical="statistical"
            :detailed_report="detailed_report"
            :column_form="column_form"
            v-if="activeIndex == '1'"
        />

        <Teachers
            :start="start"
            :limit="limit"
            :tableData="tableData"
            :statistical="statistical"
            :detailed_report="detailed_report"
            :column_form="column_form"
            v-if="activeIndex == '2'"
        />
        <Student
            :detailed_report="detailed_report"
            :start="start"
            :limit="limit"
            :tableData="tableData"
            :column_form="column_form"
            v-if="activeIndex == '3'"
            :statistical="statistical"
        />

        <AcademicPerformanceResults
            :detailed_report="detailed_report"
            :start="start"
            :limit="limit"
            :tableData="tableData"
            :column_form="column_form"
            v-if="activeIndex == '4'"
            :statistical="statistical"
        />

        <div class="pagi" v-show="total_rows > 0 && tableData.length > 0">
            <CustomPagination
                v-show="total_rows > 0 && tableData.length > 0"
                :tongbanghi="total_rows"
                :pageSize="pageSize"
                :pageStart="pageStart"
                @pageChange="pageChange($event)"
                @pageSizeChange="pageSizeChange($event)"
            >
            </CustomPagination>
        </div>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import { showNotication } from '@/utils/notification'
import ESelect from '@/components/ESelect.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ToolBar from '@/components/ToolBar.vue'
import School from './school/index.vue'
import ClassRoom from './classroom/index.vue'
import Teachers from './teachers/index.vue'
import Student from './student/index.vue'
import AcademicPerformanceResults from './academic-performance-results/index.vue'
import { convertStringToNumber } from '@/utils'
export default {
    name: 'RePort',

    components: {
        ToolBar,
        CustomButton,
        FormSearch,
        ESelect,
        CustomPagination,

        School,

        ClassRoom,

        Teachers,

        Student,

        AcademicPerformanceResults
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school: [],
            school: [],

            list_detailed_report: [],
            detailed_report: '',

            list_statistical: [],
            statistical: 4,

            list_collectionName: [],

            tableData: [],

            activeIndex: '0',
            menuItems: [
                { label: 'Trường học' },
                { label: 'Lớp học' },
                { label: 'Cán bộ' },
                { label: 'Học sinh' },
                { label: 'KQHT' }
            ],

            column_form: 2
        }
    },
    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        async get_list_school() {
            this.school = []

            const request_data = {
                capHoc: this.grade_level ? [this.grade_level] : [],
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        handleMenuItemClick(index) {
            this.activeIndex = index.toString()
            this.detailed_report = ''
            this.statistical = 4
            this.tableData = []
            this.total_rows = 0
            this.get_list_collectionName()
            // this.handleSearch()
        },
        async get_list_collectionName() {
            try {
                const chiTietBaoCao = convertStringToNumber(this.activeIndex) + 1

                const request_params = {
                    tenBaoCao: 'baoCaoChoSoNew',
                    chiTietBaoCao: chiTietBaoCao
                }

                const response = await sendRequest(
                    Api.bao_cao.bao_cao_don_vi.collectionName,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_statistical = convertListData(
                    response,
                    'category',
                    'capHoc'
                )
                this.list_collectionName = response
                console.log(response)

                this.onChangeStatistical()
            } catch (error) {
                console.log(error)
            }
        },

        onChangeStatistical() {
            this.detailed_report = ''
            this.tableData = []
            this.total_rows = 0

            const selectedStatistical = this.statistical
            console.log(selectedStatistical)
            const selectedChildren =
                this.list_collectionName.find(
                    (item) => item.capHoc == selectedStatistical
                )?.children || []

            selectedChildren
                ? (this.list_detailed_report = selectedChildren.map((item) => ({
                      ...item,
                      label: `${item.chiTietName} - [${item.maBaoCao}]`,
                      value: item.maBaoCao
                  })))
                : (this.list_detailed_report = [])

            console.log(this.list_detailed_report)
        },
        async handleSearch() {
            try {
                const request_data = {
                    capHoc: this.statistical,
                    namHoc: this.selectedYearFromStore,
                    maBaoCao: this.detailed_report,
                    maTruongs: this.school,
                    maDonVis: this.unit_education,
                    start: this.start,
                    limit: this.limit
                }

                const response = await sendRequest(
                    Api.bao_cao.bao_cao_don_vi.danh_sach,
                    request_data,
                    this.request_headers,
                    null
                )
                const type = response.rc === 0 ? 'success' : 'error'
                showNotication(this, type, response.rd)
                this.tableData =
                    response.reportData === null ? [] : response.reportData
                this.total_rows = response.total
                console.log(response)
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.get_list_collectionName()
        // this.handleSearch()
    },
    watch: {
        grade_level: function (newVal) {
            if (newVal) {
                this.get_list_school()
            }
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.pagi {
    background: #f5f5f5;
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
.el-menu-item {
    height: 45px;
    line-height: 45px;
    background: #f5f5f5;
    color: black;
    border-top: 1px solid #ddd;
    font-size: 14px;
}
.el-menu.el-menu--horizontal {
    margin-left: -20px;
}
</style>
