<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="updateVisible"
        :modal-title="'Chỉnh sửa thông tin vai trò'"
        :close-function="handleCloseModalUpdate"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-4">
                    <label>Tên vai trò: <span class="error-label">*</span></label>
                    <div>
                        <CustomInput
                            v-model="name"
                            @input="onChangeHandler('name', $event)"
                            :textError="err_name"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>Vai trò gốc <span class="error-label">*</span> </label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_role_modal"
                            :placeholder="'Chọn '"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="role"
                            @input="onChangeHandler('role', $event)"
                            :class="{
                                'error-select': err_role.length > 0
                            }"
                        />
                        <span
                            v-if="err_role.length > 0"
                            class="text-error-select"
                        >
                            {{ err_role }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>Mô tả: <span class="error-label">*</span></label>
                    <div>
                        <CustomInput
                            v-model="desc"
                            @input="onChangeHandler('desc', $event)"
                            :textError="err_desc"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-xs-12 col-sm-6">
                    <label class="typo__label"
                        >Phân quyền chức năng<span class="error-label"
                            >*</span
                        ></label
                    >
                    <div
                        v-if="decentralization_func"
                        class="bd Dts-scoll"
                        :style="{ maxHeight: maxHeight + 'px' }"
                    >
                        <v-jstree
                            :data="decentralization_func"
                            show-checkbox="show-checkbox"
                            multiple="multiple"
                            allow-batch="allow-batch"
                            whole-row="whole-row"
                            @item-click="handle_decentralization_func"
                        >
                        </v-jstree>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6">
                    <label class="typo__label"
                        >Phân quyền báo cáo
                        <span class="error-label">*</span></label
                    >
                    <div
                        class="bd Dts-scoll"
                        v-if="decentralization_report"
                        :style="{ height: maxHeight + 'px' }"
                    >
                        <v-jstree
                            :data="decentralization_report"
                            show-checkbox="show-checkbox"
                            multiple="multiple"
                            allow-batch="allow-batch"
                            whole-row="whole-row"
                            @item-click="handle_decentralization_report"
                        ></v-jstree>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleUpdate"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
import { list_role_modal } from '@/mock_data/quan-ly-vai-tro'
import VJstree from 'vue-jstree'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalEdit',

    components: { CustomButton, CustomModal, CustomInput, ESelect, VJstree },
    data() {
        return {
            maxHeight: window.innerHeight,

            name: '',
            list_role_modal: list_role_modal,
            role: '',
            desc: '',

            err_name: '',
            err_role: '',
            err_desc: '',

            decentralization_func: [], // data phân quyền chức năng
            decentralization_report: [], // data phân quyền báo cáo

            errModal: false
        }
    },
    props: {
        updateVisible: {
            type: Boolean,
            required: true
        },
        closeModalUpdate: Function,
        handleSearch: Function,
        dataUpdate: {
            type: Object,
            required: true
        }
    },
    watch: {
        async dataUpdate(newValue) {
            this.name = newValue.roleName
            this.role = newValue.parentRole
            this.desc = newValue.description

            await this.get_list_decentralization(newValue.roleId)
        }
    },

    methods: {
        async get_list_decentralization(id) {
            const request_data = {
                roleId: id
            }
            const response = await sendRequest(
                Api.he_thong.quan_ly_vai_tro.phan_quyen,
                request_data,
                this.request_headers
            )
            this.decentralization_func = response.item.menu
            this.decentralization_report = response.item.baoCaoRoleResponses
        },
        handle_decentralization_func(node) {
            let allCheck = []
            if (node && node.parentItem && node.parentItem.children) {
                allCheck = node.parentItem.children.filter((obj) => obj.selected)
            }
            if (node && node.model && node.model.selected) {
                node.parentItem.selected = true
            } else {
                if (!allCheck.length) {
                    node.parentItem.selected = false
                }
            }
        },
        handle_decentralization_report(node) {
            let allCheck = []
            if (node && node.parentItem && node.parentItem.children) {
                allCheck = node.parentItem.children.filter((obj) => obj.selected)
            }
            if (node && node.model && node.model.selected) {
                node.parentItem.selected = true
            } else {
                if (!allCheck.length) {
                    node.parentItem.selected = false
                }
            }
        },
        handleClearData() {
            this.errModal = false

            this.err_name = ''

            this.err_role = ''

            this.err_desc = ''
        },
        handleCloseModalUpdate() {
            this.closeModalUpdate()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                name: 'err_name',
                role: 'err_role',
                desc: 'err_desc'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async handleUpdate() {
            const fieldsToValidate = [
                {
                    value: this.name,
                    errorField: 'err_name'
                },
                {
                    value: this.role,
                    errorField: 'err_role'
                },
                {
                    value: this.desc,
                    errorField: 'err_desc'
                }
            ]

            if (validateFields(this, fieldsToValidate)) {
                const request_data = {
                    roleDto: {
                        roleName: this.name,
                        parentRole: this.role,
                        description: this.desc,
                        maSo: this.auth_user.ma_so
                    },
                    listReport: this.decentralization_report,
                    listMenu: this.decentralization_func,
                    roleUpdate: this.dataUpdate.roleId
                }
                try {
                    const response = await sendRequest(
                        Api.he_thong.quan_ly_vai_tro.chinh_sua,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)

                        this.closeModalUpdate()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                } catch (error) {
                    if (error.response?.data.rd) {
                        showNotication(this, 'error', error.response?.data.rd)
                    }
                }
            }
        }
    },
    mounted() {
        this.maxHeight -= 350
    },
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.bd {
    border: 1px solid slategrey;

    overflow-y: scroll;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
