<template>
    <div>
        <ToolBar
            :menu="'Tra cứu cán bộ giáo viên'"
            :content="getContentToolBar()"
            :hideToggle="true"
            :hideZoom="true"
        >
            <template v-slot:form>
                <CustomButton
                    :plain="true"
                    label="Quay lại"
                    @click="navigateLockUpTeacher"
                />
                <CustomButton
                    v-if="!viewVisible"
                    label="Lưu"
                    @click="handleUpdate"
                />
            </template>
        </ToolBar>
        <FormLockUp>
            <template v-slot:lock-up-container>
                <CustomTab
                    :initialTab="active_tab_info"
                    :tabs="list_tab_info"
                    :hideHr="false"
                />
                <div class="form-update mt-3">
                    <div class="row">
                        <div class="col-md-2">
                            <Avatar :avatar="avatar" :code="teacher_code" />
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Họ tên
                                        </label>
                                        <div class="n-index error">[1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="name"
                                        :disabled="viewVisible"
                                        @input="onChangeHandler('name', $event)"
                                        :textError="err_name"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày sinh
                                        </label>
                                        <div class="n-index error">[2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="birthday"
                                        @input="
                                            onChangeHandler('birthday', $event)
                                        "
                                        :disabled="viewVisible"
                                        :show-error="err_birthday.length > 0"
                                    />
                                    <span
                                        v-if="err_birthday.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_birthday }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Giới tính
                                        </label>
                                        <div class="n-index">[3]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_gender"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="gender"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Trạng thái CB
                                        </label>
                                        <div class="n-index error">[4]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_status"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="status"
                                        @input="onChangeHandler('status', $event)"
                                        :class="{
                                            'error-select': err_status.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_status.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_status }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Là tuyển mới
                                        </label>
                                        <div class="n-index">[4.2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        v-model="is_new_recruit"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Số CMND/CCCD
                                        </label>
                                        <div class="n-index">[5]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="identity_number"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày cấp
                                        </label>
                                        <div class="n-index">[6]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="date_range"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi cấp
                                        </label>
                                        <div class="n-index">[7]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="issued_by"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            CCCD có gắn chip
                                        </label>
                                        <div class="n-index">[8]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        v-model="
                                            is_citizen_identification_card_chip
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Số định danh cá nhân
                                        </label>
                                        <div class="n-index">[9]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="personal_identification_number"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Email
                                        </label>
                                        <div class="n-index">[10]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="email"
                                        :disabled="viewVisible"
                                        :trimAll="true"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Số điện thoại
                                        </label>
                                        <div class="n-index">[11]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="phone"
                                        :disabled="viewVisible"
                                        :trimAll="true"
                                        :type="'Number'"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Dân tộc
                                        </label>
                                        <div class="n-index">[12]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_nation"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="nation"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Tôn giáo
                                        </label>
                                        <div class="n-index">[13]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_religion"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="religion"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Mã định danh bộ GD&ĐT
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="moet_code"
                                        :disabled="viewVisible"
                                        :type="'Number'"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Số hộ chiếu
                                        </label>
                                        <div class="n-index">[14]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="passport"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày cấp (Hộ chiếu)
                                        </label>
                                        <div class="n-index">[15]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="passport_date"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi cấp (Hộ chiếu)
                                        </label>
                                        <div class="n-index">[16]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="passport_place_of_issue"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Là Đoàn viên
                                        </label>
                                        <div class="n-index">[17]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_union_member"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi kết nạp đoàn
                                        </label>
                                        <div class="n-index">[17.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="unit_enlistment_location"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Là Đảng viên
                                        </label>
                                        <div class="n-index">[18]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_party_member"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nơi kết nạp đảng
                                        </label>
                                        <div class="n-index">[18.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="party_enlistment_location"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày vào đảng dự bị
                                        </label>
                                        <div class="n-index">[19]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="date_join_the_reserve_party"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày vào đảng chính thức
                                        </label>
                                        <div class="n-index">[20]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <DatePicker
                                        v-model="official_date_join_party"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Số sổ BHXH
                                        </label>
                                        <div class="n-index">[21]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="social_insurance_book_number"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label text-blue-2">
                                            Thông tin hộ khẩu, quê quán
                                        </label>
                                        <div class="n-index"></div>
                                    </div>
                                </div>
                                <div class="col-md-8"></div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Hộ khẩu thường trú
                                        </label>
                                        <div class="n-index">[15]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_province"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="province_permanent_address"
                                        @change="onChangeProvincePermanentAddress"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4"></div>
                                <div class="col-md-8">
                                    <div style="display: flex">
                                        <div class="col-sm-6">
                                            <ESelect
                                                style="width: 100%"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_district_permanent_address
                                                "
                                                :multiple="false"
                                                :fields="['label', 'value']"
                                                v-model="
                                                    district_permanent_address
                                                "
                                                @change="
                                                    onChangeDistrictPermanentAddress
                                                "
                                            />
                                        </div>
                                        <div class="col-sm-6">
                                            <ESelect
                                                style="width: 100%"
                                                :disabled="viewVisible"
                                                :data="
                                                    list_wards_permanent_address
                                                "
                                                :multiple="false"
                                                :fields="['label', 'value']"
                                                v-model="wards_permanent_address"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4"></div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="village_permanent_address"
                                        :disabled="viewVisible"
                                        :placeholder="'Nhập Tổ/Thôn/Xóm thường trú'"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Quê quán
                                        </label>
                                        <div class="n-index">[16]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_province"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="province_hometown"
                                        @change="onChangeProvinceHometown"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4"></div>
                                <div class="col-md-8">
                                    <div style="display: flex">
                                        <div class="col-sm-6">
                                            <ESelect
                                                style="width: 100%"
                                                :disabled="viewVisible"
                                                :data="list_district_hometown"
                                                :multiple="false"
                                                :fields="['label', 'value']"
                                                v-model="district_hometown"
                                                @change="onChangeDistrictHometown"
                                            />
                                        </div>
                                        <div class="col-sm-6">
                                            <ESelect
                                                style="width: 100%"
                                                :disabled="viewVisible"
                                                :data="list_wards_hometown"
                                                :multiple="false"
                                                :fields="['label', 'value']"
                                                v-model="wards_hometown"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4"></div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="village_hometown"
                                        :disabled="viewVisible"
                                        :placeholder="'Nhập Tổ/Thôn/Xóm quê quán'"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-3"></div>
                <CustomTab
                    :initialTab="active_tab_private"
                    :tabs="list_tab_private"
                    :hideHr="false"
                    @tabChanged="handleTabPrivateChanged"
                />
                <div class="form-update mt-3" v-if="active_tab_private == 1">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Vị trí việc làm
                                        </label>
                                        <div class="n-index error">[24]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_job_position"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="job_position"
                                        @input="
                                            onChangeHandler(
                                                'job_position',
                                                $event
                                            )
                                        "
                                        :class="{
                                            'error-select':
                                                err_job_position.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_job_position.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_job_position }}
                                    </span>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="
                                    info_teacher.capHoc === 1 ||
                                    info_teacher.capHoc === 2
                                "
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Môn dạy
                                        </label>
                                        <div class="n-index">[14.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_subject"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="subject"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="
                                    info_teacher.capHoc === 1 ||
                                    info_teacher.capHoc === 2
                                "
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Môn dạy kiêm nghiệm
                                        </label>
                                        <div class="n-index">[14.2]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_subject"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="experiential_subject"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nhóm chức vụ
                                        </label>
                                        <div class="n-index">[25]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_position"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="position"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Hình thức hợp đồng
                                        </label>
                                        <div class="n-index">[26]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_contract_form"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="contract_form"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày tuyển dụng
                                        </label>
                                        <div class="n-index">[27]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <DatePicker
                                        v-model="recruitment_day"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Cơ quan tuyển dụng
                                        </label>
                                        <div class="n-index">[28]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="employment_agencies"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nghề nghiệp khi được tuyển dụng
                                        </label>
                                        <div class="n-index">[29]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="occupation_recruited"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nhiệm vụ kiêm nhiệm
                                        </label>
                                        <div class="n-index">[30]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_part_time_duties"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="part_time_duties"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="
                                    info_teacher.capHoc === 3 ||
                                    info_teacher.capHoc === 1 ||
                                    info_teacher.capHoc === 2
                                "
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            S.tiết thực dạy trên tuần
                                        </label>
                                        <div class="n-index">[31]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="
                                            number_of_actual_lessons_taught_per_week
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="
                                    info_teacher.capHoc === 3 ||
                                    info_teacher.capHoc === 1 ||
                                    info_teacher.capHoc === 2
                                "
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            S.tiết thực k.nhiệm trên tuần
                                        </label>
                                        <div class="n-index">[32]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="
                                            number_of_concurrent_meals_per_week
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Đã tập huấn dạy KN sống (HIV, SKSS...)
                                        </label>
                                        <div class="n-index">[33]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        v-model="
                                            is_trained_in_teaching_life_skills
                                        "
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Dạy HSKT học hòa nhập
                                        </label>
                                        <div class="n-index">[34]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="
                                            is_teaching_students_with_disabilities_to_learn_inclusively
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Số buổi dạy/ngày
                                        </label>
                                        <div class="n-index">[35]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_number_of_teaching_sessions_day
                                        "
                                        :multiple="true"
                                        :fields="['label', 'value']"
                                        v-model="number_of_teaching_sessions_day"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="
                                    info_teacher.capHoc === 3 ||
                                    info_teacher.capHoc === 1 ||
                                    info_teacher.capHoc === 2
                                "
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Th.gia CT b.dưỡng T.xuyên
                                        </label>
                                        <div class="n-index">[36]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_regular_treatment_program"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="
                                    info_teacher.capHoc === 1 ||
                                    info_teacher.capHoc === 2
                                "
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Tổng phụ trách đội
                                        </label>
                                        <div class="n-index">[36.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_team_supervisor"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngạch/Hạng
                                        </label>
                                        <div class="n-index">[37]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_rank"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="rank"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Mã số
                                        </label>
                                        <div class="n-index">[38]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="code"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Có CC bồi dưỡng TCCD
                                        </label>
                                        <div class="n-index">[39]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-checkbox
                                        v-model="is_TCCD"
                                        :disabled="viewVisible"
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-update mt-3" v-if="active_tab_private == 2">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Mức phụ cấp thu hút nghề (%)
                                        </label>
                                        <div class="n-index">[40]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-input-number
                                        v-model="occupational_attraction_bonus"
                                        controls-position="right"
                                        :precision="2"
                                        :step="0.01"
                                        :max="100"
                                        :min="0"
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                    >
                                    </el-input-number>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Mức phụ cấp thâm niên (%)
                                        </label>
                                        <div class="n-index">[41]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-input-number
                                        v-model="seniority_allowance"
                                        controls-position="right"
                                        :precision="2"
                                        :step="0.01"
                                        :max="100"
                                        :min="0"
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                    >
                                    </el-input-number>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Mức phụ cấp ưu đãi nghề (%)
                                        </label>
                                        <div class="n-index">[42]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-input-number
                                        v-model="
                                            occupational_preferential_benefits
                                        "
                                        controls-position="right"
                                        :precision="2"
                                        :step="0.01"
                                        :max="100"
                                        :min="0"
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                    >
                                    </el-input-number>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Mức phụ cấp chức vụ lãnh đạo (%)
                                        </label>
                                        <div class="n-index">[43]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-input-number
                                        v-model="leadership_role_allowance"
                                        controls-position="right"
                                        :precision="2"
                                        :step="0.01"
                                        :max="100"
                                        :min="0"
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                    >
                                    </el-input-number>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            D.biến q.trình lương
                                        </label>
                                        <div class="n-index">[44]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomButton
                                        label="Chi tiết"
                                        :btnSaveModal="true"
                                        @click="openModalSalaryProcess"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Bậc lương
                                        </label>
                                        <div class="n-index">[45]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_pay_scale"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="pay_scale"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Phần trăm vượt khung (%)
                                        </label>
                                        <div class="n-index">[46]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-input-number
                                        v-model="salary_premium"
                                        controls-position="right"
                                        :precision="2"
                                        :step="0.01"
                                        :max="100"
                                        :min="0"
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                    >
                                    </el-input-number>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Hệ số lương
                                        </label>
                                        <div class="n-index">[47]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <el-input-number
                                        v-model="salary_factor"
                                        controls-position="right"
                                        :precision="2"
                                        :step="0.01"
                                        :max="100"
                                        :min="0"
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                    >
                                    </el-input-number>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngày hưởng
                                        </label>
                                        <div class="n-index">[48]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <DatePicker
                                        v-model="effective_date"
                                        :disabled="viewVisible"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-update mt-3" v-if="active_tab_private == 3">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            KQ B.dưỡng t.xuyên
                                        </label>
                                        <div class="n-index">[49]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_continuous_training_outcomes"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="continuous_training_outcomes"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            T.độ c.môn n.vụ
                                        </label>
                                        <div class="n-index error">[50]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_professional_competence_for_tasks
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="
                                            professional_competence_for_tasks
                                        "
                                        @input="
                                            onChangeHandler(
                                                'professional_competence_for_tasks',
                                                $event
                                            )
                                        "
                                        :class="{
                                            'error-select':
                                                err_professional_competence_for_tasks.length >
                                                0
                                        }"
                                    />
                                    <span
                                        v-if="
                                            err_professional_competence_for_tasks.length >
                                            0
                                        "
                                        class="text-error-select"
                                    >
                                        {{
                                            err_professional_competence_for_tasks
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            T.độ LLCT
                                        </label>
                                        <div class="n-index">[51]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_political_ideology_proficiency
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="political_ideology_proficiency"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            T.độ quản lý GD
                                        </label>
                                        <div class="n-index">[52]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_educational_administration_proficiency
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="
                                            educational_administration_proficiency
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Th.gia BD nghiệp vụ QLGD
                                        </label>
                                        <div class="n-index">[53]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_participating_in_educational_leadership_training
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="
                                            participating_in_educational_leadership_training
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Th.gia BD CBQL/GV cốt cán
                                        </label>
                                        <div class="n-index">[54]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_participating_training_administrative_teaching_core_personnel
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="
                                            participating_training_administrative_teaching_core_personnel
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Th.gia BD thay sách
                                        </label>
                                        <div class="n-index">[55]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_participating_in_book_replacement_training_program
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="
                                            participating_in_book_replacement_training_program
                                        "
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Ngoại ngữ chính
                                        </label>
                                        <div class="n-index">[56]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_main_language"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="main_language"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            T.độ Đ.tạo N.Ngữ
                                        </label>
                                        <div class="n-index">[57]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_foreign_language_education_level
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="foreign_language_education_level"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Nhóm C.Chỉ N.Ngữ
                                        </label>
                                        <div class="n-index">[58]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_language_certificate_group"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="language_certificate_group"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Loại C.Chỉ N.Ngữ
                                        </label>
                                        <div class="n-index">[59]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_type_of_language_certificate"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="type_of_language_certificate"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Điểm N.Ngữ
                                        </label>
                                        <div class="n-index">[60]</div>
                                    </div>
                                </div>
                                <div class="col-md-6 d-flex">
                                    <el-input-number
                                        v-model="foreign_language_points"
                                        controls-position="right"
                                        :precision="2"
                                        :max="100"
                                        :min="0"
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                    >
                                    </el-input-number>
                                    <CustomButton
                                        label="Chi tiết"
                                        :btnSaveModal="true"
                                        @click="openModalLanguageLearningProcess"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Khung N.Lực N.Ngữ
                                        </label>
                                        <div class="n-index">[61]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_language_proficiency_framework
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="language_proficiency_framework"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            T.độ tin học
                                        </label>
                                        <div class="n-index">[62]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_computer_proficiency"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="computer_proficiency"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Chuyên ngành chính
                                        </label>
                                        <div class="n-index">[63]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_specialized"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="major_field"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Trình độ chính
                                        </label>
                                        <div class="n-index">[64]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_level"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="main_proficiency"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Cơ sở đào tạo
                                        </label>
                                        <div class="n-index">[65]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="training_facility"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Chuyên ngành khác
                                        </label>
                                        <div class="n-index">[66]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_specialized"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="other_major"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Trình độ khác
                                        </label>
                                        <div class="n-index">[67]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_level"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="other_proficiency"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            G.viên có c.chỉ tiếng d.tộc t.số
                                        </label>
                                        <div class="n-index">[68]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_teacher_with_minority_language_certificate
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="
                                            teacher_with_minority_language_certificate
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Quá trình đào tạo b.dưỡng
                                        </label>
                                        <div class="n-index">[69]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomButton
                                        label="Chi tiết"
                                        :btnSaveModal="true"
                                        @click="openModalTraining"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-update mt-3" v-if="active_tab_private == 4">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Đánh giá viên chức
                                        </label>
                                        <div class="n-index">[70]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_employee_evaluation"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="employee_evaluation"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Đ.giá chuẩn NN CBQL/GV
                                        </label>
                                        <div class="n-index">[71]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomButton
                                        label="Chi tiết"
                                        :btnSaveModal="true"
                                        @click="openModalCareerAssessment"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Giáo viên dạy giỏi
                                        </label>
                                        <div class="n-index">[72]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_excellent_teacher"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="excellent_teacher"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Giáo viên chủ nhiệm giỏi
                                        </label>
                                        <div class="n-index">[73]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_excellent_homeroom_teacher"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="excellent_homeroom_teacher"
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="
                                    info_teacher.capHoc === 1 ||
                                    info_teacher.capHoc === 2
                                "
                            >
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Tổng phụ trách đội giỏi
                                        </label>
                                        <div class="n-index">[73.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_team_excellence_leader"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="team_excellence_leader"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Khen thưởng
                                        </label>
                                        <div class="n-index">[74]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomButton
                                        label="Chi tiết"
                                        :btnSaveModal="true"
                                        @click="openModalBonus"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Kỷ luật
                                        </label>
                                        <div class="n-index">[75]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomButton
                                        label="Chi tiết"
                                        :btnSaveModal="true"
                                        @click="openModalDiscipline"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            Danh hiệu phong tặng
                                        </label>
                                        <div class="n-index">[76]</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <CustomInput
                                        v-model="honorary_title_award"
                                        :disabled="viewVisible"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </FormLockUp>
        <ModalSalaryProcess
            :dataSalaryProcess="dataSalaryProcess"
            :salaryProcessVisible="salaryProcessVisible"
            :closeModalSalaryProcess="closeModalSalaryProcess"
        />
        <ModalLanguageLearningProcess
            :dataLanguageLearningProcess="dataLanguageLearningProcess"
            :languageLearningProcessVisible="languageLearningProcessVisible"
            :closeModalLanguageLearningProcess="closeModalLanguageLearningProcess"
        />
        <ModalDiscipline
            :disciplineVisible="disciplineVisible"
            :closeModalDiscipline="closeModalDiscipline"
            :dataDiscipline="dataDiscipline"
        />
        <ModalBonus
            :bonusVisible="bonusVisible"
            :closeModalBonus="closeModalBonus"
            :dataBonus="dataBonus"
        />
        <ModalCareerAssessment
            :dataCareerAssessment="dataCareerAssessment"
            :closeModalCareerAssessment="closeModalCareerAssessment"
            :careerAssessmentVisible="careerAssessmentVisible"
        />
        <ModalTraining
            :dataTraining="dataTraining"
            :closeModalTraining="closeModalTraining"
            :trainingVisible="trainingVisible"
        />
    </div>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomTab from '@/components/CustomTab.vue'
import FormLockUp from '@/components/FormLockUp.vue'
import DatePicker from '@/components/DatePicker.vue'
import Avatar from '@/components/Avatar.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertListData,
    formatDateToYYYYMMDD,
    formatDateToDDMMYYYY,
    convertToBoolean,
    convertToBinary,
    parseJsonArray
} from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import {
    list_gender,
    list_pay_scale,
    list_level
} from '@/mock_data/tra-cuu-can-bo-giao-vien'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'
import ToolBar from '@/components/ToolBar.vue'
import ModalSalaryProcess from './modal_salary_process.vue'
import ModalLanguageLearningProcess from './modal_language_learning_process.vue'
import ModalDiscipline from './modal_discipline.vue'
import ModalBonus from './modal_bonus.vue'
import ModalCareerAssessment from './modal_career_assessment.vue'
import ModalTraining from './modal_training.vue'
import { path } from '@/router/path'
import { showNotication } from '@/utils/notification'
export default {
    name: 'UpdateLookUpSchool',
    components: {
        CustomButton,
        CustomInput,
        ESelect,
        DatePicker,
        CustomTab,
        ToolBar,
        FormLockUp,
        ModalSalaryProcess,
        ModalLanguageLearningProcess,
        ModalDiscipline,
        ModalBonus,
        ModalCareerAssessment,
        ModalTraining,
        Avatar
    },
    data() {
        return {
            avatar: null,
            teacher_code: '',
            name: '',
            birthday: '',
            list_gender: list_gender,
            gender: '',
            status: '', // trạng thái cán bộ làm việc
            is_new_recruit: false, // la tuyen moi
            identity_number: '', // so cmnd
            date_range: '', // ngay cap,
            issued_by: '', // noi cap
            is_citizen_identification_card_chip: false, // cccd chip
            personal_identification_number: '', // so dinh danh ca nhan
            email: '',
            phone: '',
            nation: '', // dân tộc
            religion: '', // tôn giáo
            moet_code: '', // mã định danh bộ gd&đt

            passport: '', // số hộ chiếu
            passport_date: '',
            passport_place_of_issue: '',
            is_union_member: false, // là đoàn viên
            unit_enlistment_location: '', // nơi kết nạp đoàn
            is_party_member: false, // là Đảng viên
            party_enlistment_location: '', // nơi kết nạp đảng
            date_join_the_reserve_party: '', // ngày vào đảng dự bị
            official_date_join_party: '', // ngày vào đảng chính thức
            social_insurance_book_number: '', // Số sổ BHXH

            // Hộ khẩu thường trú
            province_permanent_address: '', // tỉnh thường trú
            list_district_permanent_address: [],
            district_permanent_address: '', // huyện thường trú
            list_wards_permanent_address: [],
            wards_permanent_address: '', // phường thường trú
            village_permanent_address: '', // xã thường trú
            // Quê quán
            province_hometown: '', // tỉnh
            list_district_hometown: [],
            district_hometown: '', // huyện
            list_wards_hometown: [],
            wards_hometown: '', // phường
            village_hometown: '', //xã

            job_position: '', // vị trí việc làm
            list_grade_level: [], // cấp dạy
            grade_level: '',
            subject: '', // môn dạy
            experiential_subject: '', // môn dạy kiêm nghiệm
            position: '', // nhóm chức vụ
            contract_form: '', //Hình thức hợp đồng
            recruitment_day: '', // Ngày tuyển dụng
            employment_agencies: '', // Cơ quan tuyển dụng
            occupation_recruited: '', // Nghề nghiệp khi được tuyển dụng
            part_time_duties: '', // Nhiệm vụ kiêm nhiệm
            number_of_actual_lessons_taught_per_week: '', // Số tiết thực dạy trên tuần
            number_of_concurrent_meals_per_week: '', // Số tiết thực kiêm nhiệm trên tuần
            is_trained_in_teaching_life_skills: false, // Đã tập huấn dạy Kỹ Năng sống
            is_teaching_students_with_disabilities_to_learn_inclusively: false, // Dạy HSKT học hòa nhập
            list_number_of_teaching_sessions_day: [], // Số buổi dạy/ngày
            number_of_teaching_sessions_day: '',
            is_regular_treatment_program: false, // th.gia CT b.dưỡng T.xuyên
            is_team_supervisor: false, // tổng phụ trách đội
            rank: '', // Ngạch/Hạng
            code: '', // Mã số
            is_TCCD: false, // Có CC bồi dưỡng TCCD,

            occupational_attraction_bonus: '', // Mức phụ cấp thu hút nghề
            seniority_allowance: '', // Mức phụ cấp thâm niên
            occupational_preferential_benefits: '', // Mức phụ cấp ưu đãi nghề
            leadership_role_allowance: '', // Mức phụ cấp chức vụ lãnh đạo
            list_pay_scale: list_pay_scale, // Bậc lương
            pay_scale: '',
            salary_premium: '', // Phần trăm vượt khung
            salary_factor: '', // Hệ số lương
            effective_date: '', // Ngày hưởng

            continuous_training_outcomes: '', // Kết quả bồi dưỡng thường xuyên
            professional_competence_for_tasks: '', // Trình độ chuyên môn nhiệm vụ
            political_ideology_proficiency: '', // Trình độ lý luận chính trị
            educational_administration_proficiency: '', // trình độ quản lý giáo dục
            participating_in_educational_leadership_training: '', // Tham gia bồi dưỡng nghiệp vụ Quản lý Giáo dục
            participating_training_administrative_teaching_core_personnel: '', // Th.gia BD CBQL/GV cốt cán
            participating_in_book_replacement_training_program: '', // Tham gia Bồi Dưỡng thay sách
            main_language: '', // Ngoại ngữ chính
            foreign_language_education_level: '', // Trình độ Đào tạo Ngoại Ngữ
            language_certificate_group: '', // Nhóm Chứng Chỉ Ngoại Ngữ
            type_of_language_certificate: '', // loại  Chứng Chỉ Ngoại Ngữ
            foreign_language_points: '', // điểm ngoại ngữ
            language_proficiency_framework: '', // Khung Năng Lực Ngôn Ngữ
            computer_proficiency: '', // T.độ Tin Học

            major_field: '', // Chuyên ngành chính
            list_level: list_level, // Trình độ chính
            main_proficiency: '',
            training_facility: '', //  Cơ sở đào tạo
            other_major: '', // Chuyên ngành khác
            other_proficiency: '', // Trình độ khác
            teacher_with_minority_language_certificate: '', // Giáo viên có chứng chỉ tiếng dân tộc thiểu số

            employee_evaluation: '', // Đánh giá viên chức
            excellent_teacher: '', // Giáo viên dạy giỏi
            excellent_homeroom_teacher: '', // Giáo viên chủ nhiệm giỏi
            team_excellence_leader: '', // Tổng phụ trách đội giỏi
            honorary_title_award: '', // Danh hiệu phong tặng

            err_name: '',
            err_birthday: '',
            err_status: '',
            err_job_position: '',
            err_professional_competence_for_tasks: '',

            errModal: false,
            viewVisible: false,
            info_teacher: {},

            salaryProcessVisible: false, // quá trình lương
            dataSalaryProcess: [],
            languageLearningProcessVisible: false, // quá trình ngoại ngữ
            dataLanguageLearningProcess: [],
            disciplineVisible: false, // kỷ luật
            dataDiscipline: [],
            bonusVisible: false, // khen thưởng
            dataBonus: [],
            careerAssessmentVisible: false, // Đánh giá chuẩn nghề
            dataCareerAssessment: [],
            trainingVisible: false, // Quá trình đạo tạo bồi dưỡng
            dataTraining: [],

            active_tab_info: 1,
            list_tab_info: [{ number: 1, label: 'Thông tin chung' }],

            active_tab_private: 1,
            list_tab_private: [
                { number: 1, label: 'I. Vị trí việc làm TCCD' },
                { number: 2, label: 'II. Phụ cấp' },
                { number: 3, label: 'III. Đào tạo, bồi dưỡng' },
                { number: 4, label: 'IV. Đánh giá, phân loại' }
            ]
        }
    },

    mounted() {
        this.get_data_info_teacher()

        this.handleClearError()
        this.viewVisible = this.$route.query.viewVisible === 'true'
    },

    methods: {
        getContentToolBar() {
            return this.viewVisible
                ? 'Thông tin Cán bộ, giáo viên'
                : 'Chỉnh sửa Cán bộ, giáo viên'
        },
        handleTabPrivateChanged(tabNumber) {
            this.active_tab_private = tabNumber
        },
        openModalSalaryProcess() {
            this.dataSalaryProcess = parseJsonArray(
                this.info_teacher.lstQuaTrinhLuong
            )
            this.salaryProcessVisible = true
        },
        closeModalSalaryProcess() {
            this.salaryProcessVisible = false
        },
        openModalLanguageLearningProcess() {
            this.dataLanguageLearningProcess = parseJsonArray(
                this.info_teacher.lstQuaTrinhNgoaiNgu
            )
            this.languageLearningProcessVisible = true
        },
        closeModalLanguageLearningProcess() {
            this.languageLearningProcessVisible = false
        },
        openModalDiscipline() {
            this.dataDiscipline = this.info_teacher.lstKyLuats
            this.disciplineVisible = true
        },
        closeModalDiscipline() {
            this.disciplineVisible = false
        },
        openModalBonus() {
            this.dataBonus = this.info_teacher.lstKhenThuongs
            this.bonusVisible = true
        },
        closeModalBonus() {
            this.bonusVisible = false
        },
        openModalCareerAssessment() {
            this.dataCareerAssessment =
                this.info_teacher.canBoTheoNamDanhGiaKqChuanNgheNghiepEntities
            this.careerAssessmentVisible = true
        },
        closeModalCareerAssessment() {
            this.careerAssessmentVisible = false
        },
        openModalTraining() {
            this.dataTraining = parseJsonArray(
                this.info_teacher.lstQuaTrinhDaoTaoBoiDuong
            )
            this.trainingVisible = true
        },
        closeModalTraining() {
            this.trainingVisible = false
        },

        async get_data_info_teacher() {
            const canBoTheoNamId = this.$route.params.id

            const request_params = {
                canBoTheoNamId: canBoTheoNamId
            }
            const response = await sendRequest(
                Api.tra_cuu.tra_cuu_can_bo_giao_vien.chi_tiet_can_bo_gv,
                null,
                this.request_headers,
                request_params
            )
            if (response) {
                this.avatar = response.avatar

                this.teacher_code = response.maGiaoVien

                this.name = response.tenGiaoVien
                this.birthday = formatDateToYYYYMMDD(response.ngaySinh)
                this.gender = response.gioiTinh
                this.status = response.trangThai?.toString()
                this.is_new_recruit = response.tuyenMoi
                this.identity_number = response.soCmnd
                this.date_range = formatDateToYYYYMMDD(response.ngayCapCmnd)
                // [6] nơi cấp
                // [7] CCCD có gắn chip
                // [8] Số định danh cá nhân
                this.email = response.email
                this.phone = response.soDienThoai
                this.nation = response.maDanToc
                this.religion = response.maTonGiao
                this.moet_code = response.moetCode

                // [14] Số hộ chiếu
                // [15] Ngày cấp (Hộ chiếu)
                // [16] Nơi cấp (Hộ chiếu)
                this.is_union_member = convertToBoolean(response.doanVien)
                this.unit_enlistment_location = response.noiVaoDoan
                this.is_party_member = convertToBoolean(response.dangVien)
                this.party_enlistment_location = response.noiVaoDang
                // [19] Ngày vào đảng dự bị
                // [20] Ngày vào đảng chính thức
                this.social_insurance_book_number = response.soSoBhxh

                // Hộ khẩu thường trú
                this.province_permanent_address = response.maTthanhThtru

                if (this.province_permanent_address) {
                    this.onChangeProvincePermanentAddress()
                }

                this.district_permanent_address = response.maQhuyenThtru
                if (this.district_permanent_address) {
                    this.onChangeDistrictPermanentAddress()
                }
                this.wards_permanent_address = response.maPxaThtru
                this.village_permanent_address = response.maTthonThtru

                // Quê quán
                this.province_hometown = response.maTthanhQueQuan

                if (this.province_hometown) {
                    this.onChangeProvinceHometown()
                }
                this.district_hometown = response.maQhuyenQueQuan
                if (this.district_hometown) {
                    this.onChangeDistrictHometown()
                }
                this.wards_hometown = response.maPxaQueQuan
                this.village_hometown = response.maTthonQueQuan

                this.job_position = response.loaiCanBo?.toString()
                this.subject = response.maMonHoc
                this.experiential_subject = response.monDayKiemNhiem
                this.position = response.chucVu
                this.contract_form = response.loaiHopDong
                this.recruitment_day = formatDateToYYYYMMDD(
                    response.ngayTuyenDung
                )
                this.employment_agencies = response.coQuanTuyenDung
                this.occupation_recruited = response.ngheNghiepTuyenDung
                this.part_time_duties = response.nhiemVuKiemNhiem?.toString()
                this.number_of_actual_lessons_taught_per_week =
                    response.soTietThucDay1Tuan
                this.number_of_concurrent_meals_per_week =
                    response.soTietKiemNhiem1Tuan
                this.is_trained_in_teaching_life_skills = convertToBoolean(
                    response.tapHuanKnsSkss
                )
                this.is_teaching_students_with_disabilities_to_learn_inclusively =
                    convertToBoolean(response.dayHsktHocHoaNhap)
                // [35] Số buổi dạy/ngày
                this.is_regular_treatment_program =
                    response.thamGiaChuongTrinhBoiDuong
                // [36.1] Tổng phụ trách đội
                this.rank = response.maNgach
                // [38] Mã số
                this.is_TCCD = response.coCcBdChucDanhNgheNghiep

                this.occupational_attraction_bonus = response.mucPhuCapThuHut
                this.seniority_allowance = response.mucPhuCapThamNien
                this.occupational_preferential_benefits = response.mucPhuCapUuDai
                this.leadership_role_allowance = response.mucPhuCapLanhDao
                this.pay_scale = response.bacLuong
                this.salary_premium = response.vuotKhung
                this.salary_factor = response.heSoLuong
                this.effective_date = formatDateToYYYYMMDD(response.ngayHuong)

                this.continuous_training_outcomes = response.kqBdTx?.toString()
                this.professional_competence_for_tasks =
                    response.trinhDoChuyenMonCaoNhat

                this.political_ideology_proficiency =
                    response.trinhDoLyLuanChinhTri
                this.educational_administration_proficiency =
                    response.trinhDoQuanLyGiaoDuc
                this.participating_in_educational_leadership_training =
                    response.maBoiDuongNvu
                this.participating_training_administrative_teaching_core_personnel =
                    response.maBoiDuongCbqlCotCan
                this.participating_in_book_replacement_training_program =
                    response.maBoiDuongThaySach
                this.main_language = response.monNgoaiNguChinh
                this.foreign_language_education_level = response.trinhDoNgoaiNgu
                this.language_certificate_group = response.maNhomCchiNngu
                this.type_of_language_certificate = response.maLoaiCchiNngu
                this.language_proficiency_framework = response.maKhungNlucNngu
                this.computer_proficiency = response.trinhDoTinHoc

                this.major_field = response.chuyenNganhDaoTaoChinh
                this.main_proficiency = response.trinhDoDaoTaoCnChinh
                this.training_facility = response.tenCsDaoTaoBd
                this.other_major = response.chuyenNganhDaoTaoKhac
                this.other_proficiency = response.trinhDoDaoTaoCnKhac
                this.teacher_with_minority_language_certificate =
                    response.maChungChiTiengDtocTso
                this.employee_evaluation = response.danhGiaVienChuc
                this.excellent_teacher = response.gvDayGioi?.toString()
                this.excellent_homeroom_teacher = response.maGiaoVienChuNhiemGioi
                this.team_excellence_leader = response.maGvTongPtrachDoiGioi
                this.honorary_title_award = response.danhHieuPhongTang

                this.info_teacher = response
            }
        },

        navigateLockUpTeacher() {
            this.$router.push({ path: path.lock_up.teacher.home })
        },

        handleClearError() {
            this.errModal = false
        },

        async get_list_district(province, type) {
            try {
                const request_params = {
                    start: 0,
                    limit: 999,
                    maTinhThanh: province,
                    check: false
                }
                const response = await sendRequest(
                    Api.app.quan_huyen,
                    null,
                    this.request_headers,
                    request_params
                )

                const list = convertListData(
                    response.rows,
                    'tenQuanHuyen',
                    'maQuanHuyen'
                )
                const listMap = {
                    permanent_address: 'list_district_permanent_address',
                    hometown: 'list_district_hometown'
                }

                province
                    ? (this[listMap[type]] = list)
                    : (this[listMap[type]] = [])
            } catch (error) {
                console.log(error)
            }
        },

        onChangeProvincePermanentAddress() {
            this.district_permanent_address = ''
            this.list_wards_permanent_address = []
            this.wards_permanent_address = ''
            this.get_list_district(
                this.province_permanent_address,
                'permanent_address'
            )
        },
        onChangeDistrictPermanentAddress() {
            this.wards_permanent_address = ''
            this.get_list_wards(
                this.district_permanent_address,
                'permanent_address'
            )
        },
        onChangeProvinceHometown() {
            this.district_hometown = ''
            this.list_wards_hometown = []
            this.wards_hometown = ''
            this.get_list_district(this.province_hometown, 'hometown')
        },
        onChangeDistrictHometown() {
            this.wards_hometown = ''
            this.get_list_wards(this.district_hometown, 'hometown')
        },
        async get_list_wards(district, type) {
            try {
                const request_params = {
                    start: 0,
                    limit: 9999,
                    maTinhThanh:
                        type === 'hometown'
                            ? this.province_hometown
                            : this.province_permanent_address,
                    maQuanHuyen: district,
                    check: false
                }
                const response = await sendRequest(
                    Api.app.phuong_xa,
                    null,
                    this.request_headers,
                    request_params
                )

                const list = convertListData(
                    response.rows,
                    'tenPhuongXa',
                    'maPhuongXa'
                )
                const listMap = {
                    permanent_address: 'list_wards_permanent_address',
                    hometown: 'list_wards_hometown'
                }
                district
                    ? (this[listMap[type]] = list)
                    : (this[listMap[type]] = [])
            } catch (error) {
                console.log(error)
            }
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                name: 'err_name',
                birthday: 'err_birthday',
                status: 'err_status',
                job_position: 'err_job_position',
                professional_competence_for_tasks:
                    'err_professional_competence_for_tasks'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        async handleUpdate() {
            this.active_tab_private = 3
            try {
                const fieldsToValidate = [
                    {
                        value: this.name,
                        errorField: 'err_name'
                    },

                    {
                        value: this.birthday,
                        errorField: 'err_birthday'
                    },

                    {
                        value: this.status,
                        errorField: 'err_status'
                    },

                    {
                        value: this.job_position,
                        errorField: 'err_job_position'
                    },

                    {
                        value: this.professional_competence_for_tasks,
                        errorField: 'err_professional_competence_for_tasks'
                    }
                ]

                if (validateFields(this, fieldsToValidate)) {
                    const request_data = {
                        updateGiaoVienRequest: {
                            anhChanDung: this.info_teacher.anhChanDung,
                            anhThe: this.info_teacher.anhThe,
                            avatar: this.info_teacher.avatar,
                            bacLuong: this.pay_scale,
                            canBoTheoNamDanhGiaKqChuanNgheNghiepEntities:
                                this.info_teacher
                                    .canBoTheoNamDanhGiaKqChuanNgheNghiepEntities,
                            canBoTheoNamId: this.info_teacher.canBoTheoNamId,
                            canBoTheoNamQtbdNcnlNgoainguList:
                                this.info_teacher
                                    .canBoTheoNamQtbdNcnlNgoainguList,
                            canBoTheoNamQtbdNcnlSuphamList:
                                this.info_teacher.canBoTheoNamQtbdNcnlSuphamList,
                            capHoc: this.info_teacher.capHoc,
                            capKhenThuong: this.info_teacher.capKhenThuong,
                            capKyLuat: this.info_teacher.capKyLuat,
                            chucVu: this.position,
                            chuyenNganhDaoTaoChinh: this.major_field,
                            chuyenNganhDaoTaoKhac: this.other_major,
                            chuyenTrachDoi: this.info_teacher.chuyenTrachDoi,
                            coCcBdChucDanhNgheNghiep: this.is_TCCD,
                            coQuanTuyenDung: this.employment_agencies,
                            dangVien: convertToBinary(this.is_party_member),
                            danhGiaVienChuc: this.employee_evaluation,
                            danhHieuPhongTang: this.honorary_title_award,
                            day1BuoiTrenNgay: this.info_teacher.day1BuoiTrenNgay,
                            day2BuoiTrenNgay: this.info_teacher.day2BuoiTrenNgay,
                            dayHsktHocHoaNhap:
                                this.info_teacher.dayHsktHocHoaNhap,
                            dayNhomLop: this.info_teacher.dayNhomLop,
                            dayNhomLopKiemNhiem:
                                this.info_teacher.dayNhomLopKiemNhiem,
                            dchiThtru: this.info_teacher.dchiThtru,
                            dchiTmtru: this.info_teacher.dchiTmtru,
                            dgKqChuanNgheNghiep:
                                this.info_teacher.dgKqChuanNgheNghiep,
                            diemNgoaiNgu: null,
                            doanVien: convertToBinary(this.is_union_member),
                            doiVien: this.info_teacher.doiVien,
                            dongBoLoId: this.info_teacher.dongBoLoId,
                            dongBoThuTu: this.info_teacher.dongBoThuTu,
                            email: this.email,
                            gioiTinh: this.gender,
                            gvDayGioi: this.excellent_teacher,
                            hash: this.info_teacher.hash,
                            heSo: this.info_teacher.heSo,
                            heSoLuong: this.info_teacher.heSoLuong,
                            hinhThucDaoTao: this.info_teacher.hinhThucDaoTao,
                            hoTenBo: this.info_teacher.hoTenBo,
                            hoTenMe: this.info_teacher.hoTenMe,
                            hoTenVoChong: this.info_teacher.hoTenVoChong,
                            kqBdTx: this.continuous_training_outcomes,
                            loaiCanBo: Number(this.job_position),
                            loaiGiaoVien: this.info_teacher.loaiGiaoVien,
                            loaiHopDong: this.contract_form,
                            lopHocs: this.info_teacher.lopHocs,
                            lstKhenThuong: this.info_teacher.lstKhenThuong,
                            lstKhenThuongs: this.info_teacher.lstKhenThuongs,
                            lstKyLuat: this.info_teacher.lstKyLuat,
                            lstKyLuats: this.info_teacher.lstKyLuats,
                            lstNhiemVuKiemNhiem:
                                this.info_teacher.lstNhiemVuKiemNhiem,
                            lstQuaTrinhBdncnlNgoaiNgu:
                                this.info_teacher.lstQuaTrinhBdncnlNgoaiNgu,
                            lstQuaTrinhBdncnlSuPham:
                                this.info_teacher.lstQuaTrinhBdncnlSuPham,
                            lstQuaTrinhDaoTaoBoiDuong:
                                this.info_teacher.lstQuaTrinhDaoTaoBoiDuong,
                            lstQuaTrinhLuong: this.info_teacher.lstQuaTrinhLuong,
                            lstQuaTrinhNgoaiNgu:
                                this.info_teacher.lstQuaTrinhNgoaiNgu,
                            lyDoNghiViec: this.info_teacher.lyDoNghiViec,
                            maBoiDuongCbqlCotCan:
                                this
                                    .participating_training_administrative_teaching_core_personnel,
                            maBoiDuongNvu:
                                this
                                    .participating_in_educational_leadership_training,
                            maBoiDuongThaySach:
                                this
                                    .participating_in_book_replacement_training_program,
                            maChungChiTiengDtocTso:
                                this.teacher_with_minority_language_certificate,
                            maChuyenNganhDaoTao:
                                this.info_teacher.maChuyenNganhDaoTao,
                            maDanToc: this.nation,
                            maDoiTac: this.info_teacher.maDoiTac,
                            maGiaoVien: this.info_teacher.maGiaoVien,
                            maGiaoVienChuNhiemGioi:
                                this.excellent_homeroom_teacher,
                            maGvTongPtrachDoiGioi: this.team_excellence_leader,
                            maKhenThuongGiaoVien:
                                this.info_teacher.maKhenThuongGiaoVien,
                            maKhungNlucNngu: this.language_proficiency_framework,
                            maKyLuatGiaoVien: this.info_teacher.maKyLuatGiaoVien,
                            maLoaiCchiNngu: this.type_of_language_certificate,
                            maLoiSync: this.info_teacher.maLoiSync,
                            maMonHoc: this.subject,
                            maNgach: this.rank,
                            maNhomCchiNngu: this.language_certificate_group,
                            maPxaThtru: this.wards_permanent_address,
                            maPxaTmtru: this.info_teacher.maPxaTmtru,
                            maQhuyenThtru: this.district_permanent_address,
                            maQhuyenTmtru: this.info_teacher.maQhuyenTmtru,
                            maTinhThanh: this.info_teacher.maTinhThanh,
                            maTonGiao: this.religion,
                            maTruongHoc: this.info_teacher.maTruongHoc,
                            maTthanhThtru: this.province_permanent_address,
                            maTthanhTmtru: this.info_teacher.maTthanhTmtru,
                            maTthonThtru: this.village_permanent_address,
                            maTthonTmtru: this.info_teacher.maTthanhTmtru,
                            maTthanhQueQuan: this.province_hometown,
                            maQhuyenQueQuan: this.district_hometown,
                            maPxaQueQuan: this.wards_hometown,
                            maTthonQueQuan: this.village_hometown,
                            maVanBanChungChiDaoTao:
                                this.info_teacher.maVanBanChungChiDaoTao,
                            moetCode: this.moet_code,
                            monDayChinh: this.info_teacher.monDayChinh,
                            monDayKiemNhiem: this.experiential_subject,
                            monNgoaiNguChinh: this.main_language,
                            mucPhuCapLanhDao: this.leadership_role_allowance,
                            mucPhuCapThamNien: this.seniority_allowance,
                            mucPhuCapThuHut: this.occupational_attraction_bonus,
                            mucPhuCapUuDai:
                                this.occupational_preferential_benefits,
                            namGioiCapHuyen: this.info_teacher.namGioiCapHuyen,
                            namGioiCapTinh: this.info_teacher.namGioiCapTinh,
                            namGioiCapTruong: this.info_teacher.namGioiCapTruong,
                            namHoc: this.info_teacher.namHoc,
                            namSinh: this.info_teacher.namSinh,
                            namVaoTruong: this.info_teacher.namVaoTruong,
                            ngachHang: this.info_teacher.ngachHang,
                            ngayBatDauDaoTao: this.info_teacher.ngayBatDauDaoTao,
                            ngayCapCmnd: formatDateToDDMMYYYY(this.date_range),
                            ngayCapNhat: this.info_teacher.ngayCapNhat,
                            ngayChuyenDen: this.info_teacher.ngayChuyenDen,
                            ngayHuong: this.info_teacher.ngayHuong,
                            ngayKetThucDaoTao:
                                this.info_teacher.ngayKetThucDaoTao,
                            ngayKhenThuong: this.info_teacher.ngayKhenThuong,
                            ngayKyLuat: this.info_teacher.ngayKyLuat,
                            ngaySinh: formatDateToDDMMYYYY(this.birthday),
                            ngaySinhBo: this.info_teacher.ngaySinhBo,
                            ngaySinhMe: this.info_teacher.ngaySinhMe,
                            ngaySinhVoChong: this.info_teacher.ngaySinhVoChong,
                            ngayTrangThai: this.info_teacher.ngayTrangThai,
                            ngayTuyenDung:
                                formatDateToDDMMYYYY(this.recruitment_day) ||
                                null,
                            ngayVaoBienChe: this.info_teacher.ngayVaoBienChe,
                            ngayVaoDang: this.info_teacher.ngayVaoDang,
                            ngayVaoDoan: this.info_teacher.ngayVaoDoan,
                            ngayVaoDoi: this.info_teacher.ngayVaoDoi,
                            ngayVaoNghe: this.info_teacher.ngayVaoNghe,
                            ngheNghiepBo: this.info_teacher.ngheNghiepBo,
                            ngheNghiepMe: this.info_teacher.ngheNghiepMe,
                            ngheNghiepTuyenDung: this.occupation_recruited,
                            ngheNghiepVoChong:
                                this.info_teacher.ngheNghiepVoChong,
                            nhiemVuKiemNhiem: this.part_time_duties,
                            nhomMau: this.info_teacher.nhomMau,
                            noiCapCmnd: this.info_teacher.noiCapCmnd,
                            noiDungKhenThuong:
                                this.info_teacher.noiDungKhenThuong,
                            noiLamViecBo: this.info_teacher.noiLamViecBo,
                            noiLamViecMe: this.info_teacher.noiLamViecMe,
                            noiLamViecVoChong:
                                this.info_teacher.noiLamViecVoChong,
                            noiSinh: this.info_teacher.noiSinh,
                            noiVaoDang: this.party_enlistment_location,
                            noiVaoDoan: this.unit_enlistment_location,
                            noiVaoDoi: this.info_teacher.noiVaoDoi,
                            qlntCode: this.info_teacher.qlntCode,
                            quaTrinhDaoTaoBoiDuongs:
                                this.info_teacher.quaTrinhDaoTaoBoiDuongs,
                            quaTrinhLuongs: this.info_teacher.quaTrinhLuongs,
                            quaTrinhNgoaiNgus:
                                this.info_teacher.quaTrinhNgoaiNgus,
                            queQuan: this.village_hometown,
                            reqMesId: this.info_teacher.reqMesId,
                            soCmnd: this.identity_number,
                            soDienThoai: this.info_teacher.soDienThoai,
                            soDienThoaiBo: this.info_teacher.soDienThoaiBo,
                            soDienThoaiCoDinh:
                                this.info_teacher.soDienThoaiCoDinh,
                            soDienThoaiDiDong:
                                this.info_teacher.soDienThoaiDiDong,
                            soDienThoaiMe: this.info_teacher.soDienThoaiMe,
                            soDienThoaiVoChong:
                                this.info_teacher.soDienThoaiVoChong,
                            soQuyetDinhKhenThuong:
                                this.info_teacher.soQuyetDinhKhenThuong,
                            soQuyetDinhKyLuat:
                                this.info_teacher.soQuyetDinhKyLuat,
                            soSoBhxh: this.social_insurance_book_number,
                            soTietKiemNhiem1Tuan:
                                this.number_of_concurrent_meals_per_week,
                            soTietThucDay1Tuan:
                                this.number_of_actual_lessons_taught_per_week,
                            sync: this.info_teacher.sync,
                            syncLanDay: this.info_teacher.syncLanDay,
                            syncMaDoiTac: this.info_teacher.syncMaDoiTac,
                            syncMaPhongGd: this.info_teacher.syncMaPhongGd,
                            syncMaSoGd: this.info_teacher.syncMaSoGd,
                            syncMaTruongHoc: this.info_teacher.syncMaTruongHoc,
                            syncNgayCapNhat: this.info_teacher.syncNgayCapNhat,
                            syncVersion: this.info_teacher.syncVersion,
                            tapHuanKnsSkss: convertToBinary(
                                this.is_trained_in_teaching_life_skills
                            ),
                            tenBacLuong: this.info_teacher.tenBacLuong,
                            tenCanBo: this.name,
                            tenCsDaoTaoBd: this.training_facility,
                            tenGiaoVien: this.name,
                            tenGvDayGioi: this.info_teacher.tenGvDayGioi,
                            tenKqBdTx: this.info_teacher.tenKqBdTx,
                            tenLoaiCanBo: this.info_teacher.tenLoaiCanBo,
                            tenNhiemVuKiemNhiem:
                                this.info_teacher.tenNhiemVuKiemNhiem,
                            tenTrangThai: this.info_teacher.tenTrangThai,
                            tenTrinhDoDaoTaoCnChinh:
                                this.info_teacher.tenTrinhDoDaoTaoCnChinh,
                            tenTrinhDoDaoTaoCnKhac:
                                this.info_teacher.tenTrinhDoDaoTaoCnKhac,
                            thamGiaBdTxuyen: this.info_teacher.thamGiaBdTxuyen,
                            thamGiaChuongTrinhBoiDuong:
                                this.is_regular_treatment_program,
                            thanhPhanGiaDinh: this.info_teacher.thanhPhanGiaDinh,
                            tinhTrangHonNhan: this.info_teacher.tinhTrangHonNhan,
                            tinhTrangSucKhoe: this.info_teacher.tinhTrangSucKhoe,
                            trangThai: this.status,
                            trinhDoChuyenMonCaoNhat:
                                this.professional_competence_for_tasks,
                            trinhDoDTCNChinh: this.info_teacher.trinhDoDTCNChinh,
                            trinhDoDTCNKhac: this.info_teacher.trinhDoDTCNKhac,
                            trinhDoDaoTao: this.info_teacher.trinhDoDaoTao,
                            trinhDoDaoTaoCnChinh: this.main_proficiency,
                            trinhDoDaoTaoCnKhac: this.other_proficiency,
                            trinhDoGvien: this.info_teacher.trinhDoGvien,
                            trinhDoLyLuanChinhTri:
                                this.political_ideology_proficiency,
                            trinhDoNgoaiNgu:
                                this.foreign_language_education_level,
                            trinhDoQuanLyGiaoDuc:
                                this.educational_administration_proficiency,
                            trinhDoQuanLyNhaNuoc:
                                this.info_teacher.trinhDoQuanLyNhaNuoc,
                            trinhDoTinHoc: this.computer_proficiency,
                            trinhDoVanHoa: this.info_teacher.trinhDoVanHoa,
                            tuDanhGiaKqChuanNgheNghiep:
                                this.info_teacher.tuDanhGiaKqChuanNgheNghiep,
                            tuyenMoi: this.is_new_recruit,
                            vanBanChungChiDaoTao:
                                this.info_teacher.vanBanChungChiDaoTao,
                            vuotKhung: this.info_teacher.vuotKhung
                        }
                    }

                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_can_bo_giao_vien.chinh_sua_can_bo_gv,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        this.navigateLockUpTeacher()

                        showNotication(this, 'success', response.rd)
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },

    computed: {
        list_province() {
            return getFromSessionStorage('list_province')
        },
        list_status() {
            return getFromSessionStorage('DM_TRANG_THAI_CAN_BO')
        },
        list_nation() {
            return getFromSessionStorage('DM_DAN_TOC')
        },
        list_religion() {
            return getFromSessionStorage('DM_TON_GIAO')
        },
        list_job_position() {
            return getFromSessionStorage('DM_NHOM_CAN_BO')
        },
        list_subject() {
            return getFromSessionStorage('DM_MON_DAY_GV')
        },
        list_position() {
            return getFromSessionStorage('DM_LOAI_CAN_BO')
        },
        list_contract_form() {
            return getFromSessionStorage('DM_HINH_THUC_HOP_DONG')
        },
        list_part_time_duties() {
            return getFromSessionStorage('DM_NHIEM_VU_KIEM_NHIEM')
        },
        list_rank() {
            return getFromSessionStorage('DM_NGACH_CC')
        },
        list_continuous_training_outcomes() {
            return getFromSessionStorage('DM_BOI_DUONG_TX')
        },
        list_professional_competence_for_tasks() {
            return getFromSessionStorage('DM_TRINH_DO_CHUYEN_MON')
        },
        list_political_ideology_proficiency() {
            return getFromSessionStorage('DM_TRINH_DO_LLCT')
        },
        list_educational_administration_proficiency() {
            return getFromSessionStorage('DM_TRINH_DO_QLGD')
        },
        list_participating_in_educational_leadership_training() {
            return getFromSessionStorage('DM_BOI_DUONG_NVU')
        },
        list_participating_training_administrative_teaching_core_personnel() {
            return getFromSessionStorage('DM_BOI_DUONG_CBQL_COT_CAN')
        },
        list_participating_in_book_replacement_training_program() {
            return getFromSessionStorage('DM_BOI_DUONG_THAY_SACH')
        },
        list_main_language() {
            return getFromSessionStorage('DM_NGOAI_NGU')
        },
        list_foreign_language_education_level() {
            return getFromSessionStorage('DM_TRINH_DO_NGOAI_NGU')
        },
        list_language_certificate_group() {
            return getFromSessionStorage('DM_NHOM_CCHI_NNGU')
        },
        list_type_of_language_certificate() {
            return getFromSessionStorage('DM_LOAI_CCHI_NNGU')
        },
        list_language_proficiency_framework() {
            return getFromSessionStorage('DM_KHUNG_NLUC_NNGU')
        },
        list_computer_proficiency() {
            return getFromSessionStorage('DM_TRINH_DO_TIN_HOC')
        },
        list_specialized() {
            return getFromSessionStorage('DM_CHUYEN_MON')
        },
        list_teacher_with_minority_language_certificate() {
            return getFromSessionStorage('DM_CHUNG_CHI_TIENG_DTOC_TSO')
        },
        list_employee_evaluation() {
            return getFromSessionStorage('DM_DG_VIEN_CHUC')
        },
        list_excellent_teacher() {
            return getFromSessionStorage('DM_GIAO_VIEN_GIOI')
        },
        list_excellent_homeroom_teacher() {
            return getFromSessionStorage('DM_GIAO_VIEN_CHU_NHIEM_GIOI')
        },
        list_team_excellence_leader() {
            return getFromSessionStorage('DM_GV_TONG_PTRACH_DOI_GIOI')
        },

        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
