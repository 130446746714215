<template>
    <div>
        <div class="chart-container">
            <div ref="chartContainer"></div>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'

export default {
    mounted() {
        this.renderChart()
    },
    watch: {
        data_LineChart: {
            handler(newData) {
                this.updateChart(newData)
            },
            deep: true
        }
    },

    methods: {
        renderChart() {
            var options = {
                series: [],
                chart: {
                    height: 300,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [
                        '0',
                        '<1',
                        '<2',
                        '<3',
                        '<4',
                        '<5',
                        '<6',
                        '<7',
                        '<8',
                        '<9',
                        '<10'
                    ]
                },
                noData: {
                    text: 'Biểu đồ chưa có dữ liệu',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                stroke: {
                    curve: 'smooth'
                },
                fill: {
                    type: 'solid',
                    opacity: 0
                },
                legend: {
                    position: 'bottom',
                    offsetX: 0,
                    offsetY: 8
                },
                colors: [
                    '#E14A02',
                    '#FFA554',
                    '#8DAF88',
                    '#FFDFA5',
                    '#325434',
                    '#7D5510',
                    '#FFFF00',
                    '#19070B',
                    '#107C41'
                ]
            }

            const chartContainer = this.$refs.chartContainer
            this.chart = new ApexCharts(chartContainer, options)
            this.chart.render()
        },
        updateChart(newData) {
            if (newData && newData.length > 0) {
                this.chart.updateOptions({
                    series: newData
                })
            }
        }
    },
    props: {
        data_LineChart: {
            type: Array,
            required: true
        }
    }
}
</script>

<style></style>
