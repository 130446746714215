<template>
    <div>
        <ToolBar
            :menu="'Tài khoản'"
            :content="'Thông tin tài khoản'"
            :hideToggle="true"
            :hideZoom="true"
        >
        </ToolBar>
        <div class="p-5">
            <div class="info">
                <div class="info-header">
                    <p>Thông tin cơ bản</p>
                    <CustomButton label="Cập nhật" />
                </div>
                <div class="body">
                    <div class="row border-bottom">
                        <div class="col-4 col-xl-3 pl-0">
                            <p class="p-left">Tên tài khoản</p>
                        </div>
                        <div class="col-8 col-xl-9">
                            <p class="p-right">Trường mầm non Bình Minh</p>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-4 col-xl-3 pl-0">
                            <p class="p-left">
                                Email:<span class="error-label">*</span>
                            </p>
                        </div>
                        <div class="col-8 col-xl-9">
                            <p class="p-right">email@gmail.com</p>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-4 col-xl-3 pl-0">
                            <p class="p-left">
                                Số điện thoại:<span class="error-label">*</span>
                            </p>
                        </div>
                        <div class="col-8 col-xl-9">
                            <p class="p-right">0987654321</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info">
                <div class="info-header">
                    <p>Thông tin sở giáo dục</p>
                </div>
                <div class="body">
                    <div class="row border-bottom">
                        <div class="col-4 col-xl-3 pl-0">
                            <p class="p-left">Mã đơn vị</p>
                        </div>
                        <div class="col-8 col-xl-9">
                            <p class="p-right"></p>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-4 col-xl-3 pl-0">
                            <p class="p-left">Tên đơn vị</p>
                        </div>
                        <div class="col-8 col-xl-9">
                            <p class="p-right"></p>
                        </div>
                    </div>
                    <div class="row border-bottom">
                        <div class="col-4 col-xl-3 pl-0">
                            <p class="p-left">Cấp học</p>
                        </div>
                        <div class="col-8 col-xl-9">
                            <p class="p-right"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

export default {
    name: 'DataTransferSchedule',
    components: {
        ToolBar,
        CustomButton
    },
    data() {
        return {}
    },

    methods: {},
    mounted() {},

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.info {
    background-color: var(--white-100);
    border-radius: 4px;
    margin: 0 auto;
    padding: 20px;
    width: 80%;
}
.info-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.info-header p {
    color: #186ab2;
    font-size: 15px;
    font-weight: 600;
}
.body {
    padding: 0px 20px 10px 20px;
}
.p-left {
    margin-top: 1rem;
    color: #262626;
    font-weight: 600;
}
.p-right {
    margin-top: 1rem;
    color: #666;
    font-weight: 400;
}
.error {
    color: red;
}
</style>
