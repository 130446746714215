<template>
    <CustomModal
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới đối tác trường Trường'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-4">
                    <label>Đơn vị</label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="onChangeHandler('unit_education', $event)"
                        @change="get_list_school"
                    />
                </div>
                <div class="col-sm-2">
                    <label>Cấp học</label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_grade_level"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="grade_level"
                        @input="onChangeHandler('grade_level', $event)"
                        @change="get_list_school"
                    />
                </div>

                <div class="col-sm-4">
                    <label>Trường học <span class="error-label">*</span></label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenTruongHoc', 'maTruongHoc']"
                        v-model="school"
                        @input="onChangeHandler('school', $event)"
                        :class="{
                            'error-select': err_school.length > 0
                        }"
                    />
                    <span v-if="err_school.length > 0" class="text-error-select">
                        {{ err_school }}
                    </span>
                </div>
                <div class="col-sm-2">
                    <label>Đối tác <span class="error-label">*</span></label>
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_partner"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="partner"
                        @input="onChangeHandler('partner', $event)"
                        :class="{
                            'error-select': err_partner.length > 0
                        }"
                    />
                    <span v-if="err_partner.length > 0" class="text-error-select">
                        {{ err_partner }}
                    </span>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleAddNew"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { convertListData } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalAdd',
    data() {
        return {
            unit_education: [],
            grade_level: '',
            list_school: [],
            school: [],
            partner: '',
            err_school: '',
            err_partner: '',

            errModal: false
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function,
        list_partner: {
            type: Array,
            require: true
        }
    },
    components: { CustomButton, CustomModal, ESelect },
    watch: {
        addNewVisible(newVal) {
            if (newVal === true) {
                this.get_list_school()
            }
        }
    },
    methods: {
        onChangeHandler(source, newValue) {
            const noValidate = ['grade_level', 'unit_education']

            if (noValidate.includes(source) && newValue.length > 0) {
                this.errModal = true
            } else {
                this.errModal = false
            }
            const errorFields = {
                school: 'err_school',
                partner: 'err_partner'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async get_list_school() {
            this.err_school = ''
            this.school = []

            const request_data = {
                capHoc: this.grade_level ? [this.grade_level] : [],
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },

        handleClearData() {
            this.school = []
            this.errModal = false
            this.err_school = ''
            this.partner = ''
            this.err_partner = ''
            this.unit_education = []
            this.grade_level = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        async handleAddNew() {
            try {
                const fieldsToValidate = [
                    {
                        errorField: 'err_school',
                        value: this.school
                    },
                    {
                        errorField: 'err_partner',
                        value: this.partner
                    }
                ]

                if (validateFields(this, fieldsToValidate)) {
                    const truongEntities = {
                        maDoiTac: this.partner,
                        maTruongHoc: this.school
                    }
                    const convertTruongEntities = []

                    truongEntities.maTruongHoc.forEach((maTruong) => {
                        convertTruongEntities.push({
                            maDoiTac: truongEntities.maDoiTac,
                            maTruongHoc: maTruong
                        })
                    })

                    const request_data = {
                        truongEntities: convertTruongEntities
                    }

                    const response = await sendRequest(
                        Api.tiep_nhan_du_lieu_QLNT.quan_ly_he_thong_doi_tac_truong
                            .them_moi,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)
                        this.closeModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                }
            } catch (error) {
                if (error.response?.data.rd) {
                    showNotication(this, 'error', error.response?.data.rd)
                }
            }
        }
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_grade_level() {
            return getFromSessionStorage('list_grade_level')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}

::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
