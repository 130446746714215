<template>
    <CustomModal
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm tài khoản kết chuyển lên Bộ'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-4">
                    <label>Đơn vị quản lý</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_unit_education"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['tenDonVi', 'maDonVi']"
                            @change="get_list_school"
                            @input="onChangeHandler('unit_education', $event)"
                            v-model="unit_education"
                        />
                    </div>
                </div>

                <div class="col-sm-4">
                    <label>Cấp học</label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_grade_level"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            @change="get_list_school"
                            @input="onChangeHandler('grade_level', $event)"
                            v-model="grade_level"
                        />
                    </div>
                </div>

                <div class="col-sm-4">
                    <label> Trường học <span class="error-label">*</span></label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :clearable="true"
                            :disabled="false"
                            :data="list_school"
                            :placeholder="'Chọn'"
                            :filterable="true"
                            :multiple="false"
                            :collapse-tags="true"
                            :fields="['label', 'value']"
                            v-model="school"
                            @input="onChangeHandler('school', $event)"
                            :class="{
                                'error-select': err_school.length > 0
                            }"
                        />
                    </div>
                    <span v-if="err_school.length > 0" class="text-error-select">
                        {{ err_school }}
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-4">
                    <label
                        >Tên đăng nhập: <span class="error-label">*</span></label
                    >
                    <div>
                        <CustomInput
                            v-model="user_name"
                            @input="onChangeHandler('user_name', $event)"
                            :textError="err_user_name"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>Mật khẩu: <span class="error-label">*</span></label>
                    <div>
                        <CustomInput
                            v-model="password"
                            @input="onChangeHandler('password', $event)"
                            :textError="err_password"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleAddNew"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { convertListData } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalAdd',
    data() {
        return {
            unit_education: '',
            grade_level: '',
            list_school: [],
            school: '',
            user_name: '',
            password: '',

            err_school: '',
            err_user_name: '',
            err_password: '',

            errModal: false
        }
    },
    components: { CustomButton, CustomModal, CustomInput, ESelect },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        handleSearch: Function
    },

    watch: {
        addNewVisible(newVal) {
            if (newVal === true) {
                this.get_list_school()
            }
        }
    },
    methods: {
        async get_list_school() {
            this.school = ''

            const request_data = {
                capHoc: this.grade_level ? [this.grade_level] : [],
                maDonVi: this.unit_education ? [this.unit_education] : []
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        handleClearData() {
            this.errModal = false
            this.unit_education = ''
            this.grade_level = ''
            this.password = ''
            this.err_password = ''

            this.user_name = ''
            this.err_user_name = ''

            this.school = ''
            this.err_school = ''
        },
        handleCloseModalAddNew() {
            this.closeModalAddNew()
            this.handleClearData()
        },
        onChangeHandler(source, newValue) {
            const noValidate = ['grade_level', 'unit_education']

            if (noValidate.includes(source) && newValue.length > 0) {
                this.errModal = true
            } else {
                this.errModal = false
            }
            const errorFields = {
                school: 'err_school',
                user_name: 'err_user_name',
                password: 'err_password'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        async handleAddNew() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.school,
                        errorField: 'err_school'
                    },
                    {
                        value: this.password,
                        errorField: 'err_password'
                    },

                    {
                        value: this.user_name,
                        errorField: 'err_user_name'
                    }
                ]

                if (validateFields(this, fieldsToValidate)) {
                    const request_data = {
                        maTruong: this.school,
                        matKhau: this.password,
                        tenDangNhap: this.user_name
                    }

                    const response = await sendRequest(
                        Api.ket_chuyen_du_lieu_len_bo.mat_khau_ket_chuyen
                            .them_moi,
                        request_data,
                        this.request_headers
                    )
                    if (response.code == 200) {
                        showNotication(this, 'success', response.message)

                        this.handleCloseModalAddNew()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.message)
                    }
                }
            } catch (error) {
                if (error.response?.data.rd) {
                    showNotication(this, 'error', error.response?.data.rd)
                }
            }
        }
    },
    mounted() {},
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_grade_level() {
            return getFromSessionStorage('list_grade_level')
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
