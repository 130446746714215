<template>
    <LayoutCard>
        <div class="row">
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Trình độ chính </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_column_chart_educational_interpreter
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                customColorStackedColumnChart(
                                    column_chart_educational_interpreter
                                )
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_educational_interpreter
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Giới tính </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="handleRefresh_column_chart_gender_teacher"
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                customColorStackedColumnChart(
                                    column_chart_gender_teacher
                                )
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_gender_teacher
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Loại hợp đồng </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="handleRefresh_column_chart_contact_type"
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                customColorStackedColumnChart(
                                    column_chart_contact_type
                                )
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_contact_type
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Độ tuổi </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="handleRefresh_column_chart_age"
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="column_chart_age"
                            :xaxis_categories="xaxisCategories.column_chart_age"
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Loại hình đào tạo </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="handleRefresh_column_chart_type_of_education"
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                column_chart_type_of_education
                            "
                            :xaxis_categories="
                                xaxisCategories.column_chart_type_of_education
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-4 mb-4">
                <Card>
                    <template v-slot:title> Trạng thái </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="handleRefresh_column_chart_status"
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="convert_column_chart_status"
                            :xaxis_categories="
                                xaxisCategories.column_chart_status_teacher
                            "
                        />
                    </template>
                </Card>
            </div>
        </div>
    </LayoutCard>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import 'element-ui/lib/theme-chalk/index.css'

import { customColorStackedColumnChart } from '@/utils'
import StackedColumnChart from '@/components/StackedColumnChart.vue'
import LayoutCard from '@/components/CardDashboard/LayoutCard'
import Card from '@/components/CardDashboard/Card'
export default {
    name: 'ChartTeacherClassification',
    components: {
        CustomButton,

        StackedColumnChart,
        LayoutCard,
        Card
    },
    data() {
        return {
            xaxisCategories: {
                column_chart_educational_interpreter: [
                    'Tiến sĩ',
                    'Thạc sĩ',
                    'Đại học',
                    'Cao đẳng',
                    'Trung cấp'
                ],
                column_chart_gender_teacher: ['Nam', 'Nữ'],
                column_chart_contact_type: [
                    'HĐkXĐ thời hạn',
                    'HĐXĐ thời hạn',
                    'HĐLĐ'
                ],
                column_chart_age: [
                    '<20',
                    '20-29',
                    '30-39',
                    '40-49',
                    '50-54',
                    '55-59',
                    '>=60'
                ],
                column_chart_type_of_education: [
                    'Công lập',
                    'Tư thục',
                    'Dân lập'
                ],
                column_chart_status_teacher: [
                    'Đang làm',
                    'Chuyển đến',
                    'Chuyển đi',
                    'Khác'
                ]
            },
            convert_column_chart_status: []
        }
    },
    props: {
        column_chart_educational_interpreter: {
            type: Array,
            required: true
        },
        refresh_column_chart_educational_interpreter: Function,
        column_chart_gender_teacher: {
            type: Array,
            required: true
        },
        refresh_column_chart_gender_teacher: Function,
        column_chart_contact_type: {
            type: Array,
            required: true
        },
        refresh_column_chart_contact_type: Function,
        column_chart_age: {
            type: Array,
            required: true
        },
        refresh_column_chart_age: Function,

        column_chart_type_of_education: {
            type: Array,
            required: true
        },
        refresh_column_chart_type_of_education: Function,
        column_chart_status_teacher: {
            type: Array,
            required: true
        },
        refresh_column_chart_status: Function
    },
    watch: {
        column_chart_status_teacher(newVal) {
            this.convert_column_chart_status = newVal.map((item) => {
                item.data = item.data.slice(0, 4)
                return item
            })
        }
    },
    methods: {
        customColorStackedColumnChart,
        handleRefresh_column_chart_educational_interpreter() {
            this.$emit('refresh_column_chart_educational_interpreter')
        },
        handleRefresh_column_chart_gender_teacher() {
            this.$emit('refresh_column_chart_gender_teacher')
        },
        handleRefresh_column_chart_contact_type() {
            this.$emit('refresh_column_chart_contact_type')
        },
        handleRefresh_column_chart_age() {
            this.$emit('refresh_column_chart_age')
        },
        handleRefresh_column_chart_type_of_education() {
            this.$emit('refresh_column_chart_type_of_education')
        },
        handleRefresh_column_chart_status() {
            this.$emit('refresh_column_chart_status')
        }
    }
}
</script>

<style scoped></style>
