<template>
    <div>
        <ToolBar :menu="'Hệ thống'" :content="'Quản lý người dùng'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    @click="openModalAddNew"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="updatedColumnForm">
            <div class="row">
                <div class="col-md-2" v-if="isRoleSuperAdmin">
                    <div class="label-wrapper">
                        <label class="control-label">Sở giáo dục:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="isRoleSuperAdmin">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_province"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="province"
                        @change="onChangeProvince"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Phòng giáo dục:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="isRoleEducationOffice"
                        :data="list_education_department"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="education_department"
                    />
                </div>

                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Vai trò:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_role"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="role"
                    />
                </div>

                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn '"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>

                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Tên tài khoản:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="account_name"> </CustomInput>
                </div>

                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Tên đăng nhập:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="user_name"> </CustomInput>
                </div>

                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Email:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="email" :trimAll="true"> </CustomInput>
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="updatedColumnForm" :hide-btn-table="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>

                    <th>Tên tài khoản</th>
                    <th>Tên đăng nhập</th>
                    <th>Email</th>
                    <th>Vai trò</th>
                    <th>Loại tài khoản</th>
                    <th>Trạng thái</th>
                    <th>Thao tác</th>
                </tr>
            </template>
            <template v-slot:body>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>

                    <td class="text-left">{{ item.hoTen }}</td>
                    <td class="text-left">{{ item.username }}</td>
                    <td class="text-left">{{ item.email }}</td>
                    <td class="text-left">
                        {{ getRole(item.role) }}
                    </td>
                    <td class="text-left">
                        {{
                            item.isSso === true
                                ? 'Tài khoản SSO'
                                : ' Tài khoản thường'
                        }}
                    </td>
                    <td>
                        <TextStatus
                            :content="
                                item.trangThai === 1
                                    ? 'Đang hoạt động'
                                    : 'Ngưng hoạt động'
                            "
                            :status="item.trangThai"
                        />
                    </td>
                    <td class="text-left">
                        <span v-if="item.isSso === true">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="'Tài khoản sso. Không thể thay đổi.'"
                                placement="left"
                            >
                                <img :src="iconEdit" class="icon icon-dislabel" />
                            </el-tooltip>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="'Tài khoản sso. Không thể thay đổi.'"
                                placement="top"
                            >
                                <img :src="iconLock" class="icon icon-dislabel" />
                            </el-tooltip>

                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="'Tài khoản sso. Không thể thay đổi.'"
                                placement="right-start"
                            >
                                <img :src="iconKey" class="'icon icon-dislabel" />
                            </el-tooltip>
                        </span>

                        <span v-else>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="getTooltipEdit(item)"
                                placement="left"
                            >
                                <img
                                    :src="iconEdit"
                                    :class="[
                                        'icon',
                                        { 'icon-dislabel': isDisabled(item) }
                                    ]"
                                    @click="openModalUpdate(item)"
                                />
                            </el-tooltip>

                            <el-tooltip
                                v-if="item.trangThai == 1"
                                class="item"
                                effect="dark"
                                :content="getTooltipStatus(item)"
                                placement="top"
                            >
                                <img
                                    :src="iconLock"
                                    @click="openModalStatus(item)"
                                    :class="[
                                        'icon',
                                        { 'icon-dislabel': isDisabled(item) }
                                    ]"
                                />
                            </el-tooltip>
                            <el-tooltip
                                v-if="item.trangThai == 0"
                                class="item"
                                effect="dark"
                                content="Mở Khóa"
                                placement="top"
                            >
                                <img
                                    :src="iconUnLock"
                                    @click="openModalStatus(item)"
                                    :class="[
                                        'icon',
                                        { 'icon-dislabel': isDisabled(item) }
                                    ]"
                                />
                            </el-tooltip>

                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="getTooltipKey(item)"
                                placement="right-start"
                            >
                                <img
                                    :src="iconKey"
                                    @click="openModalKey(item)"
                                    :class="[
                                        'icon',
                                        { 'icon-dislabel': isDisabled(item) }
                                    ]"
                                />
                            </el-tooltip>
                        </span>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="statusVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalStatus"
            :size="'sm'"
        >
            <template v-slot:content>
                Xác nhận thay đổi trạng thái tài khoản ?
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleStatus"
                />
            </template>
        </CustomModal>
        <ModalAddNew
            :closeModalAddNew="closeModalAddNew"
            :addNewVisible="addNewVisible"
            @handleSearch="handleSearch"
            :list_role="list_role"
            :list_education_department="list_education_department"
        />

        <ModalUpdate
            :closeModalUpdate="closeModalUpdate"
            :updateVisible="updateVisible"
            @handleSearch="handleSearch"
            :dataUpdate="dataUpdate"
            :list_role="list_role"
            :list_education_department="list_education_department"
        />
        <ModalKey
            :closeModalKey="closeModalKey"
            :keyVisible="keyVisible"
            @handleSearch="handleSearch"
            :dataKey="dataKey"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import ModalAddNew from './modal_add.vue'
import ModalUpdate from './modal_update.vue'
import ModalKey from './modal_key.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import { list_status } from '@/mock_data/quan-ly-nguoi-dung'
import CustomInput from '@/components/CustomInput.vue'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconLock from '@/assets/img/icon-table/lock.svg'
import iconUnLock from '@/assets/img/icon-table/un-lock.svg'
import iconKey from '@/assets/img/icon-table/key.svg'
import CustomModal from '@/components/CustomModal.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
import TextStatus from '@/components/TextStatus.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'UserManager',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        ModalAddNew,
        ModalUpdate,
        ModalKey,
        CustomPagination,
        CustomInput,
        CustomModal,
        TextStatus
    },
    data() {
        return {
            iconEdit,
            iconLock,
            iconUnLock,
            iconKey,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            province: '',

            list_education_department: [],
            education_department: '',

            list_role: [],
            role: '',

            list_status: list_status,
            status: '',

            account_name: '',
            user_name: '',
            email: '',

            tableData: [],
            addNewVisible: false,

            statusVisible: false,
            dataStatus: {},

            keyVisible: false,
            dataKey: {},

            updateVisible: false,
            dataUpdate: {},

            column_form: 2
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalUpdate(item) {
            if (this.isDisabled(item)) {
                return
            }
            this.dataUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
        },
        openModalStatus(item) {
            if (item.role === this.auth_user.role || this.isDisabled(item)) {
                return
            }
            this.dataStatus = item
            this.statusVisible = true
        },
        closeModalStatus() {
            this.statusVisible = false
        },
        openModalKey(item) {
            if (item.role === this.auth_user.role || this.isDisabled(item)) {
                return
            }
            this.dataKey = item
            this.keyVisible = true
        },
        closeModalKey() {
            this.keyVisible = false
        },
        handleOpenMsgSSO() {
            showNotication(
                this,
                'error',
                'Để thay đổi thông tin của tài khoản SSO vui lòng liên hệ với admin để được hỗ trợ.'
            )
        },
        getTooltip(item, action) {
            const tooltips = {
                edit: 'Chỉnh sửa',
                status: 'Khóa',
                key: 'Đổi mật khẩu'
            }

            return item.role !== this.auth_user.role
                ? tooltips[action]
                : 'Vai trò tương đương. Không được phép thực hiện'
        },
        isDisabled(item) {
            return item.role === this.auth_user.role
        },
        getTooltipEdit(item) {
            return this.getTooltip(item, 'edit')
        },
        getTooltipStatus(item) {
            return this.getTooltip(item, 'status')
        },
        getTooltipKey(item) {
            return this.getTooltip(item, 'key')
        },
        getRole(e) {
            if (e == 2) {
                return 'Admin sở - [2]'
            }

            const matchedRole = this.list_role.find((role) => role.value == e)

            return matchedRole ? matchedRole.label : ''
        },
        async handleStatus() {
            try {
                const request_data = {
                    username: this.dataStatus.username,
                    trangThai: this.dataStatus.trangThai ? 0 : 1
                }

                const response = await sendRequest(
                    Api.he_thong.quan_ly_nguoi_dung.thay_doi_trang_thai,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    this.closeModalStatus()
                    await this.handleSearch()

                    showNotication(this, 'success', response.rd)
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        },
        onChangeProvince() {
            this.education_department = ''
            this.get_list_education_department()
        },
        async get_list_education_department() {
            const request_params = {
                limit: 9999,
                loaiDonVi: 2,
                maTinhThanh: this.province ? this.province : this.auth_user.ma_so,
                start: 0
            }
            const response = await sendRequest(
                Api.internal_api.dm_DonVi,
                null,
                this.request_headers,
                request_params
            )
            this.list_education_department = convertListData(
                response.rows,
                'tenDonVi',
                'maDonVi'
            )
        },
        async get_list_role() {
            const request_data = {}
            const response = await sendRequest(
                Api.he_thong.quan_ly_nguoi_dung.vai_tro,
                request_data,
                this.request_headers
            )
            this.list_role = convertListData(response, 'roleName', 'roleId')
        },
        async handleSearch() {
            try {
                const request_data = {
                    email: this.email,
                    hoTen: this.account_name,
                    limit: this.limit,
                    maDonVi: this.auth_user.ma_so,
                    maPhong: this.education_department,
                    role: this.role,
                    start: this.start,
                    trangThai: this.status,
                    username: this.user_name
                }

                const response = await sendRequest(
                    Api.he_thong.quan_ly_nguoi_dung.danh_sach_nguoi_dung,
                    request_data,
                    this.request_headers,
                    null
                )

                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                } else if (response.rc !== 0) {
                    showNotication(this, 'error', response.rd)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData
            } catch (error) {
                console.log(error)
            }
        }
    },
    watch: {
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOffice) {
                    this.education_department = user.ma_phong
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.get_list_education_department()
        this.get_list_role()
        this.handleSearch()
    },

    computed: {
        list_province() {
            return getFromSessionStorage('list_province')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOffice() {
            return checkUserRole(this.auth_user, ROLES.EducationOffice)
        },
        isRoleSuperAdmin() {
            return checkUserRole(this.auth_user, ROLES.SuperAdmin)
        },
        updatedColumnForm() {
            return this.isRoleSuperAdmin ? 3 : 2
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
