<template>
    <div>
        <ToolBar :menu="'Hệ thống'" :content="'Giải thể trường học'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Nhóm cấp học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_grade_group"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="grade_group"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Mã trường:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="school_code"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Tên trường:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="school_name"
                        placeholder="Nhập ..."
                        :disabled="false"
                        :clearable="false"
                        :show-password="false"
                        :showCloseIcon="true"
                        size="small"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Loại hình đào tạo:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_type_of_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type_of_education"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="2" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>Thao tác</th>
                    <th>Mã trường học</th>
                    <th>Tên trường học</th>
                    <th>Phòng GD&ĐT</th>
                    <th>Hiệu trưởng</th>
                    <th>ĐT hiệu trưởng</th>
                    <th>Loại hình đào tạo</th>
                    <th>Loại hình trường</th>
                    <th>Chuẩn quốc gia mức độ</th>
                    <th>Trạng thái</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="10">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td class="text-left">
                        <span v-if="item.trangThai !== -1">
                            <CustomButton
                                label="Giải thể"
                                @click="openModalDissolveSchools(item)"
                            />
                        </span>
                    </td>
                    <td
                        class="text-left text-navigate"
                        @click="navigateViewLockUpSchool(item)"
                    >
                        {{ item.maTruongHoc }}
                    </td>
                    <td
                        class="text-left text-navigate"
                        @click="navigateViewLockUpSchool(item)"
                    >
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-left">
                        {{ item.tenDonVi }}
                    </td>
                    <td class="text-left">
                        {{ item.tenHieuTruong }}
                    </td>

                    <td class="text-left">
                        {{ item.sdtHieuTruong }}
                    </td>

                    <td class="text-left">
                        {{
                            getLabelByArray(
                                list_type_of_education,
                                item.loaiHinhDaoTao
                            )
                        }}
                    </td>

                    <td class="text-left">
                        {{
                            getLabelByArray(list_school_type, item.loaiHinhTruong)
                        }}
                    </td>

                    <td class="text-left">
                        {{
                            getLabelByArray(
                                list_national_standard,
                                item.chuanQuocGia
                            )
                        }}
                    </td>

                    <td class="min-width">
                        <TextStatus
                            :content="
                                getLabelByArray(list_name_status, item.trangThai)
                            "
                            :status="item.trangThai"
                        />
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalDissolveSchools
            :dissolveSchoolsVisible="dissolveSchoolsVisible"
            :closeModalDissolveSchools="closeModalDissolveSchools"
            @handleSearch="handleSearch"
            :dataModalDissolveSchools="dataModalDissolveSchools"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { getLabelByArray } from '@/utils/index'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomInput from '@/components/CustomInput.vue'

import {
    list_grade_group,
    list_status,
    list_type_of_education,
    list_name_status,
    list_grade_level
} from '@/mock_data/tra-cuu-truong-hoc'

import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import TextStatus from '@/components/TextStatus.vue'
import { getFromSessionStorage } from '@/utils/session'
import ModalDissolveSchools from './modal_dissolve_schools.vue'
export default {
    name: 'LockUpSchool',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        TextStatus,
        CustomInput,
        CustomPagination,
        ModalDissolveSchools
    },
    data() {
        return {
            iconEdit,
            iconDelete,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_grade_group: list_grade_group,
            grade_group: '',

            list_status: list_status,
            status: '',

            school_code: '',
            school_name: '',

            list_type_of_education: list_type_of_education,
            type_of_education: [],

            list_name_status: list_name_status,
            list_grade_level: list_grade_level,

            tableData: [],

            dissolveSchoolsVisible: false,
            dataModalDissolveSchools: {}
        }
    },

    methods: {
        getLabelByArray,
        getNameGradeLevel(e) {
            if (!e) return ''
            const item = list_grade_level.find((option) => option.value === e)
            return item ? item.label : 'Cấp học ' + e
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        openModalDissolveSchools(item) {
            this.dataModalDissolveSchools = item
            this.dissolveSchoolsVisible = true
        },
        closeModalDissolveSchools() {
            this.dissolveSchoolsVisible = false
        },
        async buildRequestParams() {
            const caphoc = this.selectedGradeLevelFromStore[0] || ''
            return {
                caphoc,
                limit: this.limit,
                loaihinhdaotao: this.type_of_education,
                ma: this.school_code,
                maNhomCapHoc: this.grade_group,
                madonvi: this.unit_education,
                matinhthanh: this.auth_user.ma_so,
                start: this.start,
                ten: this.school_name,
                trangthai: this.status
            }
        },
        async handleSearch() {
            try {
                const request_params = await this.buildRequestParams()

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.danh_Sach,
                    null,
                    this.request_headers,
                    request_params
                )

                this.tableData = response.rows
                this.total_rows = response.total
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_school_type() {
            return getFromSessionStorage('DM_LOAI_HINH_TRUONG')
        },
        list_national_standard() {
            return getFromSessionStorage('DM_MUC_DAT_CHUAN_QG_CLGD')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    height: 15px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
.text-left {
    text-align: left !important;
}

.count {
    font-size: 14px;
    margin-right: 10px;
}
.min-width {
    min-width: 150px;
}
</style>
