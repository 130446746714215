<template>
    <div class="box-avatar">
        <div class="avatar">
            <el-avatar
                shape="square"
                :size="150"
                :src="computedAvatar"
                :fit="'cover'"
            >
            </el-avatar>
        </div>
        <div class="code">Mã: {{ code }}</div>
    </div>
</template>

<script>
export default {
    name: 'ComponentAvatar',
    props: {
        avatar: {
            type: String
        },
        code: {
            type: String
        }
    },
    data() {
        return {}
    },
    computed: {
        computedAvatar() {
            return this.avatar && this.avatar !== '' && this.avatar !== null
                ? this.avatar
                : 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
        }
    }
}
</script>

<style scoped>
.box-avatar {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.avatar {
    text-align: center;
    padding: 10px 5px 5px 5px;
}
.code {
    color: var(--blue-100);
    text-align: center;
}
</style>
