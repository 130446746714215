<template>
    <div>
        <ToolBar
            :menu="'Kết chuyển dữ liệu lên Bộ'"
            :content="'Lịch sử đẩy dữ liệu lên IOC'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool()"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool()"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Mã trường bổ sung
                            <CustomToolTip
                                content="Nhập danh sách mã trường, các mã trường được ngăn cách với nhau bằng dấu phẩy (,)."
                            >
                                <i class="el-icon-info blue"></i>
                            </CustomToolTip>
                            :
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="input_school" :type="'textarea'">
                    </CustomInput>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Loại dữ liệu:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_data_type"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="data_type"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Mã lần đẩy:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <CustomInput v-model="messageId"> </CustomInput>
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="2" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th class="min-w-150">Kết quả xử lý</th>
                    <th class="min-w-200">Tên trường</th>

                    <th class="min-w-150">Mã trường</th>
                    <th class="min-w-150">Cấp học</th>
                    <th class="min-w-150">Năm học</th>
                    <th class="min-w-150">Loại dữ liệu</th>
                    <th class="min-w-150">Thời gian đẩy</th>
                    <th class="min-w-150">Mã lần đẩy</th>
                    <th class="min-w-150">Trạng thái</th>
                    <th class="min-w-400">Mã lỗi và Mô tả</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="20">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>
                        <CustomToolTip content="Xem file">
                            <img
                                :src="iconView"
                                class="icon"
                                @click="openModalView(item)"
                            />
                        </CustomToolTip>

                        <CustomToolTip content="Tải file">
                            <img
                                :src="iconDownLoad"
                                class="custom-icon"
                                style=""
                                @click="openModalExportExcel(item)"
                            />
                        </CustomToolTip>
                    </td>
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>{{ item.maTruongHoc }}</td>
                    <td>{{ item.capHoc }}</td>
                    <td>{{ item.namHoc }}</td>
                    <td class="text-left">{{ item.type }}</td>
                    <td class="text-left">
                        {{ formatDateToHHMMSS(item.createdTime) }}
                    </td>
                    <td class="text-left">{{ item.messageId }}</td>
                    <td class="text-left">{{ item.responseDescription }}</td>

                    <td class="text-left">
                        <span v-if="item.error">
                            {{ item.error }} /
                            {{ item.errorDescription }}
                        </span>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>

        <ModalView
            :viewVisible="viewVisible"
            :closeModalView="closeModalView"
            :dataModalView="dataModalView"
        />

        <ModalExportExcel
            :exportExcelVisible="exportExcelVisible"
            :closeModalExportExcel="closeModalExportExcel"
            :dataExportExcel="dataExportExcel"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertListData,
    formatDateToHHMMSS,
    convertStringToArray
} from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import iconView from '@/assets/img/icon-table/view.svg'
import iconDownLoad from '@/assets/img/icon-table/download.svg'
import { list_data_type } from '@/mock_data/lich-su-day-du-lieu-len-ioc'
import ModalView from './modal_view.vue'
import ModalExportExcel from './modal_export_excel.vue'

import { isRoleEducationOfficeOrSchool, isRoleSchool } from '@/constants/Roles'
import CustomToolTip from '@/components/CustomToolTip.vue'

export default {
    name: 'MoetTransferHistoryIOC',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        CustomInput,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomToolTip,
        ModalView,
        ModalExportExcel
    },
    data() {
        return {
            iconView,
            iconDownLoad,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            list_data_type: list_data_type.slice(0, 5),
            data_type: '',
            unit_education: [],
            list_school: [],
            school: [],

            input_school: '',

            messageId: null,

            tableData: [],

            viewVisible: false,
            dataModalView: {},

            exportExcelVisible: false,
            dataExportExcel: {}
        }
    },

    methods: {
        isRoleEducationOfficeOrSchool,
        isRoleSchool,
        formatDateToHHMMSS,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_school() {
            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        openModalView(item) {
            this.handleSearch()
            this.dataModalView = item
            this.viewVisible = true
        },
        closeModalView() {
            this.viewVisible = false
        },
        openModalExportExcel(item) {
            this.dataExportExcel = item
            this.exportExcelVisible = true
        },
        closeModalExportExcel() {
            this.exportExcelVisible = false
        },
        processArrays(arr1, arr2) {
            // Tìm phần tử trùng lặp
            const commonElements = arr1.filter((element) =>
                arr2.includes(element)
            )

            if (commonElements.length > 0) {
                // Loại bỏ các phần tử trùng lặp trong mảng gộp
                const mergedArray = [...new Set([...arr1, ...arr2])]
                // Chỉ giữ một bản sao của các phần tử trùng
                return [...new Set([...commonElements, ...mergedArray])]
            } else {
                // Nếu không có phần tử trùng, gộp cả hai mảng
                return [...new Set([...arr1, ...arr2])]
            }
        },

        async handleSearch() {
            try {
                const list_input_school = convertStringToArray(this.input_school)
                const mergeSchool = this.processArrays(
                    list_input_school,
                    this.school
                )()
                const request_data = {
                    idPhongList: this.unit_education,
                    maCapHocs: this.selectedGradeLevelFromStore,
                    maTruongList: mergeSchool,
                    namHoc: this.selectedYearFromStore,

                    // loaiYeuCau: this.data_type,
                    idSo: this.auth_user.ma_so,
                    messageId: this.messageId,

                    start: this.start,
                    limit: this.limit
                }

                const response = await sendRequest(
                    Api.ket_chuyen_du_lieu_len_bo.lich_su_day_du_lieu_len_ioc
                        .danh_sach,
                    request_data,
                    this.request_headers
                )

                this.tableData = response.rows
                this.total_rows = response.total
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore: function (newVal) {
            if (newVal) {
                this.school = []
                this.get_list_school()
            }
        },

        auth_user: {
            handler(user) {
                if (isRoleEducationOfficeOrSchool()) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (isRoleSchool()) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },

        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.custom-icon {
    height: 20px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
</style>
