<template>
    <div>
        <CustomModal
            :is-modal-visible="downloadTemplateVisible"
            :modal-title="'Mẫu có sẵn'"
            :close-function="closeModalDownloadTemplate"
            :size="'xl'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                <CustomTable
                    :table-modal="true"
                    :hide-pagi="true"
                    :hideBtnTable="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>
                            <th>Thao tác</th>
                            <th>Tên mẫu</th>
                            <th>Mã</th>
                            <th>Thời gian tạo</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <template v-if="tableData === 0">
                            <tr>
                                <td colspan="3">Không có bản ghi nào.</td>
                            </tr>
                        </template>
                        <tr v-for="(item, index) in tableData" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <CustomToolTip content="Áp dụng">
                                    <img
                                        :src="iconCheck"
                                        class="icon"
                                        @click="handleApply(item)"
                                    />
                                </CustomToolTip>
                                <CustomToolTip content="Xóa">
                                    <img
                                        :src="iconDelete"
                                        class="icon"
                                        @click="openModalDelete(item)"
                                    />
                                </CustomToolTip>
                            </td>
                            <td class="text-left">{{ item.ten }}</td>
                            <td>{{ item.id }}</td>
                            <td>
                                {{ item.timeCreate }}
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </template>

            <template v-slot:btn-handle>
                <CustomButton label="Lưu" :btnSaveModal="true" />
            </template>
        </CustomModal>
        <CustomModal
            :centered="true"
            :is-modal-visible="deleteVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDelete"
            :size="'sm'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                Xóa mẫu báo cáo <b>{{ dataDelete.ten }}</b> đã chọn?
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="handleDelete"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import CustomTable from '@/components/CustomTable.vue'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import iconCheck from '@/assets/img/icon-table/check.svg'
import CustomToolTip from '@/components/CustomToolTip.vue'
import { mapMutations } from 'vuex'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalDownloadTemplate',
    data() {
        return {
            iconDelete,
            iconCheck,
            errModal: false,
            tableData: [],

            deleteVisible: false,
            dataDelete: {}
        }
    },
    components: { CustomButton, CustomModal, CustomTable, CustomToolTip },
    props: {
        downloadTemplateVisible: {
            type: Boolean,
            required: true
        },

        closeModalDownloadTemplate: Function,
        handleSearch: Function
    },
    watch: {
        downloadTemplateVisible(newVal) {
            if (newVal === true) {
                this.get_data_template()
            }
        }
    },

    methods: {
        ...mapMutations('dynamicReport', ['setcontentDisplayStudentReport']),
        openModalDelete(item) {
            this.dataDelete = item
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        async handleDelete() {
            try {
                const request_Data = {}

                const response = await sendRequest(
                    Api.bao_cao.bao_cao_dong.bao_cao_hoc_sinh.xoa_mau(
                        this.dataDelete.id
                    ),
                    request_Data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    this.get_data_template()
                    this.closeModalDelete()
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        },
        handleApply(item) {
            const request = JSON.parse(item.request)

            const colList = request.colList
            this.setcontentDisplayStudentReport(colList)
            this.closeModalDownloadTemplate()
        },
        async get_data_template() {
            try {
                const request_data = {
                    name: '',
                    type: 1
                }
                const response = await sendRequest(
                    Api.bao_cao.bao_cao_dong.bao_cao_hoc_sinh.tai_mau,
                    request_data,
                    this.request_headers
                )

                this.tableData = response.rows.slice(0, 10)
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.icon {
    height: 15px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
</style>
