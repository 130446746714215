<template>
    <div>
        <CustomModal
            :is-modal-visible="contentDisplayedVisible"
            :modal-title="'Cấu hình nội dung hiển thị'"
            :close-function="closeModalContentDisplayed"
            :size="'xl'"
            :err-modal="errModal"
            :scrollable="true"
        >
            <template v-slot:content>
                <div class="row">
                    <div class="col-md-9">
                        Chọn cột rồi di chuyển
                        <br />
                        Click chuột trái để chọn dòng để di chuyển Hoặc Giữ Ctrl +
                        Click chuột trái để chọn nhiều dòng để di chuyển
                        <br />
                        Bôi đen các cột muốn di chuyển kéo sang trái
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-5">
                        <label class="label">Cột chưa hiển thị</label>
                        <CustomInput
                            v-model="column_not_displayed"
                            placeholder=""
                            :showCloseIcon="true"
                            @input="searchColumnNotDisplayed"
                        >
                        </CustomInput>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-5">
                        <label class="label">Cột hiển thị</label>
                        <CustomInput
                            v-model="column_displayed"
                            placeholder=""
                            :showCloseIcon="true"
                            @input="searchColumnDisplayed"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <draggable
                            class="list-group"
                            :list="filter_list_column_not_displayed"
                            group="people"
                        >
                            <div
                                class="list-group-item"
                                v-for="(
                                    element, index
                                ) in filter_list_column_not_displayed"
                                :key="index"
                            >
                                {{ element.name }}
                            </div>
                        </draggable>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-5">
                        <draggable
                            class="list-group"
                            :list="filter_list_column_displayed"
                            group="people"
                        >
                            <div
                                class="list-group-item"
                                v-for="(
                                    element, index
                                ) in filter_list_column_displayed"
                                :key="index"
                            >
                                {{ element.name }}
                            </div>
                        </draggable>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12">
                        <label class="label">Bảng mô phỏng</label>
                        <CustomTable
                            :column-form="1"
                            :hideBtnTable="true"
                            :hidePagi="true"
                            :tableModal="true"
                        >
                            <template v-slot:header>
                                <tr>
                                    <th
                                        v-for="(
                                            element, index
                                        ) in filter_list_column_displayed"
                                        :key="index"
                                    >
                                        {{ element.name }}
                                    </th>
                                </tr>
                            </template>
                        </CustomTable>
                    </div>
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Lưu"
                    :btnSaveModal="true"
                    @click="saveListColumnDisplayed"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import CustomButton from '@/components/CustomButton.vue'
import CustomModal from '@/components/CustomModal.vue'
import CustomInput from '@/components/CustomInput.vue'
import { list_report_criteria_school } from '@/mock_data/bao-cao-dong'
import CustomTable from '@/components/CustomTable.vue'
import { mapMutations } from 'vuex'
import { mapState } from 'vuex'
export default {
    name: 'ModalContentDisplay',
    components: {
        CustomButton,
        CustomModal,
        CustomInput,
        draggable,
        CustomTable
    },
    data() {
        return {
            column_not_displayed: '',
            list_column_not_displayed: list_report_criteria_school,
            filter_list_column_not_displayed: list_report_criteria_school,

            column_displayed: '',
            list_column_displayed: [
                { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true }
            ],
            filter_list_column_displayed: [
                { name: 'Năm học', nameCol: 'namHoc', index: 3, status: true }
            ],

            errModal: false
        }
    },
    props: {
        contentDisplayedVisible: {
            type: Boolean,
            required: true
        },

        closeModalContentDisplayed: Function
    },

    methods: {
        ...mapMutations('dynamicReport', ['setcontentDisplaySchoolReport']),
        saveListColumnDisplayed() {
            this.setcontentDisplaySchoolReport(this.filter_list_column_displayed)
            this.closeModalContentDisplayed()
        },
        searchColumnNotDisplayed() {
            this.filter_list_column_not_displayed =
                this.list_column_not_displayed.filter((column) =>
                    column.name
                        .toLowerCase()
                        .includes(this.column_not_displayed.toLowerCase())
                )
        },
        searchColumnDisplayed() {
            this.filter_list_column_displayed = this.list_column_displayed.filter(
                (column) =>
                    column.name
                        .toLowerCase()
                        .includes(this.column_displayed.toLowerCase())
            )
        }
    },
    watch: {
        contentDisplaySchoolReport(newVal) {
            if (newVal) {
                this.list_column_displayed = newVal
                this.filter_list_column_displayed = newVal
            }
        }
    },
    mounted() {},
    computed: {
        ...mapState('dynamicReport', {
            contentDisplaySchoolReport: (state) =>
                state.contentDisplaySchoolReport
        }),
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            }
        }
    }
}
</script>

<style scoped>
.list-group {
    min-height: 50px;
    padding: 5px;
    border: 1px solid gainsboro;
}

.list-group-item {
    cursor: move;
    display: inline-flex !important;
    padding: 4px 10px;
}
.list-group-item:hover {
    background: #186ab2;
    color: #ffff;
}

.list-group-item span {
    font-size: 13px;
    font-weight: 500;
}

.list-group-item i {
    cursor: pointer;
}
.label {
    font-weight: 600;
}
.horizontal-list {
    display: flex;
    flex-direction: row;
}
</style>
