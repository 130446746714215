<template>
    <el-date-picker
        :value="value"
        :type="type"
        :placeholder="placeholder"
        :size="size"
        format="dd/MM/yyyy"
        value-format="yyyy-MM-dd"
        @input="updateValue"
        :class="{ 'is-error': showError }"
        :style="'width:100%'"
        :disabled="disabled"
    >
    </el-date-picker>
</template>

<script>
export default {
    name: 'DatePicker',
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'DD/MM/YYYY'
        },
        size: {
            type: String,
            default: 'small'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'date'
        },
        showError: Boolean
    },
    data() {
        return {}
    },
    methods: {
        updateValue(newValue) {
            this.$emit('input', newValue)
        }
    }
}
</script>

<style scoped>
::v-deep .el-input__icon.el-icon-date {
    display: none !important;
}
</style>
