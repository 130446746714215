<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="addNewVisible"
        :modal-title="'Thêm mới điểm trường'"
        :close-function="handleCloseModalAddNew"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-sm-4">
                    <label>
                        {{ titleObject.school_name }}
                        <span class="error-label">*</span>
                    </label>
                    <div>
                        <CustomInput
                            v-model="school_name"
                            @input="onChangeHandler('school_name', $event)"
                            :textError="err_school_name"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>{{ titleObject.address }}</label>
                    <div>
                        <CustomInput
                            v-model="address"
                            @input="onChangeHandler('address', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>{{ titleObject.distance }}</label>
                    <div>
                        <CustomInput
                            v-model="distance"
                            :type="'Number'"
                            @input="onChangeHandler('distance', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-4">
                    <label>{{ titleObject.phone }}</label>
                    <div>
                        <CustomInput
                            v-model="phone"
                            @input="onChangeHandler('phone', $event)"
                            :type="'Number'"
                            :trimAll="true"
                        >
                        </CustomInput>
                    </div>
                </div>

                <div class="col-sm-4">
                    <label>{{ titleObject.vehicle }}</label>
                    <div>
                        <CustomInput
                            v-model="vehicle"
                            @input="onChangeHandler('vehicle', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>{{ titleObject.acreage }}</label>
                    <div>
                        <CustomInput
                            v-model="acreage"
                            @input="onChangeHandler('acreage', $event)"
                            :type="'Number'"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-4">
                    <label>
                        {{ titleObject.province }}
                        <span class="error-label">*</span>
                    </label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :disabled="true"
                            :data="list_province"
                            :multiple="false"
                            :fields="['label', 'value']"
                            v-model="province"
                            @input="onChangeHandler('province', $event)"
                            :class="{
                                'error-select': err_province.length > 0
                            }"
                        />
                        <span
                            v-if="err_province.length > 0"
                            class="text-error-select"
                        >
                            {{ err_province }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>
                        {{ titleObject.district }}
                        <span class="error-label">*</span>
                    </label>
                    <div>
                        <ESelect
                            style="width: 100%"
                            :disabled="false"
                            :data="list_district"
                            :multiple="false"
                            :fields="['label', 'value']"
                            v-model="district"
                            @input="onChangeHandler('district', $event)"
                            :class="{
                                'error-select': err_district.length > 0
                            }"
                        />
                        <span
                            v-if="err_district.length > 0"
                            class="text-error-select"
                        >
                            {{ err_district }}
                        </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>{{ titleObject.desc }}</label>
                    <div>
                        <CustomInput
                            v-model="desc"
                            @input="onChangeHandler('desc', $event)"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleAddNew"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import {
    validateFields,
    handlerError,
    updateChangedTitles
} from '@/utils/validator'
import { convertListData } from '@/utils'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalAdd',
    data() {
        return {
            titleObject: {
                school_name: 'Tên điểm trường',
                address: 'Địa chỉ',
                distance: 'Khoảng cách tới trường chính (km)',
                phone: 'Điện thoại',
                vehicle: 'Phương tiện đi lại',
                acreage: 'Diện tích (m2)',
                province: 'Tỉnh thành',
                district: 'Quận huyện',
                desc: 'Mô tả'
            },
            changedTitleObject: [],
            errModal: false,
            school_name: '',
            err_school_name: '',

            address: '',
            distance: '',
            phone: '',

            vehicle: '',
            acreage: '',

            province: '',
            err_province: '',

            list_district: [],
            district: '',
            err_district: '',

            desc: ''
        }
    },
    props: {
        addNewVisible: {
            type: Boolean,
            required: true
        },
        closeModalAddNew: Function,
        get_data_school_site: Function
    },
    watch: {
        addNewVisible(newValue) {
            if (newValue === true) {
                this.province = this.auth_user.ma_so
                if (this.province) {
                    this.get_list_district()
                }
            }
        }
    },
    components: { CustomButton, CustomModal, CustomInput, ESelect },
    methods: {
        onChangeHandler(source, newValue) {
            this.changedTitleObject = updateChangedTitles(
                this.titleObject,
                this.changedTitleObject,
                source,
                newValue
            )
            const errorFields = {
                school_name: 'err_school_name',
                province: 'err_province',
                district: 'err_district'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },

        async get_list_district() {
            try {
                const request_params = {
                    maTinhThanh: this.province,
                    check: true,
                    start: 0,
                    limit: 9999
                }
                const response = await sendRequest(
                    Api.app.quan_huyen,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_district = convertListData(
                    response.rows,
                    'tenQuanHuyen',
                    'maQuanHuyen'
                )
            } catch (error) {
                console.log(error)
            }
        },
        handleClearData() {
            this.errModal = false
            this.school_name = ''
            this.err_school_name = ''
            this.address = ''
            this.distance = ''
            this.phone = ''
            this.vehicle = ''
            this.acreage = ''
            this.province = ''
            this.err_province = ''
            this.district = ''
            this.err_district = ''
            this.desc = ''
        },
        handleCloseModalAddNew() {
            this.handleClearData()
            this.closeModalAddNew()
        },
        async handleAddNew() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.school_name,
                        errorField: 'err_school_name'
                    },
                    {
                        value: this.province,
                        errorField: 'err_province'
                    },
                    {
                        value: this.district,
                        errorField: 'err_district'
                    }
                ]
                if (validateFields(this, fieldsToValidate)) {
                    const request_data = {
                        maTruongHoc: this.$route.params.id,
                        namHoc: this.selectedYearFromStore,
                        tenDiemTruong: this.school_name,
                        diaChi: this.address,
                        khoangCach: this.distance,
                        dienTich: this.acreage,
                        dienThoai: this.phone,
                        phuongTien: this.vehicle,
                        tinhThanh: this.province,
                        quanHuyen: this.district,
                        moTa: this.desc
                    }

                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_truong_hoc.them_diem_truong,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)

                        this.$emit('get_data_school_site')
                        this.handleCloseModalAddNew()
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        },
        list_province() {
            return getFromSessionStorage('list_province')
        }
    }
}
</script>

<style scoped>
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
