<template>
    <div>
        <ToolBar
            :menu="'Tiếp nhận dữ liệu QLNT'"
            :content="'Hệ thống đối tác trường'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />

                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    @click="openModalAddNew"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="1">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isEducationOffice || isSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="get_list_school"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-4">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đối tác:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_partner"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="partner"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="1">
            <template v-slot:btn-table>
                <span class="count" v-if="isCheckedCount > 0">
                    <b>Đã chọn {{ isCheckedCount }} bản ghi</b>
                </span>
                <CustomButton
                    label="Xóa"
                    @click="openModalDeleteAll('delete')"
                    :type="'danger'"
                />
            </template>
            <template v-slot:header>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            v-model="selectAllChecked"
                            @change="handleSelectAllCheckbox"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                    <th>STT</th>

                    <th>Đơn vị quản lý</th>
                    <th>Tên trường</th>
                    <th>Mã trường</th>
                    <th>Đối tác</th>
                    <th>Thao tác</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckbox(item)"
                        />
                    </td>
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>

                    <td class="text-left">
                        {{ item.tenDonVi }}
                    </td>
                    <td class="text-left">
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-left">
                        {{ item.maTruongHoc }}
                    </td>

                    <td class="text-left">
                        {{ item.tenDoiTac }}
                    </td>
                    <td>
                        <CustomToolTip content="Chỉnh sửa">
                            <img
                                :src="iconEdit"
                                class="icon"
                                @click="openModalUpdate(item)"
                            />
                        </CustomToolTip>
                        <CustomToolTip content="Xóa">
                            <img
                                :src="iconDelete"
                                class="icon"
                                @click="openModalDeleteOne(item)"
                            />
                        </CustomToolTip>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalAddNew
            :addNewVisible="addNewVisible"
            :closeModalAddNew="closeModalAddNew"
            @handleSearch="handleSearch"
            :list_partner="list_partner"
        />
        <ModalUpdate
            :updateVisible="updateVisible"
            :closeModalUpdate="closeModalUpdate"
            @handleSearch="handleSearch"
            :list_partner="list_partner"
            :dataUpdate="dataUpdate"
        />
        <ModalDelete
            :deleteVisible="deleteVisible"
            :closeModalDelete="closeModalDelete"
            @handleSearch="handleSearch"
            :dataDelete="dataDelete"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomToolTip from '@/components/CustomToolTip.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import { convertListData, addIsCheckedProperty, countCheckedItems } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import ModalAddNew from './modal_add.vue'
import ModalUpdate from './modal_update.vue'
import ModalDelete from './modal_delete.vue'
import { ROLES } from '@/constants/Roles'
import { showNotication } from '@/utils/notification'
export default {
    name: 'RequestDataBySchool',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomToolTip,
        ModalAddNew,
        ModalUpdate,
        ModalDelete
    },
    data() {
        return {
            iconEdit,
            iconDelete,
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            isCheckedCount: 0,
            selectAllChecked: false,

            unit_education: [],
            list_school: [],
            school: [],
            list_partner: [],
            partner: '',

            tableData: [],

            deleteVisible: false,
            dataDelete: [],

            addNewVisible: false,

            updateVisible: false,
            dataUpdate: {}
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        resetCheckedTable() {
            this.tableData.forEach((item) => {
                item.isChecked = false
            })

            this.selectAllChecked = false
        },

        toggleCheckbox(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
        },
        handleSelectAllCheckbox() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked
            })
        },
        updateCheckedState(item) {
            this.selectAllChecked = false
            this.tableData.forEach((dataItem) => {
                dataItem.isChecked = dataItem.id === item.id
            })
        },
        openModalDeleteAll() {
            const dataDeleteAll = this.tableData.filter((item) => item.isChecked)
            if (dataDeleteAll.length === 0) {
                showNotication(this, 'error', 'Vui lòng chọn bản ghi')
                return
            }
            this.dataDelete = dataDeleteAll
            this.deleteVisible = true
        },
        openModalDeleteOne(item) {
            this.updateCheckedState(item)
            const dataDeleteOne = this.tableData.filter(
                (dataItem) => dataItem.isChecked
            )
            this.dataDelete = dataDeleteOne
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
            this.resetCheckedTable()
        },
        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalUpdate(item) {
            this.updateCheckedState(item)
            this.dataUpdate = item
            this.updateVisible = true
        },
        closeModalUpdate() {
            this.updateVisible = false
            this.resetCheckedTable()
        },
        async get_list_school() {
            this.school = []

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        async get_list_partner() {
            const request_params = {
                start: 0,
                limit: 9999
            }
            const response = await sendRequest(
                Api.tiep_nhan_du_lieu_QLNT.quan_ly_he_thong_doi_tac_truong
                    .danh_sach_doi_tac,
                null,
                this.request_headers,
                request_params
            )
            this.list_partner = convertListData(
                response.rows,
                'tenDoiTac',
                'maDoiTac'
            )
        },

        async handleSearch() {
            try {
                const request_Data = {
                    limit: this.limit,
                    maDoiTac: this.partner,
                    maDonVis: this.unit_education,
                    maTruongs: this.school,
                    start: this.start
                }

                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.quan_ly_he_thong_doi_tac_truong
                        .danh_sach,
                    request_Data,
                    this.request_headers
                )

                this.tableData = addIsCheckedProperty(response.rows)
                this.total_rows = response.total
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.handleSearch()
        this.get_list_school()
        this.get_list_partner()
    },
    watch: {
        selectedGradeLevelFromStore: function (newVal) {
            if (newVal) {
                this.get_list_school()
            }
        },
        tableData: {
            handler(newVal) {
                this.isCheckedCount = countCheckedItems(newVal)
            },
            deep: true
        },
        auth_user: {
            handler(user) {
                if (this.isEducationOffice) {
                    this.unit_education = this.isEducationOffice
                        ? [user.ma_phong]
                        : []
                }

                if (this.isSchool) {
                    this.unit_education = this.isEducationOffice
                        ? [user.ma_phong]
                        : []
                }
            },
            immediate: true
        }
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isEducationOffice() {
            return (
                this.auth_user &&
                this.auth_user.baseRole === ROLES.EducationOffice
            )
        },
        isSchool() {
            return this.auth_user && this.auth_user.baseRole === ROLES.School
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.text-left {
    text-align: left !important;
}

.count {
    font-size: 14px;
    margin-right: 10px;
}
.icon {
    height: 15px;
    cursor: pointer;
    padding: 0px 10px 0px 10px;
}
</style>
