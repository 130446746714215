<template>
    <div>
        <el-input
            :placeholder="disabled ? '' : placeholder"
            :value="value"
            :disabled="disabled"
            :clearable="clearable"
            :size="size"
            :autosize="autosize"
            :type="type"
            @input="updateValue"
            @blur="trimValue"
            :show-password="showPassword"
            v-bind="$attrs"
            :class="{ 'is-error': textError.length > 0 }"
        >
            <i
                v-if="
                    showCloseIcon === true &&
                    disabled === false &&
                    value &&
                    type !== 'textarea' &&
                    !clearable
                "
                @click="clearInput"
                slot="suffix"
                class="el-input__icon el-icon-circle-close icon-close"
            >
            </i>
        </el-input>
        <span v-if="textError.length > 0" class="text-error-input">
            {{ textError }}
        </span>
        <i
            v-if="
                showCloseIcon === true &&
                disabled === false &&
                value &&
                type === 'textarea'
            "
            @click="clearInput"
            class="el-input__icon el-icon-circle-close icon-close-textarea"
        >
        </i>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: {
            type: String,
            default: 'Nhập ...'
        },
        value: [String, Number],
        disabled: Boolean,
        clearable: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: 'small'
        },
        type: String,
        showPassword: Boolean,
        showCloseIcon: {
            type: Boolean,
            default: true
        },

        autosize: Object,

        textError: {
            type: String,
            default: ''
        },
        trimAll: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        updateValue(newValue) {
            this.$emit('input', newValue)
        },
        trimValue(event) {
            let value = event.target.value

            this.trimAll
                ? (value = value.replace(/\s+/g, ''))
                : (value = value.trim())

            if (value !== event.target.value) {
                this.$emit('input', value)
            }
        },
        clearInput() {
            this.$emit('input', '')
        }
    }
}
</script>

<style scoped>
.icon-close-textarea {
    color: #333;
    cursor: pointer;
}
.el-input {
    position: relative;
}

.el-input__icon.icon-close-textarea {
    top: 15px;
    position: absolute;

    transform: translateY(-50%);
    right: 15px;
    cursor: pointer;
}

::v-deep .el-icon-view:before {
    color: black;
    content: '\e6ce';
}
</style>
