<template>
    <div class="layout">
        <div class="left">
            Hiển thị
            <el-select
                class="select"
                size="small"
                v-model="soLuong"
                @change="onSizeChange"
                placeholder="Chọn"
            >
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            bản ghi/trang
        </div>

        <div class="right">
            <i class="records-info">
                Hiển thị từ
                <span>{{ start }} - {{ to }}</span>
                / <b>{{ formatNumber(total_rows) }} </b> bản ghi
            </i>
            <b-pagination
                class="pagination"
                :first-text="pagination.first_text"
                :limit="9"
                :last-text="pagination.last_text"
                :total-rows="total_rows"
                :prev-text="pagination.next_text"
                :next-text="pagination.prev_text"
                v-model="trangHienTai"
                @change="chonTrangHienThi"
                :per-page="pagination.limit"
                pills
            >
            </b-pagination>
        </div>
    </div>
</template>

<script>
import { PaginationPlugin } from 'bootstrap-vue'
import number_util from '@/utils/number_utils'
import Vue from 'vue'
Vue.use(PaginationPlugin)

export default {
    props: ['pageStart', 'tongbanghi', 'pageSize'],
    watch: {
        pageStart() {
            this.trangHienTai = 1
            this.start = 0
            this.onPageChange()
        },

        pageSize(newVal) {
            this.pagination.limit = newVal
            this.to = newVal
            this.trangHienTai = 1
            let ret = {
                start: 0,
                limit: this.pagination.limit,
                currentPage: this.trangHienTai
            }

            this.rebuild()
            this.$emit('pageChange', ret)
        },
        tongbanghi: function (newVal, oldVal) {
            this.total_rows = this.tongbanghi
            this.rebuild()
        }
    },

    data() {
        return {
            trangHienTai: 1,
            pagination: {
                next_text: '«',
                prev_text: '»',
                per_page: 10,
                first_text: 'Đầu',
                last_text: 'Cuối',
                limit: 25
            },
            start: 0,
            to: 25,
            total_rows: 0,

            soLuong: 25,
            options: [
                {
                    value: 25,
                    label: '25'
                },
                {
                    value: 50,
                    label: '50'
                },
                {
                    value: 100,
                    label: '100'
                },
                {
                    value: 200,
                    label: '200'
                },
                {
                    value: 500,
                    label: '500'
                },
                {
                    value: 1000,
                    label: '1000'
                }
            ],
            currentPage: 1
        }
    },

    mounted() {},

    methods: {
        chonTrangHienThi(number) {
            this.trangHienTai = number

            this.onPageChange()
        },

        formatNumber(num) {
            return number_util.formatNumberEx(num)
        },

        onPageChange() {
            let ret = {
                start: (this.trangHienTai - 1) * this.pagination.limit,
                limit: this.pagination.limit,
                currentPage: this.trangHienTai
            }
            this.rebuild()

            this.$emit('pageChange', ret)
        },
        rebuild() {
            this.start = (this.trangHienTai - 1) * this.pagination.limit + 1
            this.to = this.trangHienTai * this.pagination.limit

            if (this.trangHienTai * this.pagination.limit <= this.total_rows) {
                this.to = this.trangHienTai * this.pagination.limit
            } else {
                if (this.total_rows == 0) {
                    this.to = 0
                } else {
                    this.to = this.total_rows
                }
            }
        },
        onSizeChange() {
            this.currentPage = 1
            let ret = {
                soluong: this.soLuong
            }
            this.$emit('pageSizeChange', ret)
        }
    }
}
</script>

<style scoped>
.layout {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.right {
    display: flex;
    align-items: center;
}
.records-info {
    color: var(--blue-shared);
    margin-right: 10px;
}
.select {
    width: 75px !important;
    margin-right: 10px;
    margin-left: 10px;
}
.pagination {
    margin: 0;
}
::v-deep .b-pagination-pills .page-item .page-link {
    border-color: var(--gray-100) !important;
    color: var(--black-200);
}
::v-deep .page-item.active .page-link {
    color: var(--white-common);
    background: var(--gray-500);
}

@media (max-width: 768px) {
    .layout {
        flex-direction: column;
        align-items: flex-start;
    }

    .select {
        width: 100% !important;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    .records-info {
        margin-top: 10px;
        width: 100%;
        text-align: left;
    }
}
</style>
