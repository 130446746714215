l
<template>
    <div>
        <ToolBar :tab="true">
            <template v-slot:tab>
                <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                >
                    <el-menu-item
                        v-for="(item, index) in menuItems"
                        :key="index"
                        :index="index.toString()"
                        @click="handleMenuItemClick(index)"
                    >
                        {{ item.label }}
                    </el-menu-item>
                </el-menu>
            </template>
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Xuất Excel"
                    :icon="'el-icon-download'"
                    @click="handleExportExcel"
                />
                <CustomButton
                    label="Cấu hình cột hiển thị"
                    @click="openModalContentDisplayed"
                />
                <CustomButton label="Lưu mẫu" @click="openModalSaveTemplate" />
                <CustomButton
                    label="Tải mẫu"
                    @click="openModalDownloadTemplate"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tìm kiếm nâng cao:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_advanced_search_student"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['name', 'value']"
                        v-model="advanced_search"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('nhomMau')">
                    <div class="label-wrapper">
                        <label class="control-label">Nhóm máu:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('nhomMau')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_blood_type"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="blood_type"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('khuyetTat')">
                    <div class="label-wrapper">
                        <label class="control-label">Khuyết tật:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('khuyetTat')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="disability"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maDtuongCsach')">
                    <div class="label-wrapper">
                        <label class="control-label">Diện chính sách:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maDtuongCsach')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_policy_beneficiaries"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="policy_beneficiaries"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('tphanGdinh')">
                    <div class="label-wrapper">
                        <label class="control-label">Thành phần gia đình:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('tphanGdinh')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_family_composition"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="family_composition"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dtuongUuTien')">
                    <div class="label-wrapper">
                        <label class="control-label">Diện ưu tiên:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dtuongUuTien')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_priority_group"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="priority_group"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('doiVien')">
                    <div class="label-wrapper">
                        <label class="control-label">Đội viên:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('doiVien')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="teammate"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('doanVien')">
                    <div class="label-wrapper">
                        <label class="control-label">Đoàn viên:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('doanVien')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="union_member"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dangVien')">
                    <div class="label-wrapper">
                        <label class="control-label">Đảng viên:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dangVien')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="party_member"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maLoaiKhuyetTat')">
                    <div class="label-wrapper">
                        <label class="control-label"> Loại khuyết tật: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maLoaiKhuyetTat')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_type_of_disabilit"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type_of_disabilit"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maKhuVuc')">
                    <div class="label-wrapper">
                        <label class="control-label">Khu vực: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maKhuVuc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_area"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="area"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiNhapHoc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Loại nhập học: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiNhapHoc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_enrollment_type"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="enrollment_type"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hocKnsSkss')">
                    <div class="label-wrapper">
                        <label class="control-label"> Học kỹ năng skss: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hocKnsSkss')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="skill_learning_skss"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('khoiHoc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Khối học: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('khoiHoc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school_block"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school_block"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('trangThai')">
                    <div class="label-wrapper">
                        <label class="control-label"> Trạng thái: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('trangThai')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('htHoTro')">
                    <div class="label-wrapper">
                        <label class="control-label"> Hỗ trợ học tập: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('htHoTro')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_educational_support"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="educational_support"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('thoiDiemRoiTruong')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Thời điểm rời trường:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('thoiDiemRoiTruong')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_dropout_time"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="dropout_time"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('kiemTraSucKhoeDinhDuong')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Kiểm tra sức khỏe dinh dưỡng:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('kiemTraSucKhoeDinhDuong')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="nutritional_health_check"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('tinhTrangDinhDuong')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Tình trạng dinh dưỡng:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('tinhTrangDinhDuong')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_nutritional_status"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="nutritional_status"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('chaDanToc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Cha dân tộc: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('chaDanToc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="ethnic_father"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('meDanToc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Mẹ dân tộc: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('meDanToc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_yes_of_no"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="ethnic_mother"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maLyDoThoiHoc')">
                    <div class="label-wrapper">
                        <label class="control-label"> Lý do thôi học: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maLyDoThoiHoc')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_reason_for_dropout"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="reason_for_dropout"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhTiengDanToc')"
                >
                    <div class="label-wrapper">
                        <label class="control-label"> Học tiếng dân tộc: </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhTiengDanToc')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="ethnic_language_learning"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hocSongNgu')">
                    <div class="label-wrapper">
                        <label class="control-label"> Học song ngữ: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hocSongNgu')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="bilingual_education"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhBanTruDanNuoi')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học bán trú dân nuôi:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhBanTruDanNuoi')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="boarding_education_for_ethnic_minorities"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhNoiTruDanNuoi')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học nội trú dân nuôi:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhNoiTruDanNuoi')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="residential_education_for_ethnic_minorities"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('khuyetTatKhongDanhGia')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Khuyết tật không đánh giá:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('khuyetTatKhongDanhGia')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="disability_not_assessed"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hoNgheo')">
                    <div class="label-wrapper">
                        <label class="control-label"> Hộ nghèo: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hoNgheo')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="poor_household"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('htroNhaO')">
                    <div class="label-wrapper">
                        <label class="control-label"> Hỗ trợ nhà ở: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('htroNhaO')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="housing_support"
                    />
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('capTienHangThang')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Được cấp tiền hàng tháng:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('capTienHangThang')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="monthly_allowance_provided"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('capGao')">
                    <div class="label-wrapper">
                        <label class="control-label"> Được cấp gạo: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('capGao')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="rice_provided"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hocCtgdCuaBo')">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học chương trình GD của Bộ:
                        </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hocCtgdCuaBo')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="studying_ministry_of_education_curriculum"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('htChiPhiHocTap')">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Được hỗ trợ chi phí học tập:
                        </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('htChiPhiHocTap')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="educational_expense_support"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hocSinhBanTru')">
                    <div class="label-wrapper">
                        <label class="control-label"> Học sinh bán trú: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hocSinhBanTru')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="boarding_students"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hocSinhNoiTru')">
                    <div class="label-wrapper">
                        <label class="control-label"> Học sinh nội trú: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hocSinhNoiTru')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="residential_students"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhPtdtBanTru')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Học sinh PTDT bán trú:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('hocSinhPtdtBanTru')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="vocational_boarding_students"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('chuyenSangHocNghe')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Chuyển sang học nghề:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('chuyenSangHocNghe')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="transition_to_vocational_education"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('bietBoiKy1')">
                    <div class="label-wrapper">
                        <label class="control-label"> Biết bơi kỳ 1: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('bietBoiKy1')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="swim_test_1"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('bietBoiKy2')">
                    <div class="label-wrapper">
                        <label class="control-label"> Biết bơi kỳ 2: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('bietBoiKy2')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="swim_test_2"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('meBiBenhTatTrongTkMangThai')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Mẹ bị bệnh trong thời kỳ mang thai:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('meBiBenhTatTrongTkMangThai')"
                >
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="mother_had_illness_during_pregnancy"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('canThi')">
                    <div class="label-wrapper">
                        <label class="control-label"> Cận thị: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('canThi')">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_true_of_false"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="near_sightedness"
                    />
                </div>
                <div class="col-md-2" v-if="shouldShowElement('chiSoBMI')">
                    <div class="label-wrapper">
                        <label class="control-label"> Chỉ số BMI: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('chiSoBMI')">
                    <CustomInput
                        v-model="bmi"
                        placeholder=""
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="column_form" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th v-for="column in tableHeader" :key="column.key">
                        {{ column.label }}
                    </th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableRows.length === 0">
                    <tr>
                        <td colspan="211">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(row, index) in tableRows" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>
                    <td
                        class="text-left"
                        v-for="(col, colIndex) in row.col"
                        :key="colIndex"
                    >
                        {{ col.value }}
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalContentDisplayed
            :contentDisplayedVisible="contentDisplayedVisible"
            :closeModalContentDisplayed="closeModalContentDisplayed"
        />
        <ModalSaveTemplate
            :saveTemplateVisible="saveTemplateVisible"
            :closeModalSaveTemplate="closeModalSaveTemplate"
            :prepareRequestData="prepareRequestData"
        />
        <ModalDownloadTemplate
            :downloadTemplateVisible="downloadTemplateVisible"
            :closeModalDownloadTemplate="closeModalDownloadTemplate"
            :prepareRequestData="prepareRequestData"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertListData,
    calculateColumnFormLength,
    generateDataSearchList,
    convertDownloadFileExcel
} from '@/utils'
import ESelect from '@/components/ESelect.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomInput from '@/components/CustomInput.vue'
import ToolBar from '@/components/ToolBar.vue'
import {
    list_advanced_search_student,
    list_yes_of_no,
    list_true_of_false,
    list_status,
    list_dropout_time
} from '@/mock_data/bao-cao-dong'
import CustomTable from '@/components/CustomTable.vue'
import ModalContentDisplayed from './modal_content_displayed.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
import { mapState } from 'vuex'
import ModalSaveTemplate from './modal_save_template.vue'
import ModalDownloadTemplate from './modal_download_template.vue'
import { path } from '@/router/path'
export default {
    name: 'RePortStudent',

    components: {
        ToolBar,
        CustomButton,
        FormSearch,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomInput,
        ModalContentDisplayed,
        ModalSaveTemplate,
        ModalDownloadTemplate
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: [],

            list_school: [],
            school: [],

            list_advanced_search_student: list_advanced_search_student,
            advanced_search: [],

            blood_type: '',
            disability: '',
            policy_beneficiaries: '',
            family_composition: '',
            priority_group: '',
            teammate: '',
            union_member: '',
            party_member: '',
            type_of_disabilit: '',
            area: '',
            enrollment_type: '',
            skill_learning_skss: '',
            school_block: '',
            list_status: list_status,
            status: '',
            educational_support: '',
            list_dropout_time: list_dropout_time,
            dropout_time: '',
            nutritional_health_check: '',
            nutritional_status: '',
            ethnic_father: '',
            ethnic_mother: '',
            reason_for_dropout: '',
            ethnic_language_learning: '',
            bilingual_education: '',
            boarding_education_for_ethnic_minorities: '',
            residential_education_for_ethnic_minorities: '',
            disability_not_assessed: '',
            poor_household: '',
            housing_support: '',
            monthly_allowance_provided: '',
            rice_provided: '',
            studying_ministry_of_education_curriculum: '',
            educational_expense_support: '',
            boarding_students: '',
            residential_students: '',
            vocational_boarding_students: '',
            transition_to_vocational_education: '',
            swim_test_1: '',
            swim_test_2: '',
            mother_had_illness_during_pregnancy: '',
            near_sightedness: '',
            bmi: '',

            tableRows: [],
            tableHeader: [],

            list_true_of_false: list_true_of_false,
            list_yes_of_no: list_yes_of_no,

            activeIndex: '1',
            menuItems: [
                { label: 'Báo cáo cán bộ' },
                { label: 'Báo cáo học sinh' },
                { label: 'Báo cáo trường học' }
            ],

            column_form: 1,
            contentDisplayedVisible: false,
            saveTemplateVisible: false,
            downloadTemplateVisible: false
        }
    },
    methods: {
        shouldShowElement(element) {
            return this.advanced_search.includes(element)
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        handleMenuItemClick(index) {
            switch (index) {
                case 0:
                    this.$router.push({
                        path: path.report.dynamic_report.staff_report
                    })
                    break
                case 1:
                    this.$router.push({
                        path: path.report.dynamic_report.student_report
                    })
                    break
                case 2:
                    this.$router.push({
                        path: path.report.dynamic_report.school_report
                    })
                    break
                default:
            }
        },
        openModalContentDisplayed() {
            this.contentDisplayedVisible = true
        },
        closeModalContentDisplayed() {
            this.contentDisplayedVisible = false
        },
        openModalSaveTemplate() {
            this.saveTemplateVisible = true
        },
        closeModalSaveTemplate() {
            this.saveTemplateVisible = false
        },
        openModalDownloadTemplate() {
            this.downloadTemplateVisible = true
        },
        closeModalDownloadTemplate() {
            this.downloadTemplateVisible = false
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_school() {
            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        prepareRequestData() {
            const mappings = [
                {
                    key: 'nhomMau',
                    value: this.blood_type,
                    check: 'includes'
                },
                {
                    key: 'khuyetTat',
                    value: this.disability,
                    check: 'includes'
                },
                {
                    key: 'maDtuongCsach',
                    value: this.policy_beneficiaries,
                    check: 'includes'
                },
                {
                    key: 'tphanGdinh',
                    value: this.family_composition,
                    check: 'includes'
                },
                {
                    key: 'dtuongUuTien',
                    value: this.staff_group,
                    check: 'includes'
                },
                {
                    key: 'doiVien',
                    value: this.teammate,
                    check: 'includes'
                },
                {
                    key: 'doanVien',
                    value: this.union_member,
                    check: 'includes'
                },
                {
                    key: 'dangVien',
                    value: this.party_member,
                    check: 'includes'
                },
                {
                    key: 'maLoaiKhuyetTat',
                    value: this.type_of_disabilit,
                    check: 'includes'
                },

                {
                    key: 'maKhuVuc',
                    value: this.area,
                    check: 'includes'
                },
                {
                    key: 'loaiNhapHoc',
                    value: this.enrollment_type,
                    check: 'includes'
                },
                {
                    key: 'hocKnsSkss',
                    value: this.skill_learning_skss,
                    check: 'includes'
                },
                {
                    key: 'khoiHoc',
                    value: this.school_block,
                    check: 'includes'
                },
                {
                    key: 'trangThai',
                    value: this.status,
                    check: 'includes'
                },
                {
                    key: 'htHoTro',
                    value: this.educational_support,
                    check: 'includes'
                },
                {
                    key: 'thoiDiemRoiTruong',
                    value: this.dropout_time,
                    check: 'includes'
                },
                {
                    key: 'kiemTraSucKhoeDinhDuong',
                    value: this.nutritional_health_check,
                    check: 'includes'
                },
                {
                    key: 'tinhTrangDinhDuong',
                    value: this.nutritional_status,
                    check: 'includes'
                },
                {
                    key: 'chaDanToc',
                    value: this.ethnic_father,
                    check: 'includes'
                },
                {
                    key: 'meDanToc',
                    value: this.ethnic_mother,
                    check: 'includes'
                },
                {
                    key: 'maLyDoThoiHoc',
                    value: this.reason_for_dropout,
                    check: 'includes'
                },
                {
                    key: 'hocSinhTiengDanToc',
                    value: this.ethnic_language_learning,
                    check: 'includes'
                },
                {
                    key: 'hocSongNgu',
                    value: this.bilingual_education,
                    check: 'includes'
                },
                {
                    key: 'hocSinhBanTruDanNuoi',
                    value: this.boarding_education_for_ethnic_minorities,
                    check: 'includes'
                },
                {
                    key: 'hocSinhNoiTruDanNuoi',
                    value: this.residential_education_for_ethnic_minorities,
                    check: 'includes'
                },
                {
                    key: 'khuyetTatKhongDanhGia',
                    value: this.disability_not_assessed,
                    check: 'includes'
                },
                {
                    key: 'hoNgheo',
                    value: this.poor_household,
                    check: 'includes'
                },
                {
                    key: 'htroNhaO',
                    value: this.housing_support,
                    check: 'includes'
                },
                {
                    key: 'capTienHangThang',
                    value: this.monthly_allowance_provided,
                    check: 'includes'
                },
                {
                    key: 'capGao',
                    value: this.rice_provided,
                    check: 'includes'
                },
                {
                    key: 'hocCtgdCuaBo',
                    value: this.studying_ministry_of_education_curriculum,
                    check: 'includes'
                },
                {
                    key: 'htChiPhiHocTap',
                    value: this.educational_expense_support,
                    check: 'includes'
                },
                {
                    key: 'hocSinhBanTru',
                    value: this.boarding_students,
                    check: 'includes'
                },
                {
                    key: 'hocSinhNoiTru',
                    value: this.residential_students,
                    check: 'includes'
                },
                {
                    key: 'hocSinhPtdtBanTru',
                    value: this.vocational_boarding_students,
                    check: 'includes'
                },
                {
                    key: 'chuyenSangHocNghe',
                    value: this.transition_to_vocational_education,
                    check: 'includes'
                },
                {
                    key: 'bietBoiKy1',
                    value: this.swim_test_1,
                    check: 'includes'
                },
                {
                    key: 'bietBoiKy2',
                    value: this.swim_test_2,
                    check: 'includes'
                },
                {
                    key: 'meBiBenhTatTrongTkMangThai',
                    value: this.mother_had_illness_during_pregnancy,
                    check: 'includes'
                },
                {
                    key: 'canThi',
                    value: this.near_sightedness,
                    check: 'includes'
                },
                {
                    key: 'chiSoBMI',
                    value: this.bmi,
                    check: 'includes'
                }
            ]

            const requestLists = generateDataSearchList(
                this.advanced_search,
                mappings
            )
            const requestList = [
                {
                    name: 'Năm học',
                    nameSreach: 'namHoc',
                    value: this.selectedYearFromStore
                }
            ]
            const colList = this.contentDisplayStudentReport
            return {
                start: this.start,
                limit: this.limit,
                requestList: requestList,
                requestLists: requestLists,
                colList: colList
            }
        },
        async handleSearch() {
            try {
                const request_data = this.prepareRequestData()
                const response = await sendRequest(
                    Api.bao_cao.bao_cao_dong.bao_cao_hoc_sinh.danh_sach,
                    request_data,
                    this.request_headers
                )
                this.tableRows = response.tableRows
                this.tableHeader = response.tableHeaders.map((item) => ({
                    ...item,
                    key: item.index,
                    label: item.value
                }))
                this.total_rows = response.total
            } catch (error) {
                console.log(error)
            }
        },
        async handleExportExcel() {
            try {
                const domain = process.env.VUE_APP_BACKEND_BASE_URL
                const request_data = this.prepareRequestData()
                const apiUrl = `${domain}/${Api.bao_cao.bao_cao_dong.bao_cao_hoc_sinh.xuat_excel.url}`
                const fileName = 'bao_cao_hoc_sinh.xlsx'
                const authToken = this.auth_user.token

                convertDownloadFileExcel(
                    apiUrl,
                    request_data,
                    fileName,
                    authToken
                )
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_list_school()
        this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore: {
            handler(newVal) {
                if (newVal) {
                    this.get_list_school()
                }
            },
            immediate: true
        },
        advanced_search(newVal, oldVal) {
            this.column_form = calculateColumnFormLength(newVal.length, 1, 13)
        },
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        },
        contentDisplayStudentReport(newVal) {
            if (newVal) {
                this.handleSearch()
            }
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_blood_type() {
            return getFromSessionStorage('DM_NHOM_MAU')
        },
        list_policy_beneficiaries() {
            return getFromSessionStorage('DM_DOI_TUONG_CHINH_SACH')
        },
        list_family_composition() {
            return getFromSessionStorage('THANH_PHAN_GIA_DINH')
        },
        list_priority_group() {
            return getFromSessionStorage('DM_VUNG_KHO_KHAN')
        },
        list_type_of_disabilit() {
            return getFromSessionStorage('DM_LOAI_KHUYET_TAT')
        },
        list_area() {
            return getFromSessionStorage('DM_KHU_VUC')
        },
        list_enrollment_type() {
            return getFromSessionStorage('LOAI_NHAP_HOC')
        },
        list_school_block() {
            return getFromSessionStorage('DM_KHOI')
        },
        list_educational_support() {
            return getFromSessionStorage('HINH_THUC_HO_TRO')
        },
        list_nutritional_status() {
            return getFromSessionStorage('TINH_TRANG_DINH_DUONG')
        },
        list_reason_for_dropout() {
            return getFromSessionStorage('DM_LY_DO_THOI_HOC')
        },

        ...mapState('dynamicReport', {
            contentDisplayStudentReport: (state) =>
                state.contentDisplayStudentReport
        }),

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.pagi {
    background: var(--white-100);
    height: 50px;
    line-height: 50px;
    padding: 0px 10px 0px 0px;
}
.el-menu-item {
    height: 45px;
    line-height: 45px;
    background: var(--white-100);
    color: black;
    border-top: 1px solid #ddd;
    font-size: 14px;
}
.el-menu.el-menu--horizontal {
    margin-left: -20px;
}
</style>
