<template>
    <div>
        <ToolBar :menu="'Tra cứu'" :content="'Tra cứu Cán bộ, giáo viên'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Xuất Excel"
                    :icon="'el-icon-download'"
                    @click="handleExportExcel"
                    :disabled="tableData.length === 0"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-2" v-if="isRoleSuperAdmin">
                    <div class="label-wrapper">
                        <label class="control-label">Tỉnh thành:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="isRoleSuperAdmin">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_province"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="province"
                        @change="onChangeProvince"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :multiple="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Từ khóa tìm kiếm:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="key" placeholder="Tên, mã, số CMND ...">
                    </CustomInput>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trình độ chuyên môn nghiệp vụ:
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_professional_qualifications"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="professional_qualifications"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Giới tính:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_gender"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="gender"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Loại cán bộ:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_type_teacher"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="type_teacher"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_status"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Tuyển mới:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_new_recruit"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="new_recruit"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Mã định danh bộ GD&ĐT:
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="moetCode"> </CustomInput>
                </div>

                <div v-if="!isRoleSuperAdmin" class="col-md-4"></div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Nội dung hiển thị
                            <span class="error-label">*</span>:
                        </label>
                    </div>
                </div>

                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_content_displayed"
                        :multiple="true"
                        :fields="['title', 'value']"
                        v-model="content_displayed"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tìm kiếm nâng cao:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_advanced_search"
                        :multiple="true"
                        :fields="['name', 'value']"
                        v-model="advanced_search"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('ngaySinh')">
                    <div class="label-wrapper">
                        <label class="control-label">Ngày sinh:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('ngaySinh')">
                    <DatePicker v-model="date" />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('doTuoi')">
                    <div class="label-wrapper">
                        <label class="control-label">Độ tuổi:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('doTuoi')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_age"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="age"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maTonGiao')">
                    <div class="label-wrapper">
                        <label class="control-label">Tôn giáo:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maTonGiao')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_religion"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="religion"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('chucVu')">
                    <div class="label-wrapper">
                        <label class="control-label">Chức vụ:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('chucVu')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_position"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="position"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('dchiThtru')">
                    <div class="label-wrapper">
                        <label class="control-label">Địa chỉ thường trú:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dchiThtru')">
                    <CustomInput
                        v-model="permanent_address"
                        :disabled="false"
                        :show-password="false"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maKhuVuc')">
                    <div class="label-wrapper">
                        <label class="control-label">Khu vực:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maKhuVuc')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_area"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="area"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('dangVien')">
                    <div class="label-wrapper">
                        <label class="control-label">Đảng viên:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dangVien')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_party_member"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="party_member"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('bacLuong')">
                    <div class="label-wrapper">
                        <label class="control-label">Bậc lương :</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('bacLuong')">
                    <CustomInput
                        v-model="wage"
                        :disabled="false"
                        :show-password="false"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('heSoLuong')">
                    <div class="label-wrapper">
                        <label class="control-label">Hệ số lương:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('heSoLuong')">
                    <CustomInput
                        v-model="coefficients_salary"
                        :disabled="false"
                        :show-password="false"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('moetCode')">
                    <div class="label-wrapper">
                        <label class="control-label">Mã định danh:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('moetCode')">
                    <CustomInput
                        v-model="identifier"
                        :disabled="false"
                        :show-password="false"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maMonHoc')">
                    <div class="label-wrapper">
                        <label class="control-label">Môn dạy:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maMonHoc')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_subject_taught"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="subject_taught"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maDanToc')">
                    <div class="label-wrapper">
                        <label class="control-label">Dân tộc:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maDanToc')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_nation"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="nation"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('loaiHopDong')">
                    <div class="label-wrapper">
                        <label class="control-label">Hình thức hợp đồng:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiHopDong')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_contract_form"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="contract_form"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('soDienThoaiDiDong')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">Số điện thoại:</label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('soDienThoaiDiDong')"
                >
                    <CustomInput
                        v-model="phone_number"
                        :disabled="false"
                        :show-password="false"
                        :trimAll="true"
                    >
                    </CustomInput>
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoDaoTaoCnChinh')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">Trình độ đào tạo:</label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('trinhDoDaoTaoCnChinh')"
                >
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_degree_training"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="degree_training"
                    />
                </div>

                <div
                    class="col-md-2"
                    v-if="shouldShowElement('dgKqChuanNgheNghiep')"
                >
                    <div class="label-wrapper">
                        <label class="control-label">
                            Cấp trên đánh gia chuẩn n.nghiệp:
                        </label>
                    </div>
                </div>
                <div
                    class="col-md-2"
                    v-if="shouldShowElement('dgKqChuanNgheNghiep')"
                >
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_evaluate_professional_standards"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="evaluate_professional_standards"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="column_form">
            <template v-slot:btn-table>
                <span class="count" v-if="isCheckedCount > 0">
                    <b>Đã chọn {{ isCheckedCount }} bản ghi</b>
                </span>
                <CustomToolTip content="Đẩy dữ liệu lên MOET">
                    <CustomButton
                        label="Đẩy dữ liệu"
                        @click="toggleModal('pushData')"
                    />
                </CustomToolTip>
                <CustomToolTip content="Xóa hồ sơ">
                    <CustomButton
                        label="Xóa"
                        @click="toggleModal('delete')"
                        :type="'danger'"
                    />
                </CustomToolTip>
                <CustomButton
                    label="Xóa mã định danh bộ GD&ĐT"
                    @click="toggleModal('deleteIdentifier')"
                />
            </template>
            <template v-slot:header>
                <th>
                    <input
                        type="checkbox"
                        v-model="selectAllChecked"
                        @change="handleSelectAll"
                        :disabled="tableData.length === 0"
                    />
                </th>
                <th>STT</th>

                <th
                    v-for="field in tableHeader"
                    :key="field.value"
                    :class="field.class"
                >
                    {{ field.title }}
                </th>
                <th class="min-w-200">Phân công giảng dạy</th>
                <th class="min-w-100">Thao tác</th>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="21">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckboxTable(item)"
                        />
                    </td>
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>

                    <td
                        class="text-left"
                        style="min-width: 150px"
                        v-if="hasProperty('tenTruongHoc', item)"
                    >
                        {{ item.tenTruongHoc }}
                    </td>
                    <!-- <td v-if="hasProperty('maTruongHoc', item)">
                        {{ item.maTruongHoc }}
                    </td> -->
                    <td
                        class="text-left text-navigate"
                        v-if="hasProperty('tenCanBo', item)"
                        @click="navigateViewLockUpTeacher(item)"
                    >
                        {{ item.tenCanBo }}
                    </td>
                    <td v-if="hasProperty('maGiaoVien', item)" class="left">
                        {{ item.maGiaoVien }}
                    </td>
                    <td v-if="hasProperty('moetCode', item)">
                        {{ item.moetCode }}
                    </td>

                    <td v-if="hasProperty('ngaySinh', item)">
                        {{ item.ngaySinh }}
                    </td>
                    <td v-if="hasProperty('gioiTinh', item)">
                        {{ item.gioiTinh }}
                    </td>
                    <td v-if="hasProperty('trangThai', item)">
                        <TextStatus
                            :content="item.trangThai"
                            :status="item.trangThaiInt"
                        />
                    </td>
                    <td v-if="hasProperty('maDanToc', item)">
                        {{ item.maDanToc }}
                    </td>
                    <td class="text-left" v-if="hasProperty('loaiCanBo', item)">
                        {{ item.loaiCanBo }}
                    </td>
                    <td class="text-left" v-if="hasProperty('chucVu', item)">
                        {{ item.chucVu }}
                    </td>
                    <td class="text-left" v-if="hasProperty('loaiHopDong', item)">
                        {{ item.loaiHopDong }}
                    </td>
                    <td
                        class="text-left"
                        v-if="hasProperty('maTrinhDoChuyenMonCaoNhat', item)"
                    >
                        {{ item.maTrinhDoChuyenMonCaoNhat }}
                    </td>
                    <td class="text-left" v-if="hasProperty('maMonHoc', item)">
                        {{ item.maMonHoc }}
                    </td>
                    <td v-if="hasProperty('soDienThoaiDiDong', item)">
                        {{ item.soDienThoaiDiDong }}
                    </td>
                    <td class="text-left" v-if="hasProperty('queQuan', item)">
                        {{ item.queQuan }}
                    </td>
                    <td v-if="hasProperty('noiSinh', item)">
                        {{ item.noiSinh }}
                    </td>
                    <td v-if="hasProperty('namSinh', item)">
                        {{ item.namSinh }}
                    </td>
                    <td v-if="hasProperty('soCmnd', item)">
                        {{ item.soCmnd }}
                    </td>
                    <td v-if="hasProperty('hinhThucDaoTao', item)">
                        {{ item.hinhThucDaoTao }}
                    </td>
                    <td v-if="hasProperty('doTuoi', item)">
                        {{ item.doTuoi }}
                    </td>
                    <td v-if="hasProperty('trinhDoDaoTaoCnChinh', item)">
                        {{ item.trinhDoDaoTaoCnChinh }}
                    </td>
                    <td v-if="hasProperty('dgKqChuanNgheNghiep', item)">
                        {{ item.dgKqChuanNgheNghiep }}
                    </td>
                    <td>
                        <CustomButton
                            label="Cập nhật"
                            @click="openModalTeachingAssignment(item)"
                        />
                    </td>
                    <td>
                        <CustomToolTip content="Cập nhật hồ sơ">
                            <img
                                :src="iconEdit"
                                class="icon"
                                @click="navigateUpdateLockUpTeacher(item)"
                            />
                        </CustomToolTip>
                        <CustomToolTip content="Chuyển công tác">
                            <img
                                :src="iconMove"
                                class="icon"
                                @click="openModalSchoolTransfer(item)"
                            />
                        </CustomToolTip>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalDelete
            :deleteVisible="deleteVisible"
            :closeModalDelete="() => closeModal('delete')"
            :dataDelete="selectedItemsTable"
            @handleSearch="handleSearch"
        />
        <ModalDeleteIdentifier
            :deleteIdentifierVisible="deleteIdentifierVisible"
            :closeModalDeleteIdentifier="() => closeModal('deleteIdentifier')"
            :dataDeleteIdentifier="selectedItemsTable"
            @handleSearch="handleSearch"
        />
        <ModalPushData
            :pushDataVisible="pushDataVisible"
            :closeModalPushData="() => closeModal('pushData')"
            :dataPushData="selectedItemsTable"
            @handleSearch="handleSearch"
        />
        <ModalContentDisplayed
            :contentDisplayedVisible="contentDisplayedVisible"
            :closeModalContentDisplayed="closeModalContentDisplayed"
        />
        <ModalTeachingAssignment
            :teachingAssignmentVisible="teachingAssignmentVisible"
            :closeModalTeachingAssignment="closeModalTeachingAssignment"
            :dataTeachingAssignment="dataTeachingAssignment"
        />
        <ModalSchoolTransfer
            :schoolTransferVisible="schoolTransferVisible"
            :closeModalSchoolTransfer="closeModalSchoolTransfer"
            :dataSchoolTransfer="dataSchoolTransfer"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import CustomToolTip from '@/components/CustomToolTip.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertListData,
    calculateColumnFormLength,
    generateDataSearchList,
    setInitialContentDisplayed,
    updateContentDisplayed,
    addIsCheckedProperty,
    countCheckedItems,
    updateSelectedItemsTable,
    convertDownloadFileExcel,
    hasProperty,
    filterItemKeysTableData,
    convertNumberToString,
    processUnitEducationList,
    formatDateToDDMMYYYY
} from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomInput from '@/components/CustomInput.vue'
import DatePicker from '@/components/DatePicker.vue'
import {
    list_advanced_search,
    list_content_displayed,
    list_age,
    list_yes_no,
    list_true_false,
    list_gender,
    list_type_teacher
} from '@/mock_data/tra-cuu-can-bo-giao-vien'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconMove from '@/assets/img/icon-table/move.svg'
import ModalDelete from './modal_delete.vue'
import ModalPushData from './modal_push_data.vue'
import ModalDeleteIdentifier from './modal_delete_identifier.vue'
import ModalContentDisplayed from './modal_content_displayed.vue'
import ModalTeachingAssignment from './modal_teaching_assignment.vue'
import ModalSchoolTransfer from './modal_school_transfer.vue'
import { path } from '@/router/path'
import TextStatus from '@/components/TextStatus.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
import { showNotication } from '@/utils/notification'

export default {
    name: 'LookUpTeachersAndStaff',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomInput,
        CustomToolTip,
        DatePicker,
        ModalDelete,
        ModalPushData,
        ModalDeleteIdentifier,
        ModalContentDisplayed,
        ModalTeachingAssignment,
        ModalSchoolTransfer,
        TextStatus
    },
    data() {
        return {
            iconEdit,
            iconMove,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            isCheckedCount: 0,
            selectAllChecked: false,
            selectedItemsTable: [],

            province: '',
            list_unit_education: [],

            unit_education: [],

            list_school: [],
            school: [],

            key: '',

            professional_qualifications: [],

            list_gender: list_gender,
            gender: '',

            list_type_teacher: list_type_teacher,
            type_teacher: '',

            status: '',

            list_new_recruit: list_true_false,
            new_recruit: '',

            moetCode: '',

            list_content_displayed: list_content_displayed,
            content_displayed: [],

            list_advanced_search: list_advanced_search,
            advanced_search: [],

            date: '',

            list_age: list_age,
            age: [],

            religion: [],

            position: [],

            permanent_address: '',

            area: [],

            list_party_member: list_yes_no,
            party_member: '',

            wage: '',

            coefficients_salary: '',

            identifier: '',

            subject_taught: [],

            nation: '',

            contract_form: [],

            phone_number: '',

            degree_training: [],

            evaluate_professional_standards: [],

            tableData: [],
            tableHeader: [],
            column_form: 4,

            deleteVisible: false,
            pushDataVisible: false,
            deleteIdentifierVisible: false,

            viewVisible: false,

            contentDisplayedVisible: false,

            teachingAssignmentVisible: false,
            dataTeachingAssignment: {},

            schoolTransferVisible: false,
            dataSchoolTransfer: {}
        }
    },

    methods: {
        hasProperty,
        shouldShowElement(element) {
            return this.advanced_search.includes(element)
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        navigateToLockUpTeacher(item, viewVisible) {
            this.viewVisible = viewVisible

            this.$router.push({
                path: `${path.lock_up.teacher.update_teacher}/${item.canBoTheoNamId}`,
                query: { viewVisible: viewVisible }
            })
        },

        navigateUpdateLockUpTeacher(item) {
            this.navigateToLockUpTeacher(item, false)
        },

        navigateViewLockUpTeacher(item) {
            this.navigateToLockUpTeacher(item, true)
        },
        openModalTeachingAssignment(item) {
            this.dataTeachingAssignment = item
            this.teachingAssignmentVisible = true
        },
        closeModalTeachingAssignment() {
            this.teachingAssignmentVisible = false
        },
        openModalContentDisplayed() {
            this.contentDisplayedVisible = true
        },
        closeModalContentDisplayed() {
            this.contentDisplayedVisible = false
        },
        openModalSchoolTransfer(item) {
            this.dataSchoolTransfer = item
            this.schoolTransferVisible = true
        },
        closeModalSchoolTransfer() {
            this.schoolTransferVisible = false
        },
        toggleModal(modalType) {
            if (this.selectedItemsTable.length === 0) {
                showNotication(this, 'error', 'Vui lòng chọn bản ghi')
                return
            }

            this[`${modalType}Visible`] = !this[`${modalType}Visible`]
        },

        closeModal(modalType) {
            // this.resetCheckedTable()
            this[`${modalType}Visible`] = false
        },
        resetCheckedTable() {
            // this.tableData = this.tableData.filter((item) => !item.isChecked)
            this.selectedItemsTable = []
            this.selectAllChecked = false
        },

        toggleCheckboxTable(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            this.selectedItemsTable = updateSelectedItemsTable(
                this.selectedItemsTable,
                item
            )
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked
                if (
                    this.selectAllChecked &&
                    !this.selectedItemsTable.includes(item)
                ) {
                    this.selectedItemsTable.push(item)
                } else if (!this.selectAllChecked) {
                    this.selectedItemsTable = []
                }
            })
        },
        onChangeProvince() {
            this.get_list_unit_education()
            this.unit_education = []
            this.get_list_school()
            this.school = []
        },

        async get_list_unit_education() {
            try {
                const request_params = {
                    start: 0,
                    limit: 9999,
                    maTinhThanh: this.province,
                    check: false
                }

                const response = await sendRequest(
                    Api.internal_api.dm_DonVi,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_unit_education = processUnitEducationList(response.rows)
            } catch (error) {
                console.error('Error fetching unit education list:', error)
            }
        },
        async get_list_school() {
            if (
                this.isRoleSuperAdmin &&
                this.unit_education.length === 0 &&
                this.province.length === 0
            ) {
                return
            }

            const request_data = {
                maSo: this.province,
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },

        syncTableHeaderWithContentDisplay() {
            this.tableHeader = updateContentDisplayed(
                this.list_content_displayed,
                this.content_displayed
            )
        },

        async handleSearch() {
            try {
                const request_data = this.prepareRequestData()
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_can_bo_giao_vien.danh_sach,
                    request_data,
                    this.request_headers,
                    null
                )

                if (response.rc == 0) {
                    this.total_rows = response.totalRows
                    const keysToKeep = this.tableHeader.map(
                        (header) => header.key
                    )
                    const additionalKeysToKeep = [
                        'canBoTheoNamId',
                        'trangThaiInt',
                        'maTruongHoc'
                    ]
                    const filteredTableData = response.tableData.map((item) => {
                        return filterItemKeysTableData(
                            item,
                            keysToKeep,
                            additionalKeysToKeep
                        )
                    })

                    this.advanced_search = []
                    this.tableData = addIsCheckedProperty(filteredTableData)
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleExportExcel() {
            try {
                const domain = process.env.VUE_APP_BACKEND_BASE_URL
                const request_data = this.prepareRequestData()
                const apiUrl = `${domain}/${Api.tra_cuu.tra_cuu_can_bo_giao_vien.excel.url}`
                const fileName = 'tra_cuu_can_bo_giao_vien.xlsx'
                const authToken = this.auth_user.token

                convertDownloadFileExcel(
                    apiUrl,
                    request_data,
                    fileName,
                    authToken
                )
            } catch (error) {
                console.log(error)
            }
        },
        prepareRequestData() {
            const mappings = [
                {
                    key: 'ngaySinh',
                    value: formatDateToDDMMYYYY(this.date),
                    check: 'includes'
                },
                { key: 'doTuoi', value: this.age, check: 'includes' },
                { key: 'maTonGiao', value: this.religion, check: 'includes' },
                { key: 'chucVu', value: this.position, check: 'includes' },
                {
                    key: 'dchiThtru',
                    value: this.permanent_address,
                    check: 'includes'
                },
                { key: 'maKhuVuc', value: this.area, check: 'includes' },
                { key: 'dangVien', value: this.party_member, check: 'includes' },
                { key: 'bacLuong', value: this.wage, check: 'includes' },
                {
                    key: 'heSoLuong',
                    value: this.coefficients_salary,
                    check: 'includes'
                },
                { key: 'moetCode', value: this.identifier, check: 'includes' },
                {
                    key: 'maMonHoc',
                    value: this.subject_taught,
                    check: 'includes'
                },
                { key: 'maDanToc', value: this.nation, check: 'includes' },
                {
                    key: 'loaiHopDong',
                    value: this.contract_form,
                    check: 'includes'
                },
                {
                    key: 'soDienThoaiDiDong',
                    value: this.phone_number,
                    check: 'includes'
                },
                {
                    key: 'trinhDoDaoTaoCnChinh',
                    value: this.degree_training,
                    check: 'includes'
                },

                {
                    key: 'dgKqChuanNgheNghiep',
                    value: this.evaluate_professional_standards,
                    check: 'includes'
                }
            ]

            const dataSearchList = generateDataSearchList(
                this.advanced_search,
                mappings
            )
            this.syncTableHeaderWithContentDisplay()
            const colExcels = this.tableHeader.map((item) => ({
                key: item.key,
                name: item.title,
                stt: item.value
            }))

            return {
                maSo: convertNumberToString(this.province),
                capTruong: this.selectedGradeLevelFromStore,
                colExcels: colExcels,
                dataSreachList: dataSearchList,
                gioiTinh: this.gender,
                keyword: this.key,
                limit: this.limit,
                loaiCanBo: this.type_teacher,
                maChuyenNganhDaoTao: null,
                maDonVis: this.unit_education,
                maTrinhDoChuyenMonCaoNhat: this.professional_qualifications,
                maTruongs: this.school,
                namHoc: this.selectedYearFromStore,
                start: this.start,
                trangThai: this.status,
                isTuyenMoi: this.new_recruit,
                moetCode: this.moetCode
            }
        }
    },
    mounted() {
        this.content_displayed = setInitialContentDisplayed(
            this.list_content_displayed,
            this.content_displayed,
            12
        )
        this.syncTableHeaderWithContentDisplay()
        // this.handleSearch()
        if (!this.isRoleSuperAdmin) {
            this.get_list_school()
        }
        this.list_unit_education = getFromSessionStorage('list_unit_education')
    },
    watch: {
        selectedGradeLevelFromStore(newVal) {
            if (newVal) {
                this.school = []
                this.get_list_school()
            }
        },
        tableData: {
            handler(newVal) {
                this.isCheckedCount = countCheckedItems(newVal)
            },
            deep: true
        },
        advanced_search(newVal, oldVal) {
            this.column_form = calculateColumnFormLength(newVal.length, 4, 10)
        },
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },

    computed: {
        list_province() {
            return getFromSessionStorage('list_province')
        },

        list_professional_qualifications() {
            return getFromSessionStorage('DM_TRINH_DO_CHUYEN_MON')
        },
        list_status() {
            return getFromSessionStorage('DM_TRANG_THAI_CAN_BO')
        },

        list_religion() {
            return getFromSessionStorage('DM_TON_GIAO')
        },
        list_position() {
            return getFromSessionStorage('DM_LOAI_CAN_BO')
        },
        list_area() {
            return getFromSessionStorage('DM_KHU_VUC')
        },
        list_subject_taught() {
            return getFromSessionStorage('DM_MON_DAY_GV')
        },
        list_nation() {
            return getFromSessionStorage('DM_DAN_TOC')
        },
        list_contract_form() {
            return getFromSessionStorage('DM_HINH_THUC_HOP_DONG')
        },
        list_degree_training() {
            return getFromSessionStorage('DM_TRINH_DO_CHUYEN_MON')
        },
        list_evaluate_professional_standards() {
            return getFromSessionStorage('DM_KQ_CHUAN_NGHE_NGHIEP')
        },

        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleSuperAdmin() {
            return checkUserRole(this.auth_user, ROLES.SuperAdmin)
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
::v-deep .el-icon-connection:before {
    font-size: 16px;
    content: '\e736';
    font-weight: bold;
}
@media only screen and (max-width: 767px) {
    .col-md-2 {
        margin-top: 20px;
    }
}
</style>
