<template>
    <div>
        <ToolBar
            :menu="'Tiếp nhận dữ liệu QLNT'"
            :content="'Lịch sử tiếp nhận dữ liệu'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_unit_education"
                        :multiple="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unitEducation"
                        @change="get_list_school"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trường học:
                            <span class="note">*</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_school"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="school"
                        @input="onChangeHandler('school', $event)"
                        :class="{
                            'error-select': err_school.length > 0
                        }"
                    />
                    <span v-if="err_school.length > 0" class="text-error-select">
                        {{ err_school }}
                    </span>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_status"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đối tác:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_partner_system"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="partnerSystem"
                    />
                </div>

                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Loại đồng bộ:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_synchronou_stype"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="synchronou_stype"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Loại đợt:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_batch_type"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="batch_type"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Mã lần đẩy:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <CustomInput
                        v-model="pasCode"
                        :disabled="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Ngày nhận dữ liệu:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <DatePicker v-model="date" />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="column_form">
            <template v-slot:header>
                <tr>
                    <th>STT</th>

                    <th>Mã lần đẩy</th>
                    <th>Đối tác</th>
                    <th>Trường học</th>
                    <th>Mã trường</th>
                    <th>Thời gian nhận</th>
                    <th>Thời gian xử lí</th>
                    <th>Loại đồng bộ</th>
                    <th>Năm đồng bộ</th>
                    <th>Loại đợt</th>
                    <th>Chi tiết</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="11">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>

                    <td class="text-left">{{ item.reqMessId }}</td>

                    <td>{{ item.tenDoitac }}</td>
                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>{{ item.maTruongHoc }}</td>
                    <td>
                        {{ item.timeDay }}
                    </td>
                    <td>{{ item.timePhanHoi }}</td>
                    <td>
                        {{
                            getLabelByArray(
                                list_synchronou_stype,
                                item.loaiDuLieu
                            )
                        }}
                    </td>
                    <td>{{ item.namHoc }} - {{ item.namHoc + 1 }}</td>
                    <td>
                        {{ getLabelByArray(list_batch_type, item.loaiDot) }}
                    </td>
                    <td>
                        <CustomToolTip content="Xem chi tiết">
                            <i
                                class="el-icon-info icon"
                                @click="openModalView(item)"
                            >
                            </i>
                        </CustomToolTip>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalView
            :viewVisible="viewVisible"
            :closeModalView="closeModalView"
            :dataView="dataView"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { getLabelByArray, formatDateToDDMMYYYY, convertListData } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import {
    list_status,
    list_synchronou_stype,
    list_batch_type
} from '@/mock_data/list-su-tiep-nhan-du-lieu'
import CustomInput from '@/components/CustomInput.vue'
import DatePicker from '@/components/DatePicker.vue'
import { showNotication } from '@/utils/notification'
import ModalView from './modal_view.vue'
import CustomToolTip from '@/components/CustomToolTip.vue'
import { handlerError, validateFields } from '@/utils/validator'
export default {
    name: 'DataTransferSchedule',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomInput,
        DatePicker,
        ModalView,
        CustomToolTip
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unitEducation: [],

            list_school: [],
            school: [],
            err_school: '',

            list_status: list_status,
            status: '',

            partnerSystem: '',

            list_synchronou_stype: list_synchronou_stype,
            synchronou_stype: '',

            list_batch_type: list_batch_type,
            batch_type: '',

            pasCode: '',
            date: '',
            tableData: [],

            viewVisible: false,
            dataView: {}
        }
    },

    methods: {
        getLabelByArray,
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                school: 'err_school'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async get_list_school() {
            this.school = []

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unitEducation
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        openModalView(item) {
            this.dataView = item
            this.viewVisible = true
        },
        closeModalView() {
            this.viewVisible = false
        },
        async handleSearch() {
            const fieldsToValidate = [
                {
                    value: this.school,
                    errorField: 'err_school'
                }
            ]
            if (validateFields(this, fieldsToValidate)) {
                try {
                    const request_data = {
                        capHoc: this.selectedGradeLevelFromStore,
                        loaiDayDuLieu: this.synchronou_stype,
                        loaiDot: this.batch_type,
                        maDoiTacCha: this.partnerSystem,
                        maDonVi: this.unitEducation,
                        maGiaoDich: this.pasCode ? this.pasCode : null,
                        maTruongHoc: this.school,
                        namDayDulieu: this.selectedYearFromStore,
                        status: this.status,
                        timeDayEnd: formatDateToDDMMYYYY(this.date),
                        timeDayStart: formatDateToDDMMYYYY(this.date),
                        start: this.start,
                        limit: this.limit
                    }

                    const response = await sendRequest(
                        Api.tiep_nhan_du_lieu_QLNT.lich_su_tiep_nhan_du_lieu
                            .danh_sach,
                        request_data,
                        this.request_headers,
                        null
                    )

                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)
                    } else if (response.rc == 2) {
                        showNotication(this, 'error', response.rd)
                    }
                    this.total_rows = response.total

                    this.tableData = response.rows
                } catch (error) {
                    console.log(error)
                }
            }
        }
    },
    mounted() {
        this.get_list_school()
    },
    watch: {
        selectedGradeLevelFromStore: function (newYear, oldYear) {
            if (newYear) {
                this.get_list_school()
            }
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },

        list_partner_system() {
            return getFromSessionStorage('list_partner_system') || []
        },
        column_form() {
            let extraColumn = this.err_school ? 4 : 3
            return extraColumn
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.note {
    color: var(--red-common);
}
.error {
    margin-top: -20px;
    color: var(--red-common);
}
.icon {
    color: var(--gray-400);
}
.icon:hover {
    color: var(--blue-100);
}
</style>
