<template>
    <el-select
        ref="el_select"
        :clearable="clearable"
        :disabled="disabled"
        v-model="Content"
        :multiple="multiple"
        :collapse-tags="collapseTags"
        :placeholder="disabled ? '' : placeholder"
        @change="onChange"
        @input="onInput"
        :filterable="filterable"
        :no-data-text="'Không có bản ghi nào'"
        :no-match-text="'Không tìm thấy bản ghi nào'"
        :size="size"
        :class="{ 'active-dislabel': disabled }"
    >
        <li
            style="border: 1px solid #e4e7ed"
            v-if="multiple"
            class="el-select-dropdown__item"
        >
            <label style="font-weight: normal">
                <input
                    type="checkbox"
                    @change="onCheckAllChange"
                    v-model="CheckAll"
                    style="cursor: pointer"
                />
                --Tất cả--
            </label>
        </li>
        <el-option
            v-for="(item, index) in data"
            :key="index"
            :label="getLabel(item)"
            :disabled="item.disabled"
            :value="getValue(item)"
        ></el-option>
    </el-select>
</template>
<script>
export default {
    props: {
        clearable: {
            default: true
        },
        value: {
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        data: {
            default: [],
            required: true
        },
        placeholder: {
            default: 'Chọn'
        },
        filterable: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        'collapse-tags': {
            type: Boolean,
            default: true
        },
        fields: {
            type: Array,
            required: true
        },
        size: {
            type: String,
            default: 'small'
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.Content = newValue || (this.multiple ? [] : null)
                if (this.multiple && Array.isArray(this.Content)) {
                    this.CheckAll =
                        this.Content.length === (this.data ? this.data.length : 0)
                }
            },
            immediate: true
        },
        data: {
            handler() {
                if (this.multiple && Array.isArray(this.Content)) {
                    this.CheckAll =
                        this.Content.length === (this.data ? this.data.length : 0)
                }
            },
            immediate: true
        }
    },
    mounted() {},
    data() {
        return {
            Content: this.value,
            CheckAll: false
        }
    },
    methods: {
        onCheckAllChange() {
            if (this.CheckAll) {
                this.Content = []
                if (this.fields[1]) {
                    this.data.map((obj) => {
                        this.Content.push(obj[this.fields[1]])
                        this.$emit('input', this.Content)
                    })
                }
                this.$emit('change', this.Content)
            } else {
                this.Content = []
                this.$emit('change', this.Content)
                this.$emit('input', this.Content)
            }
        },
        focus() {
            this.$refs.el_select.focus()
        },
        onChange(e) {
            if (this.Content.length == this.data.length) {
                this.CheckAll = true
            } else {
                this.CheckAll = false
            }
            this.$emit('change', e)
        },
        onInput(e) {
            this.$emit('input', e)
        },
        getLabel(item) {
            if (!this.fields[0]) {
                return
            }
            return item[this.fields[0]]
        },
        getValue(item) {
            if (!this.fields[1]) {
                return
            }
            return item[this.fields[1]]
        }
    }
}
</script>

<style scoped></style>
