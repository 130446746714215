<template>
    <div>
        <CustomModal
            :centered="true"
            :is-modal-visible="deleteVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDelete"
            :size="'sm'"
            :err-modal="errModal"
        >
            <template v-slot:content>
                Xác nhận xóa <b> {{ dataDelete.length }}</b> đối tác đã chọn?
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Xóa"
                    :type="'danger'"
                    @click="handleDelete"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { extractValuesFromArray } from '@/utils'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalDelete',
    data() {
        return {
            errModal: false
        }
    },
    props: {
        deleteVisible: {
            type: Boolean,
            required: true
        },
        dataDelete: {
            type: Array,
            require: true
        },
        closeModalDelete: Function,
        handleSearch: Function
    },

    components: { CustomButton, CustomModal },
    methods: {
        async handleDelete() {
            try {
                const ids = extractValuesFromArray(this.dataDelete, 'id')

                const request_data = {
                    ids: ids
                }

                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.quan_ly_he_thong_doi_tac_truong
                        .xoa,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    this.closeModalDelete()
                    this.$emit('handleSearch')
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
