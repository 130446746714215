<template>
    <LayoutCard>
        <div class="row">
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title>
                        Chuẩn quốc gia chất lượng giáo dục
                    </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_column_chart_education_quality_standard
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                customColorStackedColumnChart(
                                    column_chart_education_quality_standard
                                )
                            "
                            :xaxis_categories="
                                xaxisCategories.education_quality_standard
                            "
                        />
                    </template>
                </Card>
            </div>
            <div class="col-md-6 mb-4">
                <Card>
                    <template v-slot:title>
                        Đạt kiểm định chất lượng: cấp độ 1- 4
                    </template>
                    <template v-slot:btn-title>
                        <CustomButton
                            type="text"
                            icon="el-icon-refresh"
                            label=""
                            size="medium"
                            @click="
                                handleRefresh_column_chart_quality_assurance_level_1_4
                            "
                        />
                    </template>
                    <template v-slot:body>
                        <StackedColumnChart
                            :data_StackedColumnChart="
                                customColorStackedColumnChart(
                                    column_chart_quality_assurance_level_1_4
                                )
                            "
                            :xaxis_categories="
                                xaxisCategories.quality_assurance_level_1_4
                            "
                        />
                    </template>
                </Card>
            </div>
        </div>
    </LayoutCard>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import 'element-ui/lib/theme-chalk/index.css'

import { customColorStackedColumnChart } from '@/utils'
import StackedColumnChart from '@/components/StackedColumnChart.vue'
import LayoutCard from '@/components/CardDashboard/LayoutCard'
import Card from '@/components/CardDashboard/Card'
export default {
    name: 'ChartSchool',
    components: {
        CustomButton,
        LayoutCard,
        Card,
        StackedColumnChart
    },
    data() {
        return {
            xaxisCategories: {
                education_quality_standard: [
                    'C.Lượng tối thiểu',
                    'Chuẩn mức 1',
                    'Chuẩn mức 2'
                ],
                quality_assurance_level_1_4: ['Công lập', 'Tư thục', 'Dân lập']
            }
        }
    },
    props: {
        column_chart_education_quality_standard: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_education_quality_standard: Function,

        column_chart_quality_assurance_level_1_4: {
            type: Array,
            required: true,
            default: () => []
        },
        refresh_column_chart_quality_assurance_level_1_4: Function
    },
    methods: {
        customColorStackedColumnChart,
        handleRefresh_column_chart_education_quality_standard() {
            this.$emit('refresh_column_chart_education_quality_standard')
        },

        handleRefresh_pie_chart_educator_profile() {
            this.$emit('refresh_pie_chart_educator_profile')
        },

        handleRefresh_column_chart_quality_assurance_level_1_4() {
            this.$emit('refresh_column_chart_quality_assurance_level_1_4')
        }
    }
}
</script>

<style scoped></style>
