<template>
    <div>
        <ToolBar
            :menu="'Tra cứu lớp học'"
            :content="getContentToolBar()"
            :hideToggle="true"
            :hideZoom="true"
        >
            <template v-slot:form>
                <CustomButton
                    :plain="true"
                    label="Quay lại"
                    @click="openModalBack"
                />
                <CustomButton
                    v-if="!viewVisible"
                    label="Lưu"
                    @click="handleUpdate"
                />
            </template>
        </ToolBar>
        <FormLockUp>
            <template v-slot:lock-up-container>
                <CustomTab
                    :initialTab="active_tab_info"
                    :tabs="list_tab_info"
                    :hideHr="false"
                />
                <div class="form-update mt-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.school_block }}
                                        </label>
                                        <div class="n-index">[1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="school_block"
                                        :disabled="true"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([4, 5, 45])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.class_group }}
                                        </label>
                                        <div class="n-index">[1.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="class_group"
                                        :disabled="true"
                                    >
                                    </CustomInput>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([4, 5, 45])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.age_group }}
                                        </label>
                                        <div class="n-index error">[1.2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_age_group"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="age_group"
                                        @input="
                                            onChangeHandler('age_group', $event)
                                        "
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.name_class }}
                                        </label>
                                        <div class="n-index error">[2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="name_class"
                                        :disabled="viewVisible"
                                        @input="
                                            onChangeHandler('name_class', $event)
                                        "
                                        :textError="err_name_class"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.code_class }}
                                        </label>
                                        <div class="n-index">[3]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="code_class"
                                        :disabled="true"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.moet_code }}
                                        </label>
                                        <div class="n-index">[4]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="moet_code"
                                        :disabled="true"
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.snake_case }}
                                        </label>
                                        <div class="n-index error">[5]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_snake_case"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="snake_case"
                                        @input="
                                            onChangeHandler('snake_case', $event)
                                        "
                                        :class="{
                                            'error-select':
                                                err_snake_case.length > 0
                                        }"
                                    />
                                    <span
                                        v-if="err_snake_case.length > 0"
                                        class="text-error-select"
                                    >
                                        {{ err_snake_case }}
                                    </span>
                                </div>
                            </div>
                            <div class="form-group" v-if="isGradeLevelValid([1])">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.number_of_lessons_per_week
                                            }}
                                        </label>
                                        <div class="n-index">[6]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_number_of_lessons_per_week"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="number_of_lessons_per_week"
                                        @input="
                                            onChangeHandler(
                                                'number_of_lessons_per_week',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.school_name }}
                                        </label>
                                        <div class="n-index">[7]</div>
                                    </div>
                                </div>
                                <div class="col-md-8" v-if="viewVisible">
                                    <CustomInput
                                        v-model="school_name"
                                        :disabled="viewVisible"
                                        @input="
                                            onChangeHandler('school_name', $event)
                                        "
                                    >
                                    </CustomInput>
                                </div>
                                <div class="col-md-8" v-else>
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_school_grade"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="school_grade"
                                        @input="
                                            onChangeHandler(
                                                'school_grade',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.lesson_session }}
                                        </label>
                                        <div class="n-index">[7.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_lesson_session"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="lesson_session"
                                        @input="
                                            onChangeHandler(
                                                'lesson_session',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.is_combined_class }}
                                        </label>
                                        <div class="n-index">[8]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_combined_class"
                                        @input="
                                            onChangeHandler(
                                                'is_combined_class',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.merge_into_class }}
                                        </label>
                                        <div class="n-index">[9]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <CustomInput
                                        v-model="merge_into_class"
                                        :disabled="viewVisible"
                                        @input="
                                            onChangeHandler(
                                                'merge_into_class',
                                                $event
                                            )
                                        "
                                    >
                                    </CustomInput>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="info_class.capHoc === 3"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.is_specialized_class }}
                                        </label>
                                        <div class="n-index">[9.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_specialized_class"
                                        @input="
                                            onChangeHandler(
                                                'is_specialized_class',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="info_class.capHoc === 3"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.class_division }}
                                        </label>
                                        <div class="n-index">[9.2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_class_division"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="class_division"
                                        @input="
                                            onChangeHandler(
                                                'class_division',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.is_vnen_class }}
                                        </label>
                                        <div class="n-index">[10]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_vnen_class"
                                        @input="
                                            onChangeHandler(
                                                'is_vnen_class',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="
                                    info_class.capHoc === 2 ||
                                    info_class.capHoc === 3
                                "
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.is_vocational_student
                                            }}
                                        </label>
                                        <div class="n-index">[10.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_vocational_student"
                                        @input="
                                            onChangeHandler(
                                                'is_vocational_student',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="info_class.capHoc === 1"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.is_sufficient_teaching_equipment_vietnamese
                                            }}
                                        </label>
                                        <div class="n-index">[11]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="
                                            is_sufficient_teaching_equipment_vietnamese
                                        "
                                        @input="
                                            onChangeHandler(
                                                'is_sufficient_teaching_equipment_vietnamese',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="info_class.capHoc === 1"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.is_sufficient_teaching_equipment_math
                                            }}
                                        </label>
                                        <div class="n-index">[12]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="
                                            is_sufficient_teaching_equipment_math
                                        "
                                        @input="
                                            onChangeHandler(
                                                'is_sufficient_teaching_equipment_math',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="info_class.capHoc === 1"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.is_parent_representative_class
                                            }}
                                        </label>
                                        <div class="n-index">[13]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_parent_representative_class"
                                        @input="
                                            onChangeHandler(
                                                'is_parent_representative_class',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="info_class.capHoc === 1"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.is_parent_representative_school
                                            }}
                                        </label>
                                        <div class="n-index">[14]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_parent_representative_school"
                                        @input="
                                            onChangeHandler(
                                                'is_parent_representative_school',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.homeroom_teacher }}
                                        </label>
                                        <div class="n-index">[15]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_homeroom_teacher"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="homeroom_teacher"
                                        @input="
                                            onChangeHandler(
                                                'homeroom_teacher',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([4, 5, 45])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.is_merged_children_group
                                            }}
                                        </label>
                                        <div class="n-index">[15.1]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_merged_children_group"
                                        @input="
                                            onChangeHandler(
                                                'is_merged_children_group',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([4, 5, 45])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.is_new_early_childhood_education_program
                                            }}
                                        </label>
                                        <div class="n-index">[15.2]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="
                                            is_new_early_childhood_education_program
                                        "
                                        @input="
                                            onChangeHandler(
                                                'is_new_early_childhood_education_program',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([4, 5, 45])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.is_care_education_program
                                            }}
                                        </label>
                                        <div class="n-index">[15.3]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_care_education_program"
                                        @input="
                                            onChangeHandler(
                                                'is_care_education_program',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            <span
                                                v-if="
                                                    isGradeLevelValid([4, 5, 45])
                                                "
                                            >
                                                {{
                                                    objectTitle.is_children_with_disabilities
                                                }}
                                            </span>
                                            <span v-else>
                                                Học sinh khuyết tật
                                            </span>
                                        </label>
                                        <div class="n-index">[15.4]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_children_with_disabilities"
                                        @input="
                                            onChangeHandler(
                                                'is_children_with_disabilities',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>

                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.foreign_language_1 }}
                                        </label>
                                        <div class="n-index">[16]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_foreign_language"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="foreign_language_1"
                                        @input="
                                            onChangeHandler(
                                                'foreign_language_1',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.curriculum_foreign_language_1
                                            }}
                                        </label>
                                        <div class="n-index">[17]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_curriculum_foreign_language"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="curriculum_foreign_language_1"
                                        @input="
                                            onChangeHandler(
                                                'curriculum_foreign_language_1',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.number_of_lessons_per_week_foreign_language_1
                                            }}
                                        </label>
                                        <div class="n-index">[18]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_number_of_lessons_per_week_foreign_language
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="
                                            number_of_lessons_per_week_foreign_language_1
                                        "
                                        @input="
                                            onChangeHandler(
                                                'number_of_lessons_per_week_foreign_language_1',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.foreign_language_2 }}
                                        </label>
                                        <div class="n-index">[19]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_foreign_language"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="foreign_language_2"
                                        @input="
                                            onChangeHandler(
                                                'foreign_language_2',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.curriculum_foreign_language_2
                                            }}
                                        </label>
                                        <div class="n-index">[20]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_curriculum_foreign_language"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="curriculum_foreign_language_2"
                                        @input="
                                            onChangeHandler(
                                                'curriculum_foreign_language_2',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.number_of_lessons_per_week_foreign_language_2
                                            }}
                                        </label>
                                        <div class="n-index">[21]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_number_of_lessons_per_week_foreign_language
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="
                                            number_of_lessons_per_week_foreign_language_2
                                        "
                                        @input="
                                            onChangeHandler(
                                                'number_of_lessons_per_week_foreign_language_2',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.is_boarding_school }}
                                        </label>
                                        <div class="n-index">[22]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_boarding_school"
                                        @input="
                                            onChangeHandler(
                                                'is_boarding_school',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.boarding_school_education
                                            }}
                                        </label>
                                        <div class="n-index">[23]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_boarding_school_education"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="boarding_school_education"
                                        @input="
                                            onChangeHandler(
                                                'boarding_school_education',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group" v-if="isGradeLevelValid([1])">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.is_specialized }}
                                        </label>
                                        <div class="n-index">[24]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="is_specialized"
                                        @input="
                                            onChangeHandler(
                                                'is_specialized',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.learning_method }}
                                        </label>
                                        <div class="n-index">[25]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_learning_method"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="learning_method"
                                        @input="
                                            onChangeHandler(
                                                'learning_method',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.training }}
                                        </label>
                                        <div class="n-index">[26]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_training"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="training"
                                        @input="
                                            onChangeHandler('training', $event)
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.vocational_education_career_orientation
                                            }}
                                        </label>
                                        <div class="n-index">[27]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="
                                            list_vocational_education_career_orientation
                                        "
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="
                                            vocational_education_career_orientation
                                        "
                                        @input="
                                            onChangeHandler(
                                                'vocational_education_career_orientation',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.used_textbooks }}
                                        </label>
                                        <div class="n-index">[28]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_used_textbooks"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="used_textbooks"
                                        @input="
                                            onChangeHandler(
                                                'used_textbooks',
                                                $event
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                class="form-group"
                                v-if="isGradeLevelValid([1, 2, 3, 6])"
                            >
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{ objectTitle.class_type }}
                                        </label>
                                        <div class="n-index">[29]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <ESelect
                                        style="width: 100%"
                                        :disabled="viewVisible"
                                        :data="list_class_type"
                                        :multiple="false"
                                        :fields="['label', 'value']"
                                        v-model="class_type"
                                        @input="
                                            onChangeHandler('class_type', $event)
                                        "
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-4">
                                    <div class="label-wrapper">
                                        <label class="control-label">
                                            {{
                                                objectTitle.vocational_high_school
                                            }}
                                        </label>
                                        <div class="n-index">[31]</div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <el-checkbox
                                        :disabled="viewVisible"
                                        v-model="vocational_high_school"
                                        @input="
                                            onChangeHandler(
                                                'vocational_high_school',
                                                $event
                                            )
                                        "
                                    >
                                    </el-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </FormLockUp>
        <CustomModal
            :is-modal-visible="backVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalBack"
        >
            <template v-slot:content>
                <strong>
                    Các thông tin vừa nhập sẽ bị mất khi đóng trang.
                    <br />
                    Thầy cô có muốn tiếp tục chỉnh sửa, kiểm tra và lưu lại thông
                    tin đã nhập?
                </strong>
            </template>
            <template v-slot:btn-handle>
                <CustomButton
                    label="Rời khỏi trang"
                    :btnSaveModal="true"
                    @click="navigateLockUpClass"
                />
            </template>
        </CustomModal>
    </div>
</template>

<script>
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { getFromSessionStorage } from '@/utils/session'
import ToolBar from '@/components/ToolBar.vue'
import { path } from '@/router/path'
import CustomButton from '@/components/CustomButton.vue'
import FormLockUp from '@/components/FormLockUp.vue'
import CustomInput from '@/components/CustomInput.vue'
import CustomTab from '@/components/CustomTab.vue'
import ESelect from '@/components/ESelect.vue'
import {
    convertToBoolean,
    convertListData,
    getLabelByArray,
    convertToBinary
} from '@/utils'
import {
    list_snake_case,
    list_curriculum_foreign_language,
    list_number_of_lessons_per_week_foreign_language,
    list_age_group,
    list_grade_level,
    list_lesson_session
} from '@/mock_data/tra-cuu-lop-hoc'

import { showNotication } from '@/utils/notification'
import {
    validateFields,
    handlerError,
    updateChangedTitles
} from '@/utils/validator'
import CustomModal from '@/components/CustomModal.vue'
export default {
    name: 'UpdateLockUpClass',
    components: {
        ToolBar,
        CustomButton,
        FormLockUp,
        CustomInput,
        ESelect,
        CustomTab,
        CustomModal
    },
    data() {
        return {
            objectTitle: {
                school_block: 'Khối học',
                class_group: 'Nhóm lớp',

                age_group: ' Nhóm tuổi',
                name_class: 'Tên lớp',
                code_class: 'Mã lớp',
                moet_code: 'Mã định danh',

                snake_case: 'Số buổi học trên tuần',

                number_of_lessons_per_week: 'Số tiết học/tuần',

                school_grade: 'Điểm trường',

                lesson_session: 'Buổi học',
                is_combined_class: 'Lớp ghép',
                merge_into_class: 'Ghép vào lớp',
                is_specialized_class: ' Lớp chuyên',
                class_division: 'Phân ban',
                is_vnen_class: 'Lớp VNEN',
                is_vocational_student: 'Học sinh học nghề',
                is_sufficient_teaching_equipment_vietnamese:
                    'Đủ Thiết Bị Dạy Học - Tiếng Việt',
                is_sufficient_teaching_equipment_math:
                    'Đủ Thiết Bị Dạy Học - Toán',
                is_parent_representative_class:
                    'Có Đại diện cha, mẹ học sinh lớp',
                is_parent_representative_school: 'Có Đại diện cha, mẹ hs trường',

                homeroom_teacher: 'Giáo viên chủ nhiệm',
                is_merged_children_group: 'Nhóm trẻ ghép',
                is_new_early_childhood_education_program:
                    'Chương trình giáo dục Mầm non mới',
                is_care_education_program: 'Chương trình chăm sóc giáo dục',
                is_children_with_disabilities: 'Có trẻ khuyết tật',
                foreign_language_1: 'Học ngoại ngữ 1',

                curriculum_foreign_language_1: 'Chương trình học (NN1)',
                number_of_lessons_per_week_foreign_language_1:
                    'Số tiết NN/tuần (NN1)',
                foreign_language_2: 'Học ngoại ngữ 2',
                curriculum_foreign_language_2: 'Chương trình học (NN2)',
                number_of_lessons_per_week_foreign_language_2:
                    'Số tiết NN/tuần (NN2)',
                is_boarding_school: 'Bán trú',
                boarding_school_education: 'Học bán trú',
                is_specialized: 'Chuyên biệt',
                learning_method: 'Hình thức học tập',
                training: 'Đào tạo, bồi dưỡng',
                vocational_education_career_orientation: 'Hướng nghiệp dạy nghề',
                used_textbooks: 'Bộ sách giáo khoa sử dụng',
                class_type: 'Kiểu lớp',
                school_name: 'Tên điểm trường',
                is_students_with_disabilities: 'Có học sinh khuyết tật',
                vocational_high_school: 'Học nghề phổ thông'
            },
            changedTitles: [],
            backVisible: false,

            school_block: '', // khối học
            grade_level: '', // cấp học
            class_group: '', // Nhóm lớp
            list_age_group: list_age_group,
            age_group: '', // Nhóm tuổi
            name_class: '', // tên lớp
            err_name_class: '',
            code_class: '', // mã lớp
            moet_code: '', // mã định danh
            list_snake_case: list_snake_case,
            snake_case: '', // số buổi học trên tuần
            err_snake_case: '',
            number_of_lessons_per_week: '', // Số tiết học/tuần
            list_school_grade: [],
            school_grade: '', // Điểm trường
            list_lesson_session: list_lesson_session,
            lesson_session: '', // Buổi học
            is_combined_class: false, // Lớp ghép
            merge_into_class: '', // Ghép vào lớp
            is_specialized_class: false, // Lớp chuyên
            class_division: '', // Phân ban
            is_vnen_class: false, // Lớp VNEN
            is_vocational_student: false, // Học sinh học nghề
            is_sufficient_teaching_equipment_vietnamese: false, // Đủ Thiết Bị Dạy Học - Tiếng Việt
            is_sufficient_teaching_equipment_math: false, // Đủ Thiết Bị Dạy Học - Toán
            is_parent_representative_class: false, // Có Đại diện cha, mẹ học sinh lớp
            is_parent_representative_school: false, // Có Đại diện cha, mẹ hs trường

            list_homeroom_teacher: [],
            homeroom_teacher: '', // Giáo viên chủ nhiệm
            is_merged_children_group: false, // Nhóm trẻ ghép
            is_new_early_childhood_education_program: false, // Chương trình giáo dục Mầm non mới
            is_care_education_program: false, // Chương trình chăm sóc giáo dục
            is_children_with_disabilities: false, //  Có trẻ khuyết tật
            foreign_language_1: '', // Học ngoại ngữ 1
            list_curriculum_foreign_language: list_curriculum_foreign_language,
            list_number_of_lessons_per_week_foreign_language:
                list_number_of_lessons_per_week_foreign_language,
            curriculum_foreign_language_1: '', // Chương trình học (NN1)
            number_of_lessons_per_week_foreign_language_1: '', // Số tiết NN/tuần (NN1)
            foreign_language_2: '', // Học ngoại ngữ 2
            curriculum_foreign_language_2: '', // Chương trình học (NN2)
            number_of_lessons_per_week_foreign_language_2: '', // Số tiết NN/tuần (NN2)
            is_boarding_school: '', // Bán trú
            boarding_school_education: '', // Học bán trú
            is_specialized: '', // Chuyên biệt
            learning_method: '', // Hình thức học tập
            training: '', // Đào tạo, bồi dưỡng
            vocational_education_career_orientation: '', // Hướng nghiệp dạy nghề
            used_textbooks: '', // Bộ sách giáo khoa sử dụng
            class_type: '', // Kiểu lớp
            school_name: '', // tên điểm trường
            is_students_with_disabilities: false, // có học sinh khuyết tật
            vocational_high_school: '', // Học nghề phổ thông
            // Học CTGD của Bộ GD&ĐT về SKSS và HIV/ADS dựa trên KNS
            namHoc: '',
            maTruongHoc: '',

            viewVisible: false,
            info_class: {},

            active_tab_info: 1,
            list_tab_info: [{ number: 1, label: 'Thông tin chung' }],

            errModal: false
        }
    },

    methods: {
        isGradeLevelValid(allowedValues) {
            return allowedValues.includes(this.info_class.capHoc)
        },
        getContentToolBar() {
            return this.viewVisible
                ? 'Thông tin lớp học'
                : 'Chỉnh sửa thông tin lớp học'
        },
        navigateLockUpClass() {
            this.$router.push({ path: path.lock_up.class.home })
            this.changedTitles = []
        },

        openModalBack() {
            this.changedTitles.length === 0
                ? this.navigateLockUpClass()
                : (this.backVisible = true)
        },
        closeModalBack() {
            this.backVisible = false
        },
        async get_list_school_grade() {
            const request_params = {
                namHoc: this.namHoc,
                maTruong: this.maTruongHoc
            }

            const response = await sendRequest(
                Api.tra_cuu.tra_cuu_lop_hoc.diem_truong,
                null,
                this.request_headers,
                request_params
            )
            this.list_school_grade = convertListData(
                response.rows,
                'tenDiemTruong',
                'maDiemTruong'
            )
        },
        async get_list_homeroom_teacher() {
            const request_params = {
                namHoc: this.namHoc,
                maTruong: this.maTruongHoc
            }

            const response = await sendRequest(
                Api.tra_cuu.tra_cuu_lop_hoc.giao_vien_chu_nhiem,
                null,
                this.request_headers,
                request_params
            )
            this.list_homeroom_teacher = convertListData(
                response.rows,
                'tenCanBo',
                'maGiaoVien'
            )
        },
        async get_data_info_class() {
            const request_params = {
                id: this.$route.params.id
            }
            try {
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_lop_hoc.thong_tin_lop_hoc,
                    null,
                    this.request_headers,
                    request_params
                )
                if (response) {
                    this.info_class = response
                    this.namHoc = response.namHoc
                    this.maTruongHoc = response.maTruongHoc
                    if (this.namHoc && this.maTruongHoc) {
                        this.get_list_school_grade()
                        this.get_list_homeroom_teacher()
                    }

                    this.school_block = response.khoiHoc
                    this.grade_level = response.capHoc
                    this.name_class = response.tenLopHoc
                    this.class_group = getLabelByArray(
                        list_grade_level,
                        response.capHoc
                    )
                    this.age_group = response.maNhomTuoiMn
                    this.code_class = response.maLopHoc
                    this.moet_code = response.moetCode
                    this.snake_case = response.soBuoiTrenTuan
                    this.number_of_lessons_per_week = response.maTietHoc
                    this.school_grade = response.maDiemTruong
                    this.lesson_session = response.buoiHoc
                    this.is_combined_class = convertToBoolean(response.lopGhep)
                    this.merge_into_class = response.maLopGhep
                    this.is_specialized_class = convertToBoolean(
                        response.lopChuyen
                    )
                    this.class_division = response.phanBan?.toString()
                    this.is_vnen_class = convertToBoolean(response.lopVnen)
                    this.is_vocational_student = response.coHocSinhHocNghe

                    this.is_sufficient_teaching_equipment_vietnamese =
                        response.thietBiDhocTviet
                    this.is_sufficient_teaching_equipment_math =
                        response.thietBiDhocToan
                    this.is_parent_representative_class = response.daiDienChaMeLop
                    this.is_parent_representative_school =
                        response.daiDienChaMeTruong

                    this.homeroom_teacher = response.maGvcn
                    this.is_merged_children_group = convertToBoolean(
                        response.lopGhep
                    )
                    this.is_new_early_childhood_education_program =
                        response.ctrinhGdmnMoi
                    this.is_care_education_program = response.ctrinhCsocGduc
                    this.is_children_with_disabilities =
                        response.lopCoTreKhuyetTat
                    this.foreign_language_1 = response.maNn1
                    this.curriculum_foreign_language_1 =
                        response.maChuongTrinhHocNn1
                    this.number_of_lessons_per_week_foreign_language_1 =
                        response.maSoTietNgoaiNgu1
                    this.foreign_language_2 = response.maNn2
                    this.curriculum_foreign_language_2 =
                        response.maChuongTrinhHocNn2
                    this.number_of_lessons_per_week_foreign_language_2 =
                        response.maSoTietNgoaiNgu2
                    this.is_boarding_school = convertToBoolean(response.lopBanTru)
                    this.boarding_school_education = response.maHocBanTru
                    this.is_specialized = response.lopChuyenBiet
                    this.learning_method = response.maHthucDtaoHtap
                    this.training = response.maLopDtaoBduong
                    this.vocational_education_career_orientation =
                        response.maLopHnghiepDayNghe
                    this.used_textbooks = response.maBoSgkSuDung
                    this.class_type = response.maKieuLop
                    this.school_name = response.tenDiemTruong

                    this.vocational_high_school = response.coHocSinhHocNghe
                }
            } catch (error) {
                console.log(error)
            }
        },

        onChangeHandler(source, newValue) {
            this.changedTitles = updateChangedTitles(
                this.objectTitle,
                this.changedTitles,
                source,
                newValue
            )

            const errorFields = {
                name_class: 'err_name_class',
                snake_case: 'err_snake_case'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        async handleUpdate() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.name_class,
                        errorField: 'err_name_class'
                    },
                    this.isGradeLevelValid([1, 2, 3])
                        ? {
                              value: this.snake_case,
                              errorField: 'err_snake_case'
                          }
                        : null
                ].filter((item) => item !== null)
                if (validateFields(this, fieldsToValidate)) {
                    const request_data = {
                        updateLopHocRequest: {
                            buoiHoc: this.lesson_session,
                            capHoc: this.info_class.capHoc,
                            coHocSinhHocNghe: this.is_vocational_student,
                            ctrinhCsocGduc: this.is_care_education_program,
                            ctrinhGdmnMoi:
                                this.is_new_early_childhood_education_program,
                            daiDienChaMeLop: this.is_parent_representative_class,
                            daiDienChaMeTruong:
                                this.is_parent_representative_school,
                            hash: this.info_class.hash,
                            hocBanTru: this.info_class.hocBanTru,
                            khoiHoc: this.info_class.khoiHoc,
                            lopBanTru: convertToBinary(this.is_boarding_school),
                            lopChuyen: convertToBinary(this.is_specialized_class),
                            lopChuyenBiet: this.is_specialized,
                            lopCoTreKhuyetTat: this.is_children_with_disabilities,
                            lopGdtx: this.info_class.lopGdtx,
                            lopGhep:
                                convertToBinary(this.is_combined_class) ||
                                convertToBinary(this.is_merged_children_group),
                            lopGhepDoTuoi: this.info_class.lopGhepDoTuoi,
                            lopVnen: convertToBinary(this.is_vnen_class),
                            maBoSgkSuDung: this.used_textbooks,
                            maChuongTrinhHocNn1:
                                this.curriculum_foreign_language_1,
                            maChuongTrinhHocNn2:
                                this.curriculum_foreign_language_2,
                            maDiemTruong: this.school_grade,
                            maDoiTac: this.info_class.maDoiTac,
                            maGvcn: this.homeroom_teacher,
                            maGvcnCn: this.homeroom_teacher,
                            maHeChuyen: this.info_class.maHeChuyen,
                            maHocBanTru: this.boarding_school_education,
                            maHthucDtaoHtap: this.learning_method,
                            maKieuLop: this.class_type,
                            maLopDtaoBduong: this.training,
                            maLopGhep: this.merge_into_class,
                            maLopHnghiepDayNghe:
                                this.vocational_education_career_orientation,
                            maLopHoc: this.info_class.maLopHoc,
                            maNhomTuoiMn: this.info_class.maNhomTuoiMn,
                            maNn1: this.foreign_language_1,
                            maNn2: this.foreign_language_2,
                            maSoTietNgoaiNgu: this.info_class.maSoTietNgoaiNgu,
                            maSoTietNgoaiNgu1:
                                this
                                    .number_of_lessons_per_week_foreign_language_1,
                            maSoTietNgoaiNgu2:
                                this
                                    .number_of_lessons_per_week_foreign_language_2,
                            maSoTietNn1: this.info_class.maSoTietNn1,
                            maSoTietNn2: this.info_class.maChuongTrinhHocNn2,
                            maTietHoc: this.number_of_lessons_per_week,
                            maTruongHoc: this.info_class.maTruongHoc,
                            message: this.info_class.message,
                            moetCode: this.info_class.moetCode,
                            namHoc: this.info_class.namHoc,
                            phanBan: Number(this.class_division),
                            phanBanChiTiet: this.info_class.phanBanChiTiet,
                            qlntCode: this.info_class.qlntCode,
                            reqMesId: this.info_class.reqMesId,
                            soBuoiTrenTuan: this.snake_case,
                            sync: this.info_class.sync,
                            syncLanDay: this.info_class.syncLanDay,
                            syncMaDoiTac: this.info_class.syncMaDoiTac,
                            syncMaPhongGd: this.info_class.syncMaPhongGd,
                            syncMaSoGd: this.info_class.syncMaSoGd,
                            syncMaTruongHoc: this.info_class.syncMaTruongHoc,
                            syncNgayCapNhat: this.info_class.syncNgayCapNhat,
                            syncVersion: this.info_class.syncVersion,
                            tenBuoiHoc: this.info_class.tenBuoiHoc,
                            tenDiemTruong: this.school_name,
                            tenDonViQuanLy: this.info_class.tenDonViQuanLy,
                            tenGvcn: this.info_class.tenGvcn,
                            tenKhoiHoc: this.info_class.tenKhoiHoc,
                            tenLopHoc: this.name_class,
                            tenNgoaiNgu1: this.info_class.tenNgoaiNgu1,
                            tenNgoaiNgu2: this.info_class.tenNgoaiNgu2,
                            tenPhanBan: this.info_class.tenPhanBan,
                            tenTruongHoc: this.info_class.tenTruongHoc,
                            thietBiDhocToan:
                                this.is_sufficient_teaching_equipment_math,
                            thietBiDhocTviet:
                                this.is_sufficient_teaching_equipment_vietnamese,
                            thuTu: this.info_class.thuTu,
                            trangThaiDdlBo: this.info_class.trangThaiDdlBo
                        }
                    }
                    const response = await sendRequest(
                        Api.tra_cuu.tra_cuu_lop_hoc.chinh_sua_lop_hoc,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        this.navigateLockUpClass()
                        showNotication(this, 'success', response.rd)
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_data_info_class()

        this.viewVisible = this.$route.query.viewVisible === 'true'
    },

    computed: {
        list_number_of_lessons_per_week() {
            return getFromSessionStorage('DM_TIET_HOC')
        },
        list_foreign_language() {
            return getFromSessionStorage('DM_NGOAI_NGU')
        },
        list_boarding_school_education() {
            return getFromSessionStorage('DM_HOC_BAN_TRU')
        },
        list_learning_method() {
            return getFromSessionStorage('DM_HINH_THUC_DAO_TAO_HOC_TAP')
        },
        list_training() {
            return getFromSessionStorage('DM_LOP_DAO_TAO_BOI_DUONG')
        },
        list_vocational_education_career_orientation() {
            return getFromSessionStorage('DM_LOP_HUONG_NGHIEP_DAY_NGHE')
        },
        list_used_textbooks() {
            return getFromSessionStorage('DM_BO_SACH_GIAO_KHOA_SU_DUNG')
        },
        list_class_type() {
            return getFromSessionStorage('DM_KIEU_LOP')
        },
        list_class_division() {
            return getFromSessionStorage('DM_PHAN_BAN')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user?.token
            }
        }
    }
}
</script>
<style scoped></style>
