<template>
    <div>
        <ToolBar
            :menu="'Tiếp nhận dữ liệu QLNT'"
            :content="'Cấu hình lịch đồng bộ'"
            :subContent="'Cấu hình lịch đồng bộ cấp Trường'"
        >
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    @click="openModalAddNew"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :multiple="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @input="get_list_school"
                    />
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_status"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :multiple="true"
                        :fields="['tenTruongHoc', 'maTruongHoc']"
                        v-model="school"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="2">
            <template v-slot:btn-table>
                <span class="count" v-if="isCheckedCount > 0">
                    <b>Đã chọn {{ isCheckedCount }} bản ghi</b>
                </span>
                <CustomButton label="Khóa" @click="openModalStatusAll(0)" />
                <CustomButton label="Mở khóa" @click="openModalStatusAll(1)" />
                <CustomButton label="Chỉnh sửa" @click="openModalUpdateAll" />
            </template>
            <template v-slot:header>
                <tr>
                    <th>
                        <input
                            type="checkbox"
                            v-model="selectAllChecked"
                            @change="handleSelectAllCheckbox"
                            :disabled="tableData.length === 0"
                        />
                    </th>
                    <th>STT</th>

                    <th>Đơn vị</th>
                    <th>Mã trường</th>
                    <th>Tên trường</th>
                    <th>Ngày bắt đầu</th>
                    <th>Ngày kết thúc</th>
                    <th>Trạng thái</th>

                    <th>Thao tác</th>
                </tr>
            </template>
            <template v-slot:body>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td class="text-tbody">
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckbox(item)"
                        />
                    </td>
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>

                    <td class="text-left">{{ item.tenDonVi }}</td>
                    <td class="text-left">{{ item.maTruongHoc }}</td>

                    <td class="text-left">{{ item.tenTruongHoc }}</td>
                    <td>
                        {{ item.tuNgay }}
                    </td>
                    <td>
                        {{ item.denNgay }}
                    </td>
                    <td>
                        <TextStatus
                            :content="
                                item.status === 1
                                    ? 'Đang hoạt động'
                                    : 'Ngưng hoạt động'
                            "
                            :status="item.status"
                        />
                    </td>
                    <td>
                        <span v-if="item.status === 1">
                            <CustomToolTip content="Khóa">
                                <img
                                    :src="iconLock"
                                    class="icon"
                                    @click="
                                        openModalStatusOne(item.maTruongHoc, 0)
                                    "
                                />
                            </CustomToolTip>
                        </span>
                        <span v-else>
                            <CustomToolTip content="Mở khóa">
                                <img
                                    :src="iconUnLock"
                                    class="icon"
                                    @click="
                                        openModalStatusOne(item.maTruongHoc, 1)
                                    "
                                />
                            </CustomToolTip>
                        </span>
                        <span>
                            <CustomToolTip content="Chỉnh sửa">
                                <img
                                    :src="iconEdit"
                                    class="icon"
                                    @click="openModalUpdateOne(item.maTruongHoc)"
                                /> </CustomToolTip
                        ></span>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>

        <ModalAddNew
            :closeModalAddNew="closeModalAddNew"
            :addNewVisible="addNewVisible"
            @handleSearch="handleSearch"
        />
        <ModalStatus
            :closeModalStatus="closeModalStatus"
            :statusVisible="statusVisible"
            @handleSearch="handleSearch"
            :dataStatus="dataStatus"
            :statusUpdate="statusUpdate"
        />
        <ModalUpdate
            :closeModalUpdate="closeModalUpdate"
            :updateVisible="updateVisible"
            @handleSearch="handleSearch"
            :dataUpdate="dataUpdate"
        />
    </div>
</template>

<script>
import CustomToolTip from '@/components/CustomToolTip.vue'
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { countCheckedItems } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import ModalAddNew from './modal_add.vue'
import ModalStatus from './modal_status.vue'
import ModalUpdate from './modal_update.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconLock from '@/assets/img/icon-table/lock.svg'
import iconUnLock from '@/assets/img/icon-table/un-lock.svg'
import TextStatus from '@/components/TextStatus.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
import { showNotication } from '@/utils/notification'
export default {
    name: 'SchoolLevel',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        ModalAddNew,
        ModalStatus,
        ModalUpdate,
        CustomPagination,
        CustomToolTip,
        TextStatus
    },
    data() {
        return {
            iconEdit,
            iconLock,
            iconUnLock,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            selectAllChecked: false,

            unit_education: [],
            list_school: [],
            school: [],
            list_status: [
                {
                    label: 'Đang hoạt động',
                    value: '1'
                },
                {
                    label: 'Ngừng hoạt động',
                    value: '0'
                }
            ],
            status: '',

            tableData: [],
            isCheckedCount: 0,
            addNewVisible: false,

            statusVisible: false,
            dataStatus: [],
            statusUpdate: 0,

            updateVisible: false,
            dataUpdate: []
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        openModalAddNew() {
            this.addNewVisible = true
        },
        closeModalAddNew() {
            this.addNewVisible = false
        },
        openModalStatusAll(status) {
            const dataStatusAll = this.tableData.filter((item) => item.isChecked)
            if (dataStatusAll.length === 0) {
                showNotication(this, 'error', 'Vui lòng chọn bản ghi')
                return
            }
            this.statusUpdate = status
            this.dataStatus = dataStatusAll
            this.statusVisible = true
        },
        openModalStatusOne(maTruongHoc, status) {
            this.tableData.forEach((item) => {
                item.isChecked = false
            })
            this.selectAllChecked = false
            const dataStatusOne = this.tableData.filter(
                (item) => item.maTruongHoc === maTruongHoc
            )
            dataStatusOne.forEach((item) => {
                item.isChecked = true
            })
            this.statusUpdate = status
            this.statusVisible = true
            this.dataStatus = dataStatusOne
        },
        closeModalStatus() {
            if (this.dataStatus) {
                this.dataStatus.forEach((item) => {
                    item.isChecked = false
                })
            }
            this.selectAllChecked = false
            this.statusVisible = false
        },
        openModalUpdateAll() {
            const dataUpdateAll = this.tableData.filter((item) => item.isChecked)
            if (dataUpdateAll.length === 0) {
                showNotication(this, 'error', 'Vui lòng chọn bản ghi')
                return
            }
            this.dataUpdate = dataUpdateAll
            this.updateVisible = true
        },
        openModalUpdateOne(maTruongHoc) {
            this.tableData.forEach((item) => {
                item.isChecked = false
            })
            this.selectAllChecked = false
            const dataUpdateOne = this.tableData.filter(
                (item) => item.maTruongHoc === maTruongHoc
            )
            dataUpdateOne.forEach((item) => {
                item.isChecked = true
            })

            this.updateVisible = true
            this.dataUpdate = dataUpdateOne
        },
        closeModalUpdate() {
            if (this.dataUpdate) {
                this.dataUpdate.forEach((item) => {
                    item.isChecked = false
                })
            }
            this.selectAllChecked = false
            this.updateVisible = false
        },
        async get_list_school() {
            this.school = []

            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = response.rows.map((item) => ({
                ...item,
                tenTruongHoc: `${item.tenTruongHoc} - [${item.maTruongHoc}]`
            }))
        },
        toggleCheckbox(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
        },
        handleSelectAllCheckbox() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked
            })
        },

        async handleSearch() {
            try {
                const request_data = {
                    dsCapHoc: this.selectedGradeLevelFromStore,
                    dsMaDonVi: this.unit_education,

                    dsMaTruong: this.school,
                    maSo: this.auth_user.ma_so,
                    namHoc: this.selectedYearFromStore,
                    status: this.status
                }

                const request_params = {
                    start: this.start,
                    limit: this.limit
                }

                const response = await sendRequest(
                    Api.tiep_nhan_du_lieu_QLNT.cau_hinh_lich_dong_bo.cap_truong,
                    request_data,
                    this.request_headers,
                    request_params
                )

                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                } else if (response.rc == 2) {
                    showNotication(this, 'error', response.rd)
                }
                this.total_rows = response.totalRows

                this.tableData = response.tableData.map((item) => {
                    return { ...item, isChecked: false }
                })
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_list_school()
        // this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore(newYear, oldYear) {
            if (newYear) {
                this.get_list_school()
            }
        },
        tableData: {
            handler(newVal) {
                this.isCheckedCount = countCheckedItems(newVal)
            },
            deep: true
        },

        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_phong ? [user.ma_phong] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
