<template>
    <div
        v-if="!toggleDisplay"
        class="form-search"
        :style="{ height: formHeight }"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        columnForm: {
            type: Number,
            default: 1
        }
    },
    computed: {
        toggleDisplay() {
            return this.$store.state.toggleDisplay.toggleDisplay
        },
        formHeight() {
            return this.toggleDisplay ? '0px' : `${this.columnForm * 50 + 20}px`
        }
    }
}
</script>

<style scoped>
.form-search {
    padding: 10px 20px 10px 20px;
    line-height: 50px;
}

.label {
    margin-right: 10px;
    font-weight: 600;
    min-width: 100px;
}

.label-wrapper {
    position: absolute;
    right: 0;
}
.control-label {
    font-size: 15px;
}

@media only screen and (max-width: 767px) {
    .form-search {
        line-height: 0px;
        height: 100% !important;
        padding: 20px;
    }
    .row {
        flex-direction: column;
    }

    .label-wrapper {
        position: static;
        text-align: left;
        margin-bottom: 5px;
    }
    .mobi {
        margin-top: 20px;
    }
}
</style>
