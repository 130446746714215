<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="dissolveSchoolsVisible"
        :modal-title="'Giải thể'"
        :close-function="closeModalDissolveSchools"
        :size="'lg'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <strong>
                Thao tác giải thể trường học sẽ xóa các thông tin liên quan sau
            </strong>

            <ul>
                <li>Hồ sơ lớp học</li>
                <li>Hồ sơ nhân sự</li>
                <li>Hồ sơ học sinh</li>
                <li>Đánh giá chuẩn nghề nghiệp nhân sự</li>
                <li>Các quá trình nhân sự</li>
                <li>Báo cáo EMIS</li>
            </ul>
            <strong class="color-red">
                Lưu ý: Trường giải thể sẽ không khôi phục lại được dữ liệu
            </strong>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleDissolveSchools"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalmodalDissolveSchools',
    data() {
        return {
            errModal: false
        }
    },
    props: {
        dissolveSchoolsVisible: {
            type: Boolean,
            required: true
        },
        dataModalDissolveSchools: {
            type: Object,
            require: true
        },
        closeModalDissolveSchools: Function,
        handleSearch: Function
    },

    components: { CustomButton, CustomModal },
    methods: {
        async handleDissolveSchools() {
            try {
                const request_params = {
                    maTruongHoc: this.dataModalDissolveSchools.maTruongHoc
                }

                const response = await sendRequest(
                    Api.he_thong.giai_the,
                    null,
                    this.request_headers,
                    request_params
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    this.closeModalDissolveSchools()
                    this.$emit('handleSearch')
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
ul {
    list-style-type: disc;
    margin-left: 50px;
    margin-top: 5px;
}

li {
    margin-bottom: 5px;
}
</style>
