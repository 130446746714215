<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="viewVisible"
        :modal-title="'Điểm chi tiết môn'"
        :close-function="closeModalView"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-md-6">
                    <label class="label">Học kỳ I</label>
                    <CustomTable
                        :tableModal="true"
                        :hidePagi="true"
                        :hideBtnTable="true"
                    >
                        <template v-slot:header>
                            <tr>
                                <th>STT</th>
                                <th>Môn học</th>
                                <th>Loại điểm</th>
                                <th>Điểm số</th>
                                <th>Nhận xét</th>
                            </tr>
                        </template>
                        <template v-slot:body>
                            <template v-if="data_semester_1.length === 0">
                                <tr>
                                    <td colspan="9">Không có bản ghi nào.</td>
                                </tr>
                            </template>
                            <tr
                                v-for="(item, index) in data_semester_1"
                                :key="index"
                            >
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td class="text-left">
                                    {{ item.tenMonHoc }}
                                </td>
                                <td>
                                    {{ item.loaiDiem }}
                                </td>
                                <td>
                                    {{ item.diemSo }}
                                </td>
                                <td>
                                    {{ item.nhanXetCuaGv }}
                                </td>
                            </tr>
                        </template>
                    </CustomTable>
                </div>
                <div class="col-md-6">
                    <label class="label">Học kỳ II</label>
                    <CustomTable
                        :tableModal="true"
                        :hidePagi="true"
                        :hideBtnTable="true"
                    >
                        <template v-slot:header>
                            <tr>
                                <th>STT</th>
                                <th>Môn học</th>
                                <th>Loại điểm</th>
                                <th>Điểm số</th>
                                <th>Nhận xét</th>
                            </tr>
                        </template>
                        <template v-slot:body>
                            <template v-if="data_semester_2.length === 0">
                                <tr>
                                    <td colspan="9">Không có bản ghi nào.</td>
                                </tr>
                            </template>
                            <tr
                                v-for="(item, index) in data_semester_2"
                                :key="index"
                            >
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td class="text-left">
                                    {{ item.tenMonHoc }}
                                </td>
                                <td>
                                    {{ item.loaiDiem }}
                                </td>
                                <td>
                                    {{ item.diemSo }}
                                </td>
                                <td>
                                    {{ item.nhanXetCuaGv }}
                                </td>
                            </tr>
                        </template>
                    </CustomTable>
                </div>
            </div>
        </template>
    </CustomModal>
</template>

<script>
import CustomTable from '@/components/CustomTable.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalView',
    data() {
        return {
            errModal: false,
            data_semester_1: [],
            data_semester_2: []
        }
    },
    props: {
        viewVisible: {
            type: Boolean,
            required: true
        },
        dataView: {
            type: Object,
            require: true
        },
        closeModalView: Function,
        handleSearch: Function
    },
    watch: {
        viewVisible(newVal) {
            if (newVal === true) {
                this.getDataSemester()
            }
        }
    },

    components: { CustomModal, CustomTable },
    methods: {
        async getDataSemester() {
            try {
                const request_data = {
                    maHocSinh: this.dataView.maHocSinh,
                    namHoc: this.dataView.namHoc,
                    hocKy: this.dataView.hocKy
                }
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_kqht.xem_diem,
                    request_data,
                    this.request_headers,
                    null
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    this.data_semester_1 = response.ki1
                    this.data_semester_2 = response.ki2
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },

        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
