import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import '../public/css/reset.css'
import '../public/css/color.css'
import '../public/css/custom_global.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lang from 'element-ui/lib/locale/lang/vi'
import locale from 'element-ui/lib/locale'
import '../public/css/color.css'

locale.use(lang)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// console.log = function () {}

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
