<template>
    <div class="main-wrapper">
        <Header v-if="!navigation" />

        <Navbar v-if="!navigation" />

        <aside class="page">
            <router-view />
        </aside>
        <CustomModal
            :is-modal-visible="onlineStatusVisible"
            :modal-title="'Cảnh báo mất kết nối'"
            :close-function="closeModalOnlineStatus"
        >
            <template v-slot:content>
                <div class="custom-modal">
                    <img :src="wifiOff" />
                    <span class="text-error-select"
                        >Bạn bị mất kết nối. Hãy kiểm tra lại
                    </span>
                </div>
            </template>
        </CustomModal>
    </div>
</template>

<script>
import Header from './containers/layouts/Header.vue'
import Navbar from './containers/layouts/NavBar.vue'
import CustomModal from '@/components/CustomModal.vue'
import wifiOff from '@/assets/img/app/wifi-off.svg'
export default {
    name: 'app',
    components: { Header, Navbar, CustomModal },
    data() {
        return {
            wifiOff,

            navigation: null,
            isOnline: navigator.onLine,
            onlineStatusVisible: false
        }
    },

    methods: {
        checkRoute() {
            if (this.$route.name === 'Login') {
                this.navigation = true
                return
            } else {
                this.navigation = false
            }
        },
        handleNetworkChange() {
            navigator.onLine
                ? (this.onlineStatusVisible = false)
                : (this.onlineStatusVisible = true)
        },
        closeModalOnlineStatus() {
            this.onlineStatusVisible = false
        }
    },

    created() {
        this.checkRoute()
        window.addEventListener('offline', this.handleNetworkChange)
        window.addEventListener('online', this.handleNetworkChange)
    },

    destroyed() {
        window.removeEventListener('offline', this.handleNetworkChange)
        window.addEventListener('online', this.handleNetworkChange)
    },

    watch: {
        $route: {
            handler(newRoute, oldRoute) {
                this.checkRoute(newRoute, oldRoute)
            },
            immediate: true
        }
    }
}
</script>
<style scoped>
.main-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Inter-Regular', sans-serif;
    overflow: hidden;
    font-size: 14px;
}

.page {
    flex: 1;
}
.error {
    color: red;
    margin-left: 8px;
}
.custom-modal {
    display: flex;
    align-items: center;
}
</style>
