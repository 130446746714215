<template>
    <CustomModal
        :is-modal-visible="updateVisible"
        :modal-title="'Chỉnh sửa đối tác'"
        :close-function="closeModalUpdate"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row d-flex justify-content-center">
                <div class="col-sm-4">
                    <label>Mã đối tác <span class="error-label">*</span></label>
                    <div>
                        <CustomInput
                            v-model="code"
                            @input="onChangeHandler('code', $event)"
                            :textError="err_code"
                            :disabled="true"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>Tên đối tác: <span class="error-label">*</span></label>
                    <div>
                        <CustomInput
                            v-model="name"
                            @input="onChangeHandler('name', $event)"
                            :textError="err_name"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center mt-4">
                <div class="col-sm-4">
                    <label>Tài khoản <span class="error-label">*</span></label>
                    <div>
                        <CustomInput
                            v-model="user_name"
                            @input="onChangeHandler('user_name', $event)"
                            :textError="err_user_name"
                        >
                        </CustomInput>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>Mật khẩu: <span class="error-label">*</span></label>
                    <div>
                        <CustomInput
                            v-model="password"
                            @input="onChangeHandler('password', $event)"
                            :textError="err_password"
                            :show-password="true"
                        >
                        </CustomInput>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center mt-4">
                <div class="col-sm-4">
                    <label>Email:</label>
                    <div>
                        <CustomInput v-model="email"> </CustomInput>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label>White list IP:</label>
                    <div>
                        <CustomInput v-model="ip"> </CustomInput>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleUpdate"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { validateFields, handlerError } from '@/utils/validator'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalUpdate',
    data() {
        return {
            name: '',
            err_name: '',

            code: '',
            err_code: '',

            user_name: '',
            err_user_name: '',

            password: '',
            err_password: '',

            email: '',
            ip: '',

            errModal: false
        }
    },
    props: {
        updateVisible: {
            type: Boolean,
            required: true
        },
        closeModalUpdate: Function,
        dataUpdate: {
            type: Object,
            require: true
        },
        handleSearch: Function
    },
    watch: {
        dataUpdate(newValue) {
            this.code = newValue.maDoiTac
            this.name = newValue.tenDoiTac
            this.user_name = newValue.tenDangNhap
            this.email = newValue.email
            this.ip = newValue.whiteListIp
            this.password = newValue.matKhau
        }
    },
    components: { CustomButton, CustomModal, CustomInput },
    methods: {
        onChangeHandler(source, newValue) {
            const errorFields = {
                name: 'err_name',
                code: 'err_code',
                user_name: 'err_user_name',
                password: 'err_password'
            }

            handlerError(this, source, newValue, errorFields)

            this.errModal = !!newValue
        },
        async handleUpdate() {
            const fieldsToValidate = [
                {
                    value: this.name,
                    errorField: 'err_name'
                },

                {
                    value: this.code,
                    errorField: 'err_code'
                },

                {
                    value: this.user_name,
                    errorField: 'err_user_name'
                },

                {
                    value: this.password,
                    errorField: 'err_password'
                }
            ]

            if (validateFields(this, fieldsToValidate)) {
                const request_data = {
                    maDoiTac: this.code,
                    tenDoiTac: this.name,
                    tenDangNhap: this.user_name,
                    trangThai: 1,
                    matKhau: this.password,
                    whileListIp: this.ip,
                    email: this.email
                }
                try {
                    const response = await sendRequest(
                        Api.he_thong.quan_ly_doi_tac.chinh_sua,
                        request_data,
                        this.request_headers
                    )
                    if (response.code == 200) {
                        showNotication(this, 'success', response.message)
                        this.closeModalUpdate()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.message)
                    }
                } catch (error) {
                    if (error.response?.data.rd) {
                        showNotication(this, 'error', error.response?.data.rd)
                    }
                }
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
