<template>
    <div>
        <ToolBar :menu="'Tài khoản'" :content="'Lịch sử đăng nhập'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="1">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Thời gian truy cập:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <el-date-picker
                        v-model="date"
                        type="daterange"
                        start-placeholder="Từ ngày"
                        end-placeholder="Đến ngày"
                        size="small"
                        format="dd/MM/yyyy"
                    >
                    </el-date-picker>
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="1" :hide-btn-table="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>
                    <th>Tài khoản</th>
                    <th>Trạng thái</th>
                    <th>Thời gian</th>
                </tr>
            </template>
            <template v-slot:body>
                <template v-if="tableData.length === 0">
                    <tr>
                        <td colspan="6">Không có bản ghi nào.</td>
                    </tr>
                </template>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (currentPage - 1) * limit + index + 1 }}
                    </td>
                    <td>
                        {{ item.username }}
                    </td>
                    <td class="text-left">
                        {{ item.responseMessage }}
                    </td>

                    <td>
                        {{ item.loginTimeString }}
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { formatDateToDDMMYYYY } from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'

export default {
    name: 'LoginHistory',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,

        CustomTable,

        CustomPagination
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            start_time: '',
            end_time: '',
            date: '',

            tableData: []
        }
    },

    methods: {
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },

        async handleSearch() {
            try {
                const request_data = {
                    username: this.auth_user.username,
                    startTime: formatDateToDDMMYYYY(this.date[0]),
                    endTime: formatDateToDDMMYYYY(this.date[1]),
                    start: this.start,
                    limit: this.limit
                }
                const response = await sendRequest(
                    Api.tai_khoan.lich_su_dang_nhap,
                    request_data,
                    this.request_headers
                )

                this.tableData = response.data
                this.total_rows = response.total
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.handleSearch()
    },

    computed: {
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style>
::v-deep .el-input__inner {
    border: 1px solid #b2b7bd;
}
::v-deep .el-input__inner {
    color: #373333;
}

::v-deep .el-input__inner:focus {
    border: 1px solid #007bff;
    box-shadow: #b2b7bd;
}

.el-date-table td.start-date span,
.el-date-table td.end-date span {
    background-color: var(--blue-shared);
}
.el-date-table td.today span,
.el-date-table td.available:hover {
    color: var(--blue-shared);
}
</style>
