<template>
    <CustomModal
        :is-modal-visible="updateVisible"
        :modal-title="'Chỉnh sửa tài khoản'"
        :close-function="handleCloseModalUpdate"
        :size="'xl'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            <div class="row">
                <div class="col-md-3">
                    <div>Tên tài khoản <span class="error-label">*</span></div>
                    <CustomInput
                        v-model="account_name"
                        @input="onChangeHandler('account_name', $event)"
                        :textError="err_account_name"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-3">
                    <div>Tên đăng nhập <span class="error-label">*</span></div>

                    <CustomInput
                        v-model="user_name"
                        @input="onChangeHandler('user_name', $event)"
                        :textError="err_user_name"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-3">
                    <div>Email <span class="error-label">*</span></div>

                    <CustomInput
                        v-model="email"
                        @input="onChangeHandler('email', $event)"
                        :textError="err_email"
                        :trimAll="true"
                    >
                    </CustomInput>
                </div>
                <div class="col-md-3">
                    <div>Số điện thoại <span class="error-label">*</span></div>

                    <CustomInput
                        v-model="phone"
                        @input="onChangeHandler('phone', $event)"
                        :textError="err_phone"
                        :trimAll="true"
                    >
                    </CustomInput>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-3">
                    <div>Vai trò <span class="error-label">*</span></div>

                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_role"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="role"
                        @change="onChangeRole"
                        @input="onChangeHandler('role', $event)"
                        :class="{
                            'error-select': err_role.length > 0
                        }"
                    />
                    <span v-if="err_role.length > 0" class="text-error-select">
                        {{ err_role }}
                    </span>
                </div>

                <div v-if="role === 20 || role === 2" class="col-md-3">
                    <div>
                        Đơn vị quản lý
                        <span class="error-label">*</span>
                    </div>

                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="role !== 2"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                    />
                </div>
                <div v-else class="col-md-3">
                    <div>
                        Đơn vị
                        <span class="error-label">*</span>
                    </div>

                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_education_department"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="education_department"
                        @change="onChangeEducationDepartment"
                        @input="onChangeHandler('education_department', $event)"
                        :class="{
                            'error-select': err_education_department.length > 0
                        }"
                    />
                    <span
                        v-if="err_education_department.length > 0"
                        class="text-error-select"
                    >
                        {{ err_education_department }}
                    </span>
                </div>

                <div v-if="role === 5" class="col-md-3">
                    <div>
                        Trường học
                        <span class="text-error-select" v-if="role === 5">*</span>
                    </div>

                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                        @input="onChangeHandler('school', $event)"
                        :class="{
                            'error-select': err_school.length > 0
                        }"
                    />
                    <span
                        v-if="err_school.length > 0 && role === 5"
                        class="text-error-select"
                    >
                        {{ err_school }}
                    </span>
                </div>
            </div>
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handleUpdate"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomInput from '@/components/CustomInput.vue'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { convertListData } from '@/utils'
import { validateFields, handlerError } from '@/utils/validator'
import ESelect from '@/components/ESelect.vue'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalUpdate',
    data() {
        return {
            role: '',

            education_department: '',
            unit_education: '',
            list_school: [],
            school: '',
            account_name: '',
            user_name: '',
            email: '',
            phone: '',

            err_role: '',
            err_account_name: '',
            err_user_name: '',
            err_email: '',
            err_phone: '',
            err_education_department: '',
            err_school: '',

            errModal: false
        }
    },
    props: {
        updateVisible: {
            type: Boolean,
            required: true
        },
        closeModalUpdate: Function,
        handleSearch: Function,
        list_role: {
            type: Array,
            required: true
        },
        list_education_department: {
            type: Array,
            required: true
        },
        dataUpdate: {
            type: Object,
            required: true
        }
    },
    watch: {
        updateVisible(newValue) {
            if (newValue === true) {
                this.account_name = this.dataUpdate.hoTen
                this.user_name = this.dataUpdate.username
                this.email = this.dataUpdate.email
                this.phone = this.dataUpdate.sdt
                this.role = this.dataUpdate.role
                this.education_department = this.dataUpdate.maDonVi
                this.school = this.dataUpdate.maTruongHoc
                this.get_list_school()
            }
        }
    },
    components: { CustomButton, CustomModal, ESelect, CustomInput },
    methods: {
        handleClearData() {
            // this.role = ''
            // this.education_department = ''
            // this.unit_education = ''
            // this.list_school = []
            // this.school = ''
            // this.account_name = ''
            // this.user_name = ''
            // this.email = ''
            // this.phone = ''

            this.err_role = ''
            this.err_account_name = ''
            this.err_user_name = ''
            this.err_email = ''
            this.err_phone = ''
            this.err_education_department = ''
            this.err_school = ''
            this.errModal = false
        },
        handleCloseModalUpdate() {
            this.closeModalUpdate()
            this.handleClearData()
        },
        async get_list_school() {
            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.education_department
                    ? [this.education_department]
                    : []
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        onChangeEducationDepartment() {
            this.school = ''
            this.err_school = ''
            if (this.role == 5) {
                this.get_list_school()
            }
        },
        onChangeRole() {
            if (this.role !== '') {
                this.err_role = ''
            }

            this.unit_education = this.auth_user.ma_so
            this.education_department = ''
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                account_name: 'err_account_name',
                user_name: 'err_user_name',
                email: 'err_email',
                phone: 'err_phone',
                role: 'err_role',
                education_department: 'err_education_department',
                school: 'err_school'
            }

            handlerError(this, source, newValue, errorFields)

            if (source in errorFields) {
                handlerError(this, source, newValue, errorFields)

                this.errModal = !!newValue || !!this[errorFields[source]]
            } else {
                this.errModal = !!newValue
            }
        },
        async handleUpdate() {
            const fieldsToValidate = [
                {
                    value: this.user_name,
                    errorField: 'err_user_name'
                },
                {
                    value: this.account_name,
                    errorField: 'err_account_name'
                },
                {
                    value: this.email,
                    errorField: 'err_email',
                    type: 'email'
                },
                {
                    value: this.phone,
                    errorField: 'err_phone',
                    type: 'phone'
                },
                {
                    value: this.role,
                    errorField: 'err_role'
                },
                {
                    value: this.education_department,
                    errorField: 'err_education_department'
                },
                this.role === 5
                    ? {
                          value: this.school,
                          errorField: 'err_school'
                      }
                    : null
            ].filter((item) => item !== null)

            const isValid = validateFields(this, fieldsToValidate)
            if (isValid) {
                const request_data = {
                    capHocList: this.selectedGradeLevelFromStore,
                    email: this.email,
                    hoTen: this.account_name,

                    isSSo: this.dataUpdate.isSSo,
                    isSsoDienBien: this.auth_user.isSsoDienBien,
                    limit: null,
                    maDonVi: this.education_department,
                    maDonViCha: this.dataUpdate.maTinhThanh,
                    maQuanHuyen: this.dataUpdate.maQuanHuyen,
                    maTinhThanh: this.dataUpdate.maTinhThanh,
                    maTruongHoc: this.school,
                    parentRole: this.role,
                    password: null,
                    role: this.role,
                    roleName: null,
                    sdt: this.phone,
                    start: null,

                    tenDonVi: this.dataUpdate.tenDonVi,
                    trangThai: 1,
                    username: this.user_name
                }
                try {
                    const response = await sendRequest(
                        Api.he_thong.quan_ly_nguoi_dung.chinh_sua,
                        request_data,
                        this.request_headers
                    )
                    if (response.rc == 0) {
                        showNotication(this, 'success', response.rd)

                        this.closeModalUpdate()
                        this.$emit('handleSearch')
                    } else {
                        showNotication(this, 'error', response.rd)
                    }
                } catch (error) {
                    if (error.response?.data.rd) {
                        showNotication(this, 'error', error.response?.data.rd)
                    }
                }
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        }
    }
}
</script>

<style scoped>
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
</style>
