<template>
    <div>
        <ToolBar :menu="'Tra cứu'" :content="'Tra cứu Học sinh'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Xuất Excel"
                    :icon="'el-icon-download'"
                    @click="handleExportExcel"
                    :disabled="tableData.length === 0"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="column_form">
            <div class="row">
                <div class="col-md-2" v-if="isRoleSuperAdmin">
                    <div class="label-wrapper">
                        <label class="control-label">Tỉnh thành:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="isRoleSuperAdmin">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_province"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="province"
                        @change="onChangeProvince"
                    />
                </div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="isRoleEducationOfficeOrSchool || isRoleSchool"
                        :data="list_unit_education"
                        :multiple="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Khối học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_school_block"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="school_block"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trường học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="isRoleSchool"
                        :data="list_school"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="school"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Từ khóa tìm kiếm: </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="key"
                        :disabled="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Mã định danh công dân:
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput
                        v-model="moet_code"
                        :disabled="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tên lớp học: </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="name_class"> </CustomInput>
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Giới tính:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_gender"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="gender"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_status"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Mã định danh bộ GD&ĐT:
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="moetCode"> </CustomInput>
                </div>

                <div v-if="!isRoleSuperAdmin" class="col-md-4"></div>
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Nội dung hiển thị
                            <span class="error-label">*</span>:
                        </label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_content_displayed"
                        :multiple="true"
                        :fields="['title', 'value']"
                        v-model="content_displayed"
                    />
                </div>

                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label"> Tìm kiếm nâng cao:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_advanced_search"
                        :multiple="true"
                        :fields="['name', 'value']"
                        v-model="advanced_search"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('ngaySinh')">
                    <div class="label-wrapper">
                        <label class="control-label">Ngày sinh:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('ngaySinh')">
                    <DatePicker v-model="date_of_birth" />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('noiSinh')">
                    <div class="label-wrapper">
                        <label class="control-label"> Nơi sinh: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('noiSinh')">
                    <CustomInput
                        v-model="place_of_birth"
                        :disabled="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maDanToc')">
                    <div class="label-wrapper">
                        <label class="control-label">Dân tộc:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maDanToc')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_nation"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="nation"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maTonGiao')">
                    <div class="label-wrapper">
                        <label class="control-label">Tôn giáo:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maTonGiao')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_religion"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="religion"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('dchiThtru')">
                    <div class="label-wrapper">
                        <label class="control-label"> Địa chỉ thường trú: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dchiThtru')">
                    <CustomInput
                        v-model="permanent_address"
                        :disabled="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('dchiTmtru')">
                    <div class="label-wrapper">
                        <label class="control-label"> Địa chỉ tạm trú: </label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('dchiTmtru')">
                    <CustomInput
                        v-model="temporary_address"
                        :disabled="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maKhuVuc')">
                    <div class="label-wrapper">
                        <label class="control-label">Khu vực:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maKhuVuc')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_area"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="area"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('loaiNhapHoc')">
                    <div class="label-wrapper">
                        <label class="control-label">Loại nhập học:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('loaiNhapHoc')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_enrollment_type"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="enrollment_type"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maLyDoThoiHoc')">
                    <div class="label-wrapper">
                        <label class="control-label">Lý do thôi học:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maLyDoThoiHoc')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_reason_for_leaving_school"
                        :multiple="true"
                        :fields="['label', 'value']"
                        v-model="reason_for_leaving_school"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('doiVien')">
                    <div class="label-wrapper">
                        <label class="control-label">Đội viên:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('doiVien')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_yes_no"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="team_member"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('doanVien')">
                    <div class="label-wrapper">
                        <label class="control-label">Đoàn viên:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('doanVien')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_yes_no"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="union_member"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('khuyetTat')">
                    <div class="label-wrapper">
                        <label class="control-label">Khuyết tật:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('khuyetTat')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_yes_no"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="disability"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('lopGhep')">
                    <div class="label-wrapper">
                        <label class="control-label">Lớp ghép:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lopGhep')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_yes_no"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="combined_class"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('lopChuyen')">
                    <div class="label-wrapper">
                        <label class="control-label">Lớp chuyên:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('lopChuyen')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_true_false"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="specialized_class"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('danTocThieuSo')">
                    <div class="label-wrapper">
                        <label class="control-label">Dân tộc thiểu số:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('danTocThieuSo')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_true_false"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="ethnic_minority"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hoNgheo')">
                    <div class="label-wrapper">
                        <label class="control-label">Hộ nghèo:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hoNgheo')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_true_false"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="poor_family"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('moetCode')">
                    <div class="label-wrapper">
                        <label class="control-label">Mã định danh:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('moetCode')">
                    <CustomInput
                        v-model="identification_code"
                        :disabled="false"
                        :show-password="false"
                        :showCloseIcon="true"
                    >
                    </CustomInput>
                </div>

                <div class="col-md-2" v-if="shouldShowElement('maDtuongCsach')">
                    <div class="label-wrapper">
                        <label class="control-label">Đối tượng chính sách:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('maDtuongCsach')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_policy_object"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="policy_object"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('isKhamSkDky')">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Kiểm tra sức khoẻ dinh dưỡng:</label
                        >
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('isKhamSkDky')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_true_false"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="health_check_nutrition"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('benhMat')">
                    <div class="label-wrapper">
                        <label class="control-label">Bệnh về mắt:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('benhMat')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_eye_disease"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="eye_disease"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('bietBoiKy1')">
                    <div class="label-wrapper">
                        <label class="control-label">Biết bơi kỳ 1:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('bietBoiKy1')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_true_false"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="swimming_1st_semester"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('bietBoiKy2')">
                    <div class="label-wrapper">
                        <label class="control-label">Biết bơi kỳ 2:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('bietBoiKy2')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_true_false"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="swimming_2nd_semester"
                    />
                </div>

                <div class="col-md-2" v-if="shouldShowElement('hocSinhBanTru')">
                    <div class="label-wrapper">
                        <label class="control-label">Học sinh bán trú:</label>
                    </div>
                </div>
                <div class="col-md-2" v-if="shouldShowElement('hocSinhBanTru')">
                    <ESelect
                        style="width: 100%"
                        :disabled="false"
                        :data="list_true_false"
                        :multiple="false"
                        :fields="['label', 'value']"
                        v-model="boarding_student"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="column_form">
            <template v-slot:btn-table>
                <span class="count" v-if="isCheckedCount > 0">
                    <b>Đã chọn {{ isCheckedCount }} bản ghi</b>
                </span>
                <CustomButton
                    label="Xóa"
                    @click="toggleModal('delete')"
                    :type="'danger'"
                />
            </template>
            <template v-slot:header>
                <th>
                    <input
                        type="checkbox"
                        v-model="selectAllChecked"
                        @change="handleSelectAll"
                        :disabled="tableData.length === 0"
                    />
                </th>
                <th>STT</th>

                <th v-for="field in tableHeader" :key="field.value">
                    {{ field.title }}
                </th>
                <th>Thao tác</th>
            </template>
            <template v-slot:body>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        <input
                            type="checkbox"
                            :checked="item.isChecked"
                            @change="toggleCheckboxTable(item)"
                        />
                    </td>
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>

                    <td v-if="hasProperty('maTruongHoc', item)">
                        {{ item.maTruongHoc }}
                    </td>
                    <td
                        class="text-left min-w-150"
                        v-if="hasProperty('tenTruongHoc', item)"
                    >
                        {{ item.tenTruongHoc }}
                    </td>
                    <td v-if="hasProperty('khoiHoc', item)">
                        {{ item.khoiHoc }}
                    </td>
                    <td v-if="hasProperty('tenLopHoc', item)">
                        {{ item.tenLopHoc }}
                    </td>
                    <td
                        class="text-left text-navigate"
                        v-if="hasProperty('hoTen', item)"
                        @click="navigateViewLockUpStudent(item)"
                    >
                        {{ item.hoTen }}
                    </td>
                    <td v-if="hasProperty('maHocSinh', item)">
                        {{ item.maHocSinh }}
                    </td>
                    <td v-if="hasProperty('moetCode', item)">
                        {{ item.moetCode }}
                    </td>

                    <td v-if="hasProperty('ngaySinh', item)">
                        {{ item.ngaySinh }}
                    </td>
                    <td v-if="hasProperty('gioiTinh', item)">
                        {{ item.gioiTinh }}
                    </td>
                    <td v-if="hasProperty('maDanToc', item)">
                        {{ item.maDanToc }}
                    </td>
                    <td class="text-left" v-if="hasProperty('trangThai', item)">
                        <TextStatus
                            :content="item.trangThai"
                            :status="item.trangThaiInt"
                        />
                    </td>
                    <td class="text-left" v-if="hasProperty('soCmnd', item)">
                        {{ item.soCmnd }}
                    </td>
                    <td class="text-left" v-if="hasProperty('ngayCapCmnd', item)">
                        {{ item.ngayCapCmnd }}
                    </td>
                    <td class="text-left" v-if="hasProperty('noiCapCmnd', item)">
                        {{ item.noiCapCmnd }}
                    </td>
                    <td class="text-left" v-if="hasProperty('sdt', item)">
                        {{ item.sdt }}
                    </td>

                    <td class="text-left" v-if="hasProperty('email', item)">
                        {{ item.email }}
                    </td>

                    <td
                        class="text-left min-w-250"
                        v-if="hasProperty('dchiThtru', item)"
                    >
                        {{ item.dchiThtru }}
                    </td>
                    <td
                        class="text-left min-w-250"
                        v-if="hasProperty('dchiTmtru', item)"
                    >
                        {{ item.dchiTmtru }}
                    </td>
                    <td
                        class="text-left min-w-250"
                        v-if="hasProperty('noiSinh', item)"
                    >
                        {{ item.noiSinh }}
                    </td>
                    <td v-if="hasProperty('hoNgheo', item)">
                        {{ item.hoNgheo }}
                    </td>
                    <td
                        class="text-left min-w-250"
                        v-if="hasProperty('maDtuongCsach', item)"
                    >
                        {{ item.maDtuongCsach }}
                    </td>

                    <td class="text-left" v-if="hasProperty('hoTenBo', item)">
                        {{ item.hoTenBo }}
                    </td>
                    <td class="text-left" v-if="hasProperty('hoTenMe', item)">
                        {{ item.hoTenMe }}
                    </td>
                    <td v-if="hasProperty('citizenId', item)">
                        {{ item.citizenId }}
                    </td>

                    <td v-if="hasProperty('kiemTraSucKhoeDinhDuong', item)">
                        {{ item.kiemTraSucKhoeDinhDuong }}
                    </td>

                    <td v-if="hasProperty('chieuCao', item)">
                        {{ item.chieuCao }}
                    </td>
                    <td v-if="hasProperty('chieuCaoKy2', item)">
                        {{ item.chieuCaoKy2 }}
                    </td>
                    <td v-if="hasProperty('canNang', item)">
                        {{ item.canNang }}
                    </td>
                    <td v-if="hasProperty('canNangKy2', item)">
                        {{ item.canNangKy2 }}
                    </td>
                    <td v-if="hasProperty('benhMat', item)">
                        {{ item.benhMat }}
                    </td>
                    <td v-if="hasProperty('bietBoiKy1', item)">
                        {{ item.bietBoiKy1 }}
                    </td>
                    <td v-if="hasProperty('bietBoiKy2', item)">
                        {{ item.bietBoiKy2 }}
                    </td>
                    <td v-if="hasProperty('hocSinhBanTru', item)">
                        {{ item.hocSinhBanTru }}
                    </td>
                    <td>
                        <CustomToolTip content="Cập nhật hồ sơ">
                            <img
                                :src="iconEdit"
                                class="icon"
                                @click="navigateUpdateLockUpStudent(item)"
                            />
                        </CustomToolTip>
                        <CustomToolTip content="Chuyển trường">
                            <img
                                :src="iconMove"
                                class="icon"
                                @click="openModalSchoolTransfer(item)"
                            />
                        </CustomToolTip>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <ModalDelete
            :deleteVisible="deleteVisible"
            :closeModalDelete="() => closeModal('delete')"
            :dataDelete="selectedItemsTable"
            @handleSearch="handleSearch"
        />
        <ModalSchoolTransfer
            :schoolTransferVisible="schoolTransferVisible"
            :closeModalSchoolTransfer="closeModalSchoolTransfer"
            :dataSchoolTransfer="dataSchoolTransfer"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import {
    convertListData,
    calculateColumnFormLength,
    generateDataSearchList,
    setInitialContentDisplayed,
    updateContentDisplayed,
    addIsCheckedProperty,
    countCheckedItems,
    updateSelectedItemsTable,
    convertDownloadFileExcel,
    hasProperty,
    filterItemKeysTableData,
    getLabelByArray,
    filterListSchoolBlock,
    processUnitEducationList,
    formatDateToDDMMYYYY
} from '@/utils'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomInput from '@/components/CustomInput.vue'
import {
    list_gender,
    list_status,
    list_advanced_search,
    list_content_displayed,
    list_yes_no,
    list_true_false
} from '@/mock_data/tra-cuu-hoc-sinh'
import { list_school_block } from '@/mock_data'
import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconMove from '@/assets/img/icon-table/move.svg'
import ModalDelete from './modal_delete.vue'
import ModalSchoolTransfer from './modal_school_transfer.vue'
import { path } from '@/router/path'
import DatePicker from '@/components/DatePicker.vue'
import CustomToolTip from '@/components/CustomToolTip.vue'
import TextStatus from '@/components/TextStatus.vue'
import { ROLES, checkUserRole } from '@/constants/Roles'
import { showNotication } from '@/utils/notification'
export default {
    name: 'LookUpStudent',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect,
        CustomTable,
        CustomPagination,
        CustomInput,
        ModalDelete,
        ModalSchoolTransfer,
        DatePicker,
        CustomToolTip,
        TextStatus
    },
    data() {
        return {
            iconEdit,
            iconMove,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            isCheckedCount: 0,
            selectAllChecked: false,
            selectedItemsTable: [],
            province: '',
            list_unit_education: [],
            unit_education: [],

            list_school_block: list_school_block,
            school_block: [],

            list_school: [],
            school: [],

            key: '',

            moet_code: '',

            name_class: '',

            list_status: list_status,
            status: '',

            moetCode: '',

            list_gender: list_gender,
            gender: '',

            list_content_displayed: list_content_displayed,
            content_displayed: [],

            list_advanced_search: list_advanced_search,
            advanced_search: [],

            list_yes_no: list_yes_no,
            list_true_false: list_true_false,

            date_of_birth: '', // Ngày sinh
            place_of_birth: '', // Nơi sinh
            nation: '', // Dân tộc
            religion: '', // Tôn giáo
            permanent_address: '', // Địa chỉ thường trú
            temporary_address: '', // Địa chỉ tạm trú
            area: '', // Khu vực
            enrollment_type: '', // Loại nhập học
            reason_for_leaving_school: '', // Lý do thôi học
            team_member: '', // Đội viên
            union_member: '', // Đoàn viên
            disability: '', // Khuyết tật
            combined_class: '', // Lớp ghép
            specialized_class: '', // Lớp chuyên
            ethnic_minority: '', // Dân tộc thiểu số
            poor_family: '', // Hộ nghèo
            identification_code: '', // Mã định danh
            policy_object: '', // Đối tượng chính sách
            health_check_nutrition: '', // Kiểm tra sức khỏe dinh dưỡng
            eye_disease: '', // Bệnh về mắt
            swimming_1st_semester: '', // Biết bơi kỳ 1
            swimming_2nd_semester: '', // Biết bơi kỳ 2
            boarding_student: '', // Học sinh bán trú

            tableData: [],
            tableHeader: [],
            column_form: 4,
            grade_level: null,

            deleteVisible: false,

            deleteIdentifierVisible: false,

            teachingAssignmentVisble: false,
            dataTeachingAssignment: {},

            schoolTransferVisible: false,
            dataSchoolTransfer: {},

            viewVisible: false
        }
    },

    methods: {
        hasProperty,
        getLabelByArray,
        shouldShowElement(element) {
            return this.advanced_search.includes(element)
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        navigateToLockUpStudent(item, viewVisible) {
            this.viewVisible = viewVisible

            this.$router.push({
                path: `${path.lock_up.student.update_student}/${item.hocSinhTheoNamId}`,
                query: { viewVisible: viewVisible }
            })
        },

        navigateUpdateLockUpStudent(item) {
            this.navigateToLockUpStudent(item, false)
        },

        navigateViewLockUpStudent(item) {
            this.navigateToLockUpStudent(item, true)
        },
        openModalTeachingAssignment(item) {
            this.dataTeachingAssignment = item
            this.teachingAssignmentVisble = true
        },
        closeModalTeachingAssignment() {
            this.teachingAssignmentVisble = false
        },
        openModalSchoolTransfer(item) {
            this.dataSchoolTransfer = item
            this.schoolTransferVisible = true
        },
        closeModalSchoolTransfer() {
            this.schoolTransferVisible = false
        },
        toggleModal(modalType) {
            if (this.selectedItemsTable.length === 0) {
                showNotication(this, 'error', 'Vui lòng chọn bản ghi')
                return
            }

            this[`${modalType}Visible`] = !this[`${modalType}Visible`]
        },

        closeModal(modalType) {
            this.resetCheckedTable()
            this[`${modalType}Visible`] = false
        },
        resetCheckedTable() {
            this.tableData.forEach((item) => {
                item.isChecked = false
            })
            this.selectedItemsTable = []
            this.selectAllChecked = false
        },

        toggleCheckboxTable(item) {
            this.selectAllChecked = false
            item.isChecked = !item.isChecked
            this.selectedItemsTable = updateSelectedItemsTable(
                this.selectedItemsTable,
                item
            )
        },

        handleSelectAll() {
            this.tableData.forEach((item) => {
                item.isChecked = this.selectAllChecked
                if (
                    this.selectAllChecked &&
                    !this.selectedItemsTable.includes(item)
                ) {
                    this.selectedItemsTable.push(item)
                } else if (!this.selectAllChecked) {
                    this.selectedItemsTable = []
                }
            })
        },
        onChangeProvince() {
            this.unit_education = []
            this.get_list_unit_education()
            this.school = []
            this.get_list_school()
        },
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_unit_education() {
            try {
                const request_params = {
                    start: 0,
                    limit: 9999,
                    maTinhThanh: this.province,
                    check: false
                }

                const response = await sendRequest(
                    Api.internal_api.dm_DonVi,
                    null,
                    this.request_headers,
                    request_params
                )

                this.list_unit_education = processUnitEducationList(response.rows)
            } catch (error) {
                console.error('Error fetching unit education list:', error)
            }
        },
        async get_list_school() {
            if (
                this.isRoleSuperAdmin &&
                this.unit_education.length === 0 &&
                this.province.length === 0
            ) {
                return
            }
            const request_data = {
                maSo: this.province,
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },

        syncTableHeaderWithContentDisplay() {
            this.tableHeader = updateContentDisplayed(
                this.list_content_displayed,
                this.content_displayed
            )
        },

        async handleSearch() {
            try {
                const request_data = this.prepareRequestData()
                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_hoc_sinh.danh_sach,
                    request_data,
                    this.request_headers,
                    null
                )
                if (response.rc == 0) {
                    this.total_rows = response.totalRows
                    const keysToKeep = this.tableHeader.map(
                        (header) => header.key
                    )
                    const additionalKeysToKeep = [
                        'hocSinhTheoNamId',
                        'trangThaiInt'
                    ]
                    const filteredTableData = response.tableData.map((item) => {
                        return filterItemKeysTableData(
                            item,
                            keysToKeep,
                            additionalKeysToKeep
                        )
                    })
                    this.advanced_search = []
                    this.tableData = addIsCheckedProperty(filteredTableData)
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleExportExcel() {
            try {
                const domain = process.env.VUE_APP_BACKEND_BASE_URL
                const request_data = this.prepareRequestData()
                const apiUrl = `${domain}/${Api.tra_cuu.tra_cuu_hoc_sinh.excel.url}`
                const fileName = 'tra_cuu_hoc_sinh.xlsx'
                const authToken = this.auth_user.token

                convertDownloadFileExcel(
                    apiUrl,
                    request_data,
                    fileName,
                    authToken
                )
            } catch (error) {
                console.log(error)
            }
        },
        prepareRequestData() {
            const mappings = [
                {
                    key: 'ngaySinh',
                    value: formatDateToDDMMYYYY(this.date_of_birth),
                    check: 'includes'
                },
                {
                    key: 'noiSinh',
                    value: this.place_of_birth,
                    check: 'includes'
                },
                {
                    key: 'maDanToc',
                    value: this.nation,
                    check: 'includes'
                },
                { key: 'maTonGiao', value: this.religion, check: 'includes' },
                {
                    key: 'dchiThtru',
                    value: this.permanent_address,
                    check: 'includes'
                },
                {
                    key: 'dchiTmtru',
                    value: this.temporary_address,
                    check: 'includes'
                },
                {
                    key: 'maKhuVuc',
                    value: this.area,
                    check: 'includes'
                },
                {
                    key: 'loaiNhapHoc',
                    value: this.enrollment_type,
                    check: 'includes'
                },
                {
                    key: 'maLyDoThoiHoc',
                    value: this.reason_for_leaving_school,
                    check: 'includes'
                },
                { key: 'doiVien', value: this.team_member, check: 'includes' },
                {
                    key: 'doanVien',
                    value: this.union_member,
                    check: 'includes'
                },
                {
                    key: 'khuyetTat',
                    value: this.disability,
                    check: 'includes'
                },

                {
                    key: 'lopGhep',
                    value: this.combined_class,
                    check: 'includes'
                },
                {
                    key: 'lopChuyen',
                    value: this.specialized_class,
                    check: 'includes'
                },
                {
                    key: 'danTocThieuSo',
                    value: this.ethnic_minority,
                    check: 'includes'
                },
                {
                    key: 'hoNgheo',
                    value: this.poor_family,
                    check: 'includes'
                },
                {
                    key: 'moetCode',
                    value: this.identification_code,
                    check: 'includes'
                },
                {
                    key: 'maDtuongCsach',
                    value: this.policy_object,
                    check: 'includes'
                },
                {
                    key: 'isKhamSkDky',
                    value: this.health_check_nutrition,
                    check: 'includes'
                },
                {
                    key: 'benhMat',
                    value: this.eye_disease,
                    check: 'includes'
                },
                {
                    key: 'bietBoiKy1',
                    value: this.swimming_1st_semester,
                    check: 'includes'
                },
                {
                    key: 'bietBoiKy2',
                    value: this.swimming_2nd_semester,
                    check: 'includes'
                },
                {
                    key: 'hocSinhBanTru',
                    value: this.boarding_student,
                    check: 'includes'
                }
            ]

            const dataSearchList = generateDataSearchList(
                this.advanced_search,
                mappings
            )
            this.syncTableHeaderWithContentDisplay()
            const colExcels = this.tableHeader.map((item) => ({
                key: item.key,
                name: item.title,
                stt: item.value
            }))

            this.grade_level = this.selectedGradeLevelFromStore

            return {
                colExcels: colExcels,
                capHocs: this.selectedGradeLevelFromStore,
                maCuDan: this.moet_code || null,
                start: this.start,
                limit: this.limit,
                dataSearchList: dataSearchList,

                gioiTinh: this.gender,
                keyword: this.key,
                lstKhoiHoc: this.school_block,
                maSo: this.province,
                maPhongs: this.unit_education,
                maTruongHocs: this.school,
                namHoc: this.selectedYearFromStore,
                tenLopHoc: this.name_class,
                trangThai: this.status,
                moetCode: this.moetCode
            }
        }
    },
    mounted() {
        this.content_displayed = setInitialContentDisplayed(
            this.list_content_displayed,
            this.content_displayed,
            11
        )

        if (!this.isRoleSuperAdmin) {
            this.get_list_school()
        }
        this.list_unit_education = getFromSessionStorage('list_unit_education')
        this.syncTableHeaderWithContentDisplay()
        // this.handleSearch()
    },
    watch: {
        selectedGradeLevelFromStore(newVal) {
            if (newVal) {
                this.school_block = []
                this.list_school_block = filterListSchoolBlock(
                    list_school_block,
                    newVal
                )
                this.school = []
                this.get_list_school()
            }
        },

        tableData: {
            handler(newVal) {
                this.isCheckedCount = countCheckedItems(newVal)
            },
            deep: true
        },
        advanced_search: {
            handler(newVal) {
                this.column_form = calculateColumnFormLength(newVal.length, 4, 12)
            },
            deep: true
        },
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_don_vi ? [user.ma_don_vi] : []
                }
                if (this.isRoleSchool) {
                    this.school = user.ma_truong_hoc ? [user.ma_truong_hoc] : []
                }
            },
            immediate: true
        }
    },

    computed: {
        list_province() {
            return getFromSessionStorage('list_province')
        },
        list_nation() {
            return getFromSessionStorage('DM_DAN_TOC')
        },
        list_religion() {
            return getFromSessionStorage('DM_TON_GIAO')
        },
        list_area() {
            return getFromSessionStorage('DM_KHU_VUC')
        },
        list_enrollment_type() {
            return getFromSessionStorage('LOAI_NHAP_HOC')
        },
        list_reason_for_leaving_school() {
            return getFromSessionStorage('DM_LY_DO_THOI_HOC')
        },
        list_policy_object() {
            return getFromSessionStorage('DM_DOI_TUONG_CHINH_SACH')
        },
        list_eye_disease() {
            return getFromSessionStorage('DM_BENH_VE_MAT')
        },

        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleSuperAdmin() {
            return checkUserRole(this.auth_user, ROLES.SuperAdmin)
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        isRoleSchool() {
            return checkUserRole(this.auth_user, ROLES.School)
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
::v-deep .el-icon-connection:before {
    font-size: 16px;
    content: '\e736';
    font-weight: bold;
}
</style>
