<template>
    <CustomModal
        :centered="true"
        :is-modal-visible="pushUpVisible"
        :modal-title="'Thông báo'"
        :close-function="closeModalPushUp"
        :size="'sm'"
        :err-modal="errModal"
    >
        <template v-slot:content>
            Đẩy dữ liệu <b> {{ dataPushUp.length }}</b> bản ghi đã chọn?
        </template>

        <template v-slot:btn-handle>
            <CustomButton
                label="Lưu"
                :btnSaveModal="true"
                @click="handlePushUp"
            />
        </template>
    </CustomModal>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import CustomModal from '@/components/CustomModal.vue'
import { extractValuesFromArray } from '@/utils'
import { showNotication } from '@/utils/notification'
export default {
    name: 'ModalPushUp',
    data() {
        return {
            errModal: false
        }
    },
    props: {
        pushUpVisible: {
            type: Boolean,
            required: true
        },
        dataPushUp: {
            type: Array,
            require: true
        },
        stage: {
            type: String
        },
        closeModalPushUp: Function,
        handleSearch: Function
    },

    components: { CustomButton, CustomModal },
    methods: {
        async handlePushUp() {
            try {
                const listMaHocSinh = extractValuesFromArray(
                    this.dataPushUp,
                    'maHocSinh'
                )

                const request_data = {
                    namHoc: this.selectedYearFromStore,
                    hocKy: this.selectedSemesterFromStore,
                    lstCapHoc: this.selectedGradeLevelFromStore,
                    maGiaiDoan: this.stage,
                    truongDayRiengLe: [
                        {
                            maTruong: this.dataPushUp.maTruong,
                            buocDays: 5,
                            listNopRieng: listMaHocSinh
                        }
                    ]
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_kqht.day_du_lieu,
                    request_data,
                    this.request_headers
                )
                if (response.rc == 0) {
                    showNotication(this, 'success', response.rd)
                    this.closeModalPushUp()
                    this.$emit('handleSearch')
                } else if (response.rc == 999) {
                    showNotication(this, 'error', response.rd)
                    this.tableDataError = response.rows
                } else {
                    showNotication(this, 'error', response.rd)
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {},
    computed: {
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
