<template>
    <el-dialog :visible="viewVisible" @close="closeModalView" :fullscreen="true">
        <span slot="title">
            Đẩy dữ liệu {{ getNameDataType(titleModalView?.buocHienTai) }} -
            Trường: {{ titleModalView?.tenTruong }} - [{{
                titleModalView?.maTruong
            }}] - Năm học: {{ titleModalView?.namHoc }} -
            {{ titleModalView?.namHoc + 1 }}
            <hr />
        </span>
        <el-tabs type="border-card">
            <el-tab-pane label="Lỗi đẩy lên bộ">
                <label class="error-push">
                    <i class="error-icon fas fa-exclamation-triangle"></i>
                    <i>
                        Để xử lý lỗi, vui lòng tra cứu
                        <span class="error-text">Danh mục lỗi đẩy lên Bộ</span>
                        theo "
                        <span class="error-text">Mã lỗi</span>
                        " và "
                        <span class="error-text">Mô tả</span>" để xem nguyên nhân
                        và cách xử lý!
                    </i>
                </label>

                <CustomTable
                    :table-modal="true"
                    :hide-pagi="true"
                    :hide-btn-table="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>
                            <th class="text-left">
                                Tên
                                <span v-if="titleModalView?.buocHienTai == '1'">
                                    Trường học
                                </span>
                                <span
                                    v-if="
                                        titleModalView?.buocHienTai == '2' ||
                                        titleModalView?.buocHienTai == '14'
                                    "
                                    >Giáo viên
                                </span>
                                <span v-else>Học sinh</span>
                            </th>
                            <th class="text-left">
                                Mã
                                <span v-if="titleModalView?.buocHienTai == '1'">
                                    Trường học
                                </span>
                                <span
                                    v-if="
                                        titleModalView?.buocHienTai == '2' ||
                                        titleModalView?.buocHienTai == '14'
                                    "
                                    >Giáo viên
                                </span>
                                <span v-else>Học sinh</span>
                            </th>
                            <th class="text-left">Trạng thái</th>
                            <th class="text-left">Mã lỗi</th>
                            <th class="text-left">Mô tả</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr
                            v-for="(
                                item, index
                            ) in tableDataModalView_error_pushing_up_the_set"
                            :key="index"
                        >
                            <td v-if="item.errorDescription !== 'Không có lỗi'">
                                {{ index + 1 }}
                            </td>
                            <td
                                class="text-left"
                                v-if="item.errorDescription !== 'Không có lỗi'"
                            >
                                {{ item.name }}
                            </td>
                            <td v-if="item.errorDescription !== 'Không có lỗi'">
                                {{ item.clientId }}
                            </td>

                            <td v-if="item.errorDescription !== 'Không có lỗi'">
                                Thất bại
                            </td>

                            <td
                                class="text-left"
                                v-if="item.errorDescription !== 'Không có lỗi'"
                            >
                                {{ item.error }}
                            </td>

                            <td
                                class="text-left"
                                v-if="item.errorDescription !== 'Không có lỗi'"
                            >
                                {{ item.errorDescription }}
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </el-tab-pane>
            <el-tab-pane label="Lỗi đồng bộ">
                <CustomTable
                    :table-modal="true"
                    :hide-pagi="true"
                    :hide-btn-table="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>
                            <th class="text-left">Mã lỗi</th>
                            <th class="text-left">Tên đối tượng</th>
                            <th class="text-left">clientId</th>
                            <th class="text-left">Mô tả lỗi</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr
                            v-for="(
                                item, index
                            ) in tableDataModalView_synchronization_error"
                            :key="index"
                        >
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td class="text-left">
                                {{ item.error }}
                            </td>
                            <td class="text-left">
                                {{ item.name }}
                            </td>
                            <td class="text-left">
                                {{ item.clientId }}
                            </td>
                            <td class="text-left">
                                {{ item.errorDescription }}
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </el-tab-pane>
            <el-tab-pane label="Danh mục lỗi">
                <CustomTable
                    :table-modal="true"
                    :hide-pagi="true"
                    :hide-btn-table="true"
                >
                    <template v-slot:header>
                        <tr>
                            <th>STT</th>
                            <th class="text-left">Mã lỗi</th>
                            <th class="text-left">Mô tả của bộ</th>
                            <th class="text-left">Nguyên nhân</th>
                            <th class="text-left">Cách xử lý</th>
                        </tr>
                    </template>
                    <template v-slot:body>
                        <tr
                            v-for="(
                                item, index
                            ) in tableDataModalView_category_error"
                            :key="index"
                        >
                            <td class="min-w-50">
                                {{ index + 1 }}
                            </td>
                            <td class="text-left min-w-350">
                                {{ item.errorCode }}
                            </td>
                            <td class="text-left min-w-350">
                                {{ item.errorDescription }}
                            </td>
                            <td class="text-left min-w-500">
                                {{ item.rootCause }}
                            </td>
                            <td class="text-left min-w-600">
                                {{ item.solution }}
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer">
            <CustomButton
                label="Đóng"
                class="fixed-btn"
                @click="handleCloseModalView"
            />
        </span>
    </el-dialog>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomTable from '@/components/CustomTable.vue'
export default {
    name: 'ModalView',
    data() {
        return {}
    },
    props: {
        viewVisible: {
            type: Boolean,
            required: true
        },
        closeModalView: Function,
        titleModalView: {
            default: null
        },
        tableDataModalView_synchronization_error: {
            type: Array,
            required: true,
            default: () => {
                return []
            }
        },
        tableDataModalView_error_pushing_up_the_set: {
            type: Array,
            required: true,
            default: () => {
                return []
            }
        },
        tableDataModalView_category_error: {
            type: Array,
            required: true,
            default: () => {
                return []
            }
        }
    },

    components: { CustomButton, CustomTable },
    methods: {
        getNameDataType(e) {
            const eAsNumber = parseInt(e, 10)

            const item = this.list_data_type?.find(
                (option) => option.value === eAsNumber
            )

            return item ? item.label : ''
        },

        handleCloseModalView() {
            this.closeModalView()
        }
    }
}
</script>

<style scoped>
.fixed-btn {
    position: fixed;
    bottom: 20px;
    left: 95%;
    transform: translateX(-50%);
    z-index: 999;
}
.error-push {
    color: var(--black-200);
    padding-left: 20px;
    padding-top: 7px;
    font-weight: 700;
}

.error-icon {
    color: red;
}

.error-text {
    color: var(--blue-100);
    font-weight: 700;
}
::v-deep .el-dialog__body {
    padding: 0px 20px 0px 20px;
}
</style>
