<template>
    <div>
        <ToolBar :menu="'Tra cứu'" :content="'Tra cứu trường học'">
            <template v-slot:form>
                <CustomButton
                    label="Tìm kiếm"
                    :icon="'el-icon-search'"
                    @click="handleSearch"
                />
                <CustomButton
                    label="Thêm mới"
                    :icon="'el-icon-circle-plus-outline'"
                    @click="navigateAddLockUpSchool"
                />

                <CustomButton
                    label="Nhập theo biểu mẫu"
                    :icon="'el-icon-document'"
                    @click="openModalUpload"
                    :plain="true"
                />

                <CustomButton
                    label="Xuất Excel"
                    :icon="'el-icon-download'"
                    @click="handleExportExcel"
                    :disabled="tableData.length === 0"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="2">
            <div class="row">
                <div class="col-md-2">
                    <div class="label-wrapper">
                        <label class="control-label">Đơn vị quản lý:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="isRoleEducationOfficeOrSchool"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                    />
                </div>
                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Nhóm cấp học:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_grade_group"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="grade_group"
                    />
                </div>
                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Trạng thái:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_status"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="status"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Mã trường:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="school_code"> </CustomInput>
                </div>
                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Tên trường:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <CustomInput v-model="school_name"> </CustomInput>
                </div>
                <div class="col-md-2 mobi">
                    <div class="label-wrapper">
                        <label class="control-label">Loại hình đào tạo:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_type_of_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type_of_education"
                    />
                </div>
            </div>
        </FormSearch>
        <CustomTable :column-form="2" :hideBtnTable="true">
            <template v-slot:header>
                <tr>
                    <th>STT</th>

                    <th>Mã trường học</th>
                    <th>Tên trường học</th>
                    <th>Phòng GD&ĐT</th>
                    <th>Hiệu trưởng</th>
                    <th>ĐT hiệu trưởng</th>
                    <th>Loại hình đào tạo</th>
                    <th>Loại hình trường</th>
                    <th>Chuẩn quốc gia mức độ</th>
                    <th>Trạng thái</th>
                    <th>Thao tác</th>
                </tr>
            </template>
            <template v-slot:body>
                <tr v-for="(item, index) in tableData" :key="index">
                    <td>
                        {{ (start / limit) * limit + index + 1 }}
                    </td>

                    <td class="text-left">
                        {{ item.maTruongHoc }}
                    </td>
                    <td
                        class="text-left text-navigate"
                        @click="navigateViewLockUpSchool(item)"
                    >
                        {{ item.tenTruongHoc }}
                    </td>
                    <td class="text-left">
                        {{ item.tenDonVi }}
                    </td>
                    <td class="text-left">
                        {{ item.tenHieuTruong }}
                    </td>

                    <td class="text-left">
                        {{ item.sdtHieuTruong }}
                    </td>

                    <td class="text-left">
                        {{
                            getLabelByArray(
                                list_type_of_education,
                                item.loaiHinhDaoTao
                            )
                        }}
                    </td>

                    <td class="text-left">
                        {{
                            getLabelByArray(list_school_type, item.loaiHinhTruong)
                        }}
                    </td>

                    <td class="text-left">
                        {{
                            getLabelByArray(
                                list_national_standard,
                                item.chuanQuocGia
                            )
                        }}
                    </td>

                    <td class="min-w-150">
                        <TextStatus
                            :content="
                                getLabelByArray(list_name_status, item.trangThai)
                            "
                            :status="item.trangThai"
                        />
                    </td>
                    <td class="text-left min-w-100">
                        <CustomToolTip content="Cập nhật hồ sơ">
                            <img
                                :src="iconEdit"
                                class="icon"
                                @click="navigateUpdateLockUpSchool(item)"
                            />
                        </CustomToolTip>
                        <span v-if="item.trangThai !== -1">
                            <CustomToolTip content="Xóa hồ sơ">
                                <img
                                    :src="iconDelete"
                                    class="icon"
                                    @click="openModalDelete(item)"
                                />
                            </CustomToolTip>
                        </span>
                    </td>
                </tr>
            </template>
            <template v-slot:pagi>
                <CustomPagination
                    v-show="total_rows > 0"
                    :tongbanghi="total_rows"
                    :pageSize="pageSize"
                    :pageStart="pageStart"
                    @pageChange="pageChange($event)"
                    @pageSizeChange="pageSizeChange($event)"
                >
                </CustomPagination>
            </template>
        </CustomTable>
        <CustomModal
            :centered="true"
            :is-modal-visible.sync="deleteVisible"
            :modal-title="'Thông báo'"
            :close-function="closeModalDelete"
        >
            <template v-slot:content>
                <div>
                    Xác nhận xóa <b>{{ titleDelete }}</b> ?
                </div>
            </template>

            <template v-slot:btn-handle>
                <CustomButton
                    label="Xóa"
                    :type="'danger'"
                    @click="handleDelete"
                />
            </template>
        </CustomModal>

        <ModalUpload
            :closeModalUpload="closeModalUpload"
            :uploadVisible="uploadVisible"
            @handleSearch="handleSearch"
        />
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import CustomToolTip from '@/components/CustomToolTip.vue'
import ESelect from '@/components/ESelect.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertDownloadFileExcel, getLabelByArray } from '@/utils/index'
import CustomTable from '@/components/CustomTable.vue'
import CustomPagination from '@/components/CustomPagination.vue'
import CustomInput from '@/components/CustomInput.vue'
import { showNotication } from '@/utils/notification'
import {
    list_grade_group,
    list_status,
    list_type_of_education,
    list_name_status,
    list_grade_level
} from '@/mock_data/tra-cuu-truong-hoc'

import iconEdit from '@/assets/img/icon-table/edit.svg'
import iconDelete from '@/assets/img/icon-table/delete.svg'
import CustomModal from '@/components/CustomModal.vue'
import TextStatus from '@/components/TextStatus.vue'
import ModalUpload from './modal_upload.vue'
import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'
import { path } from '@/router/path'
import { ROLES, checkUserRole } from '@/constants/Roles'
import axios from 'axios'
export default {
    name: 'LockUpSchool',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        CustomToolTip,
        ESelect,
        CustomTable,
        TextStatus,
        CustomInput,
        CustomPagination,
        CustomModal,
        ModalUpload
    },
    data() {
        return {
            iconEdit,
            iconDelete,

            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: '',

            list_grade_group: list_grade_group,
            grade_group: '',

            list_status: list_status,
            status: '1',

            school_code: '',
            school_name: '',

            list_type_of_education: list_type_of_education,
            type_of_education: [],

            list_name_status: list_name_status,
            list_grade_level: list_grade_level,

            tableData: [],

            deleteVisible: false,
            idDelete: 0,
            titleDelete: '',

            uploadVisible: false,

            viewVisible: false
        }
    },

    methods: {
        getLabelByArray,
        getNameGradeLevel(e) {
            if (!e) return ''
            const item = list_grade_level.find((option) => option.value === e)
            return item ? item.label : 'Cấp học ' + e
        },
        pageSizeChange(e) {
            this.total_rows = 0
            this.pageSize = e.soluong
        },
        checkTruocKhiTim() {
            this.pageStart = !this.pageStart
        },
        pageChange(e) {
            this.start = e.start
            this.limit = e.limit
            this.currentPage = e.currentPage
            this.handleSearch()
        },
        navigateAddLockUpSchool() {
            this.$router.push({ path: path.lock_up.school.add_school })
        },
        navigateToLockUpSchool(item, viewVisible) {
            this.viewVisible = viewVisible

            const dataLockUpSchool = {
                ...item,
                viewVisible: this.viewVisible
            }

            saveToSessionStorage('data_lock_up_school', dataLockUpSchool)

            this.$router.push({
                path: `${path.lock_up.school.update_school}/${item.maTruongHoc}`
            })
        },

        navigateUpdateLockUpSchool(item) {
            this.navigateToLockUpSchool(item, false)
        },

        navigateViewLockUpSchool(item) {
            this.navigateToLockUpSchool(item, true)
        },

        openModalDelete(item) {
            this.idDelete = item.maTruongHoc
            this.titleDelete = item.tenTruongHoc
            this.deleteVisible = true
        },
        closeModalDelete() {
            this.deleteVisible = false
        },
        openModalUpload() {
            this.uploadVisible = true
        },
        closeModalUpload() {
            this.uploadVisible = false
        },
        async handleDelete() {
            try {
                const request_params = {
                    ma: this.idDelete
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.xoa,
                    null,
                    this.request_headers,
                    request_params
                )
                if (response.code == 200) {
                    this.closeModalDelete()
                    showNotication(this, 'success', response.message)
                    await this.handleSearch()
                }
                if (response.code != 200) {
                    showNotication(this, 'error', response.message)
                }
            } catch (error) {
                console.log(error)
            }
        },

        async handleSearch() {
            try {
                const request_params = {
                    caphoc: '',
                    limit: this.limit,
                    loaihinhdaotao: this.type_of_education,
                    ma: this.school_code,
                    maNhomCapHoc: this.grade_group,
                    madonvi: this.unit_education,
                    matinhthanh: this.auth_user.ma_so,
                    start: this.start,
                    ten: this.school_name,
                    trangthai: this.status
                }

                const response = await sendRequest(
                    Api.tra_cuu.tra_cuu_truong_hoc.danh_Sach,
                    null,
                    this.request_headers,
                    request_params
                )
                if (response) {
                    this.tableData = response.rows
                    this.total_rows = response.total
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleExportExcel() {
            const domain = process.env.VUE_APP_BACKEND_BASE_URL
            const apiUrl = `${domain}/${Api.tra_cuu.tra_cuu_truong_hoc.excel.url}`
            const fileName = 'tra_cuu_truong_hoc.xlsx'
            const authToken = this.auth_user.token
            const axiosConfig = {
                method: 'POST',
                url: apiUrl,
                headers: {
                    token: authToken,
                    'Content-Type': 'application/json'
                },
                params: {
                    caphoc: '',
                    limit: this.limit,
                    loaihinhdaotao: this.type_of_education,
                    ma: this.school_code,
                    maNhomCapHoc: this.grade_group,
                    madonvi: this.unit_education,
                    matinhthanh: this.auth_user.ma_so,
                    start: this.start,
                    ten: this.school_name,
                    trangthai: this.status
                },
                responseType: 'blob'
            }

            const response = await axios(axiosConfig)

            const blob = new Blob([response.data])
            const blobUrl = window.URL.createObjectURL(blob)

            const downloadLink = document.createElement('a')
            downloadLink.href = blobUrl
            downloadLink.download = fileName
            downloadLink.style.display = 'none'

            document.body.appendChild(downloadLink)
            downloadLink.click()

            window.URL.revokeObjectURL(blobUrl)
        }
    },
    mounted() {
        // this.handleSearch()
    },
    watch: {
        auth_user: {
            handler(user) {
                if (this.isRoleEducationOfficeOrSchool) {
                    this.unit_education = user.ma_phong
                }
            },
            immediate: true
        }
    },
    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        list_school_type() {
            return getFromSessionStorage('DM_LOAI_HINH_TRUONG')
        },
        list_national_standard() {
            return getFromSessionStorage('DM_MUC_DAT_CHUAN_QG_CLGD')
        },

        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },

        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        isRoleEducationOfficeOrSchool() {
            return (
                checkUserRole(this.auth_user, ROLES.EducationOffice) ||
                checkUserRole(this.auth_user, ROLES.School)
            )
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped></style>
