<template>
    <div>
        <ToolBar :menu="'Tra cứu'" :content="'Thông tin điểm danh'">
            <template v-slot:form>
                <!-- <CustomButton
                    label="Xem báo cáo"
                    @click="handleViewReport"
                    
                /> -->
                <CustomButton
                    label="Xuất excel"
                    :icon="'el-icon-download'"
                    @click="handlExportExcel"
                />
            </template>
        </ToolBar>
        <FormSearch :column-form="3">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Đơn vị quản lý <span class="red">*</span>:
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_unit_education"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['tenDonVi', 'maDonVi']"
                        v-model="unit_education"
                        @change="onChangeUnitEducation"
                        @input="onChangeHandler('unit_education', $event)"
                        :class="{
                            'error-select': err_unit_education.length > 0
                        }"
                    />
                    <div
                        v-if="err_unit_education.length > 0"
                        class="text-error-select"
                    >
                        {{ err_unit_education }}
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Trường học <span class="red">*</span>:
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="true"
                        :disabled="false"
                        :data="list_school"
                        :placeholder="'Chọn '"
                        :filterable="true"
                        :multiple="true"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="school"
                        @input="onChangeHandler('school', $event)"
                        :class="{
                            'error-select': err_school.length > 0
                        }"
                    />
                    <div v-if="err_school.length > 0" class="text-error-select">
                        {{ err_school }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-1">
                    <div class="label-wrapper">
                        <label class="control-label"
                            >Loại báo cáo <span class="red">*</span>:</label
                        >
                    </div>
                </div>
                <div class="col-md-3">
                    <ESelect
                        style="width: 100%"
                        :clearable="false"
                        :disabled="false"
                        :data="list_type_report"
                        :placeholder="'Chọn'"
                        :filterable="true"
                        :multiple="false"
                        :collapse-tags="true"
                        :fields="['label', 'value']"
                        v-model="type_report"
                        @change="onChangeTypeReport"
                    />
                </div>
                <div class="col-md-1" v-if="type_report === '1'">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Ngày điểm danh <span class="red">*</span>:
                        </label>
                    </div>
                </div>
                <div class="col-md-3" v-if="type_report === '1'">
                    <el-date-picker
                        :size="'small'"
                        v-model="day"
                        type="date"
                        placeholder="Ngày"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        style="width: 100%"
                        :class="{ 'is-error': err_day.length > 0 }"
                        @input="onChangeHandler('day', $event)"
                    >
                    </el-date-picker>
                    <div v-if="err_day.length > 0" class="text-error-select">
                        {{ err_day }}
                    </div>
                </div>

                <div class="col-md-1" v-if="type_report === '2'">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Tuần học <span class="red">*</span>:
                        </label>
                    </div>
                </div>
                <div class="col-md-3" v-if="type_report === '2'">
                    <el-date-picker
                        :size="'small'"
                        v-model="week"
                        type="week"
                        placeholder="Tuần"
                        style="width: 100%"
                        format="Tuần WW"
                        :class="{ 'is-error': err_week.length > 0 }"
                        @input="onChangeHandler('week', $event)"
                    >
                    </el-date-picker>
                    <div v-if="err_week.length > 0" class="text-error-select">
                        {{ err_week }}
                    </div>
                </div>
                <div class="col-md-1" v-if="type_report === '3'">
                    <div class="label-wrapper">
                        <label class="control-label">
                            Tháng học <span class="red">*</span>:
                        </label>
                    </div>
                </div>
                <div class="col-md-3" v-if="type_report === '3'">
                    <el-date-picker
                        :size="'small'"
                        v-model="month"
                        type="month"
                        placeholder="Tháng"
                        style="width: 100%"
                        :format="'MM/yyyy'"
                        :class="{ 'is-error': err_month.length > 0 }"
                        @input="onChangeHandler('month', $event)"
                    >
                    </el-date-picker>
                    <div v-if="err_month.length > 0" class="text-error-select">
                        {{ err_month }}
                    </div>
                </div>
            </div>
        </FormSearch>
    </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import ToolBar from '@/components/ToolBar.vue'
import CustomButton from '@/components/CustomButton.vue'
import ESelect from '@/components/ESelect.vue'
import { getFromSessionStorage } from '@/utils/session'

import { formatDateToDDMMYYYY, convertDownloadFileExcel } from '@/utils'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { convertListData } from '@/utils'
import { list_type_report_role_educationDepartment } from '@/mock_data/thong-tin-diem-danh'
import { validateFields, handlerError } from '@/utils/validator'
export default {
    name: 'LookupAttendance',
    components: {
        FormSearch,
        ToolBar,
        CustomButton,
        ESelect
    },
    data() {
        return {
            start: 0,
            total_rows: 0,
            currentPage: 1,
            limit: 25,
            pageSize: 25,
            pageStart: false,

            unit_education: '',

            list_school: [],
            school: '',

            list_type_report: list_type_report_role_educationDepartment,
            type_report: '1',

            day: '',
            week: '',
            month: '',

            err_unit_education: '',
            err_school: '',
            err_day: '',
            err_week: '',
            err_month: ''
        }
    },

    methods: {
        onChangeUnitEducation() {
            this.school = []
            this.get_list_school()
        },
        async get_list_school() {
            const request_data = {
                capHoc: this.selectedGradeLevelFromStore,
                maDonVi: this.unit_education ? [this.unit_education] : []
            }

            const response = await sendRequest(
                Api.internal_api.dm_School,
                request_data,
                this.request_headers,
                null
            )
            this.list_school = convertListData(
                response.rows,
                'tenTruongHoc',
                'maTruongHoc'
            )
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                unit_education: 'err_unit_education',
                school: 'err_school',
                day: 'err_day'
            }

            handlerError(this, source, newValue, errorFields)
        },
        onChangeTypeReport() {
            this.day = ''
            this.week = ''
            this.month = ''
            this.err_day = ''
            this.err_week = ''
            this.err_month = ''
        },
        async handleViewReport() {},
        handlExportExcel() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.unit_education,
                        errorField: 'err_unit_education'
                    },
                    {
                        value: this.school,
                        errorField: 'err_school'
                    },

                    this.type_report == '1'
                        ? {
                              value: this.day,
                              errorField: 'err_day'
                          }
                        : null,

                    this.type_report == '2'
                        ? {
                              value: this.week,
                              errorField: 'err_week'
                          }
                        : null,
                    this.type_report == '3'
                        ? {
                              value: this.month,
                              errorField: 'err_month'
                          }
                        : null
                ].filter((item) => item !== null)
                const isValid = validateFields(this, fieldsToValidate)
                if (isValid) {
                    const day = formatDateToDDMMYYYY(this.day)
                    const week = formatDateToDDMMYYYY(this.week)
                    const month = formatDateToDDMMYYYY(this.month)
                    const reportTypeMapping = {
                        1: day,
                        2: week,
                        3: month
                    }

                    let ngayBatDau = reportTypeMapping[this.type_report] || null

                    const domain = process.env.VUE_APP_BACKEND_BASE_URL
                    const request_data = {
                        capHoc: '',
                        hocKy: this.selectedSemesterFromStore,
                        maDonVi: this.unit_education,
                        maSo: this.auth_user.ma_so,
                        maTruongHocs: this.school,
                        namHoc: this.selectedYearFromStore,
                        ngayBatDau: ngayBatDau,
                        type: this.type_report
                    }
                    const apiUrl = `${domain}/${Api.tra_cuu.thong_tin_diem_danh.excel.url}`
                    const fileName = 'thong_tin_diem_danh.xlsx'
                    const authToken = this.auth_user.token

                    convertDownloadFileExcel(
                        apiUrl,
                        request_data,
                        fileName,
                        authToken
                    )
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.get_list_school()
    },
    watch: {
        selectedGradeLevelFromStore(newVal) {
            if (newVal) {
                this.school = []
                this.get_list_school()
            }
        }
    },

    computed: {
        list_unit_education() {
            return getFromSessionStorage('list_unit_education')
        },
        selectedYearFromStore() {
            return this.$store.state.selectedYear.selectedYear
        },
        selectedGradeLevelFromStore() {
            return this.$store.state.gradeLevel.gradeLevel
        },
        selectedSemesterFromStore() {
            return this.$store.state.semester.semester
        },
        auth_user() {
            return getFromSessionStorage('auth_user')
        },
        request_headers() {
            return {
                token: this.auth_user.token
            }
        }
    }
}
</script>

<style scoped>
.error {
    color: red;
    margin-top: -20px;
}
.red {
    color: red;
}
::v-deep .error-select .el-input__inner {
    border: 1px solid #f56c6c !important;
}
::v-deep .is-error .el-input__inner {
    border: 1px solid #f56c6c !important;
}
::v-deep .is-error .el-input__inner:focus {
    border: 1px solid #f56c6c !important;
    box-shadow: 0 0 3px #f56c6c;
}
</style>
